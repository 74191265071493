<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>
<div *ngIf="hasUserAccess" class="container__form">
    <div class="col-md-8">
        <div class="block">
            <div class="form-horizontal form-bordered">
                <form [formGroup]="organizacionCreateForm">
                    <div class="form-group">
                        <label class="col-md-3 control-label">Organización</label>
                        <div class="col-md-9">
                            <input #organizacionSearchInput mask="A*" formControlName="newOrganization" type="text" class="form-control" required>
                            <app-error [control]="organizacionCreateForm.get('newOrganization')">
                            </app-error>
                        </div>
                    </div>


                    <div class="form-group" *ngIf="submitValid">
                        <label class="col-md-3 control-label">Descripción</label>
                        <div class="col-md-9">
                            <input formControlName="description" type="text" class="form-control" required
                            appOnlyText>
                            <app-error [control]="organizacionCreateForm.get('description')">
                            </app-error>
                        </div>
                    </div>
                </form>
                <div class="form-group" *ngIf="isLoaded" [ngClass]="{'d-none': !submitValid}">
                    <div class="row">
                        <label class="col-md-3 control-label ml-3">Permisos :</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-sm-12 col-lg-8 mt-4">
                            <div class="table-resposive px-3">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="font-size-table" scope="col">Nombre</th>
                                            <th class="font-size-table text-center" scope="col">Crear</th>
                                            <th class="font-size-table text-center" scope="col">Buscar</th>
                                            <th class="font-size-table text-center" scope="col">Administrar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let groupItem of accessGroups; let i = index">
                                            <td class="font-size-table">{{groupItem.display}}</td>
                                            <td class="text-center align-middle">
                                                <ng-container *ngFor="let accessItem of groupItem.accesos; let j = index">
                                                    <input type="checkbox" *ngIf="accessItem.type === 'Crear'" [(ngModel)]="accessGroups[i].accesos[j].active">
                                                </ng-container>
                                            </td>
                                            <td class="text-center align-middle">
                                                <ng-container *ngFor="let accessItem of groupItem.accesos; let j = index">
                                                    <input type="checkbox" *ngIf="accessItem.type === 'Buscar'" [(ngModel)]="accessGroups[i].accesos[j].active">
                                                </ng-container>
                                            </td>
                                            <td class="text-center align-middle">
                                                <ng-container *ngFor="let accessItem of groupItem.accesos; let j = index">
                                                    <input type="checkbox" [disabled]="accessItem?.name === 'CLIENTES-USER-ADMIN'" *ngIf="accessItem.type === 'Administrar'" [(ngModel)]="accessGroups[i].accesos[j].active">
                                                </ng-container>
                                            </td>                                    
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group form-actions container__actions">
                    <!-- <div class="col-md-9 col-md-offset-3"> -->
                    <button (click)="goBack()" class="btn btn-sm btn-default">Volver</button>
                    <button type="submit" class="btn btn-sm btn-primary" [disabled]="!submitValid" (click)="createOrganizacion()">
                        <i class="fa fa-angle-right formulario"></i> Crear</button>
                    <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i
                            class="fa fa-repeat formulario"></i>
                        Limpiar</button>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <div>