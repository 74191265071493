<div *ngIf="!hasUserAccess">
    <!-- Article Header -->
    <!-- For an image header add the class 'content-header-media' and an image as in the following example -->
    <div class="content-header content-header-media">
        <div class="header-section">
            <h1>SOLICITE ACCESO<br><small>www.gestionatupyme.cl</small></h1>
        </div>
    </div>
    <!-- Article Content -->
    <div class="row">
        <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
            <!-- Article Block -->
            <div class="block block-alt-noborder">
                <!-- Article Content -->
                <article>
                    <h3 class="sub-header text-center"><strong>SOLICITE ACCESO</strong></h3>
                    <p>Ud no cuenta con los accesos necesarios , pongase en contacto con el administrador para solicitar acceso</p>
                    <blockquote>
                        <p>
                            contacto@gestionatupyme.cl
                        </p>
                        <footer></footer>
                    </blockquote>
                </article>
                <!-- END Article Content -->
            </div>
            <!-- END Article Block -->
        </div>
    </div>
    <!-- END Article Content -->
</div>

<div *ngIf="hasUserAccess" class="col-12 mb-2">

    <div class="d-flex justify-content-between align-items-center align-content-center mt-4">

        <h1 class="mb-4 text-muted">Ofertas Financieras</h1>
        
        <div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div class="btn-group pb-2 mb-2">
                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Crear Ofertas
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" (click)="onCreate()">Individual</a>
                      <a class="dropdown-item" (click)="onCreateMasiva()">Masiva</a>
                    </div>
                  </div>
                <!-- <button type="button" class="btn btn-sm btn-primary flex-end" (click)="onCreate()">Crear oferta</button> -->
            </div>
        </div>
    </div>
    <div class="card mt-2">
        <div class="card-body">
            <div class="row g-3" [formGroup]="offerFilterForm">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <label class="form-label">Código de oferta</label>
                    <input formControlName="searchInput" type="text" class="form-control"
                        placeholder="Buscar por código de oferta">
                </div>
                <!-- FILTROS -->
                <!-- USUARIO (INPUT)-->
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                    <label class="form-label">Usuario</label>
                    <input formControlName="userInput" type="text" class="form-control"
                    placeholder="Buscar por rut del usuario">
                </div>
                <!-- TIPO (COMBO BOX) -->
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <label class="form-label">Tipo</label>
                    <select formControlName="typeCombo" class="form-control">
                        <!-- <option>Seleccionar</option> -->
                        <option value="">-</option>
                        <option *ngFor="let vertical of verticalesContract" value="{{vertical.id}}">{{vertical.displayName}}</option>
                    </select>
                </div>
                <!-- EXPIRACION (COMBO BINARIO) -->
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <label class="form-label">Expiración</label>
                    <select formControlName="expiredCombo" class="form-control">
                        <!-- <option>Seleccionar</option> -->
                        <option value="">-</option>
                        <option value="true">Expirada</option>
                        <option value="false">No expirada</option>
                    </select>
                </div>
                <!-- ACEPTADA (COMBO BINARIO) -->
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <label class="form-label">Aceptada</label>
                    <select formControlName="acceptedCombo" class="form-control">
                        <!-- <option>Seleccionar</option> -->
                        <option value="">-</option>
                        <option value="true">Aceptada</option>
                        <option value="false">No aceptada</option>
                    </select>
                </div>
                <!-- ELMIMINADA (COMBO BINARIO) -->
                <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                    <label class="form-label">Eliminada</label>
                    <select formControlName="deletedCombo" class="form-control">
                        <!-- <option>Seleccionar</option> -->
                        <option value="">-</option>
                        <option value="true">Eliminada</option>
                        <option value="false">No eliminada</option>
                    </select>
                </div>

                <div class="col text-lg-end">
                    <label class="form-label">&nbsp;</label>
                    <div class="row d-flex">
                        <div class="col-7">
                            <button type="button" class="btn btn-link" style="white-space: nowrap;" (click)="cleanFilters()">Limpiar filtros</button>
                        </div>
                        <div class="col-3">
                            <button type="button"
                                class="btn btn-sm btn-primary" (click)="search()">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="hasUserAccess" class="col-12 mb-2 mt-5">
    <p class="text-muted">{{offersFiltered.length}} resultados</p>
    <div class="table-responsive table-entidad mt-3">
        <table id="example-datatable" class="table table-vcenter table-condensed table-bordered bg-light">
            <thead>
                <tr>
                    <th class="bg-primary text-center" style="color:#fff; font-size: 13px;">CODIGO</th>
                    <th class="bg-primary text-center" style="color:#fff; font-size: 13px;">TIPO</th>
                    <th class="bg-primary text-center" style="color:#fff; font-size: 13px;">ÚLTIMA MODIFICACIÓN</th>
                    <th class="bg-primary text-center" style="color:#fff; font-size: 13px;">MONTO</th>
                    <th class="bg-primary text-center" style="color:#fff; font-size: 13px;">EXPIRACIÓN</th>
                    <th class="bg-primary text-center" style="color:#fff; font-size: 13px;">USUARIO</th>
                    <th class="bg-primary text-center" style="color:#fff; font-size: 13px;">ACEPTADA</th>
                    <th class="bg-primary text-center" style="color:#fff; font-size: 13px;">ELIMINADA</th>
                    <th class="bg-primary text-center" style="color:#fff; font-size: 13px;">ACCIONES</th>
                </tr>
            </thead>
            <tbody>
                <ng-container>
                    <tr *ngFor="let offer of offersFiltered | slice: pageIndex * pageSize : (pageIndex + 1) * pageSize" style="font-size: 2rem;">                
                        <td class="text-center">{{ offer.id }}</td>
                        <td class="text-center">{{ displayType(offer.idVertical) }}</td>
                        <td class="text-center">{{ getFormattedDate(offer.lastModified) }}</td>
                        <td class="text-center">{{ displayAmount({start: offer.amountStart, end: offer.amountEnd}) }}</td>
                        <td class="text-center">{{ getFormattedDate(offer.clientExpTime) }}</td>
                        <td class="text-center">{{ offer.idOrganizacionUsuario }}</td>
                        <td class="text-center">
                            <div *ngIf="offer.accepted" class="badge bg-primary">
                                Aceptada
                            </div>
                            <div *ngIf="!offer.accepted" class="badge bg-light text-dark">
                                No aceptada
                            </div>
                        </td>
                        <td class="text-center">
                            <div *ngIf="offer.deleted" class="badge bg-danger">
                                Eliminada
                            </div>
                            <div *ngIf="!offer.deleted" class="badge bg-light text-dark">
                                No eliminada
                            </div>
                        </td>
                        <td class="text-center">
                            <i *ngIf="offer.accepted" class="fa fa-eye mr-3" style="cursor: pointer;" (click)="onView(offer.id)"></i>
                            <i *ngIf="!offer.accepted" class="fa fa-edit mr-3" style="cursor: pointer;" (click)="onEdit(offer.id)"></i>
                            <i *ngIf="!offer.deleted && !offer.accepted" class="fa fa-trash ml-3" aria-hidden="true" style="cursor: pointer;" (click)="onDeleteOffer(offer.id, offer.idVertical)"></i>
                        </td>
                    </tr>
                </ng-container>
                <ng-template #noPage>
                    <tr>
                        <td colspan="100%">
                            Sin información
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li *ngIf="pageIndex > 0" (click)="onPageChange(-1)" class="page-item"><a class="page-link">Anterior</a></li>
          <li *ngIf="offersFiltered.length / pageSize > (pageIndex + 1)" (click)="onPageChange(1)" class="page-item"><a class="page-link">Siguiente</a></li>
        </ul>
    </nav>
</div>