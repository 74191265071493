import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BackendAuthService } from './backend-auth.service';
import { SessionService } from './session.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ESystemAccess } from 'src/app/enum/EAccess';

interface ICreateUser {
  username: string;
  firstName: string;
  givenName: string;
  phoneNumber: string;
  systemUserAccessList: string[];
}

interface IUpdateUserAccess {
  username: string;
  availableAccess: string[];
  profilesList: string[];
}

export interface availableAccessResponse {
  availableAdminAccess: availableAdminAccess[];
}

export interface availableAdminAccess {
  accessID: string;
  name: string;
  active: boolean;
  display: string;
  type: string;
  group: string;
}

export interface IGetAccessForUserResponse {
  userID: string;
  userSub: string;
  organization: string;
  profilesList: string[];
  availableAccess: string[];
}

export interface IGetUsersForGroupResponse {
  userName: string;
  name: string;
  givenName: string;
  email: string;
  userSub: string;
  phoneNumber: string
  creationDate: string;
  lastModifiedDate: string;
  organization: string;
  profilesList: string[];
  availableAccess: string[];
}

export interface IGetUserExistsResponse {
  currentOrg: boolean;
  otherOrg: boolean;
}


enum EUsuarioServiceAcctions {
  CREATE_USER = "createUser",
  UPDATE_USER_BASIC_INFORMATION = "updateUserBasicInformation",
  DELETE_USER = "deleteUser",
  GET_ACCESS_USER = "getAccessForUser",
  UPDATE_USER_ACCESS = "updateUserAccess",
  GET_AVAILABLE_ACCESS_SYSTEM = "getAvailableAccessOnSystem",
  GET_USER_GROUP = "getUsersForGroup",
  GET_USER_EXISTS = "searchUser",
  SET_ASSIGN_USER = "assignUser",
  RESET_USER_PASS = "resetUserPassword",
  CHANGE_USER_PASS = "changeUserPassword",
}

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private endpoint: string = environment.backend.usuarios;
  private userPoolID: string = environment.cognitoConfig.userPoolId;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    private _backendAuthService: BackendAuthService) { }

  /**
   * Crea un nuevo usuario en el sistema (no administradores, solo usuarios básicos que no pueden crear otros usuarios)
   * @param {string} user
   * @returns 
   */
  public createUser(user: ICreateUser): Observable<any> {

    const payload = {
      "userPoolID": this.userPoolID,
      "userName": user.username,
      "name": user.firstName,
      "givenName": user.givenName,
      "phoneNumber": user.phoneNumber,
      "organization": this._sessionService.getOrganization(),
      "accessList": user.systemUserAccessList,
      "profilesList": ["WEB"], // NOTE: this is the default user profilesList for "activated"
      "operation": EUsuarioServiceAcctions.CREATE_USER
    }
    return this.http.post(this.endpoint, payload);
  }

  /**
   * Borra usuarios del sistema
   * @param username The username account
   * @param userSub The user identifier account
   * @returns 
   */
  public deleteUser(username: string): Observable<any> {

    const payload = {
      "userPoolID": this.userPoolID,
      "userName": username,
      "organization": this._sessionService.getOrganization(),
      "operation": EUsuarioServiceAcctions.DELETE_USER
    }

    return this.http.post(this.endpoint, payload);
  }

  /**
   * Obtiene accesos de un respectivo usuario (perfil)
   */
  public getAccessForUser(userSub: string): Observable<IGetAccessForUserResponse> {

    const payload = {
      "userName": userSub,
      "organization": this._sessionService.getOrganization(),
      "operation": EUsuarioServiceAcctions.GET_ACCESS_USER
    }

    return this.http.post<IGetAccessForUserResponse>(this.endpoint, payload);
  }

  /**
   * Obtiene el listado de todo los accesos posibles dentro del sistema
   */
  public getSystemAccessList(): Observable<availableAdminAccess[]> {

    const payload = {
      "operation": EUsuarioServiceAcctions.GET_AVAILABLE_ACCESS_SYSTEM,
      "organization": this._sessionService.getOrganization()
    }

    return this.http.post<availableAdminAccess[]>(this.endpoint, payload);
    /*return this.http.post(this.endpoint, payload)
    .pipe(map((response: any) => response.filter((e: availableAccessResponse) => e.accessName !== ESystemAccess.CLIENTES_USER_ADMIN)));+/*/
  }

  /**
   * Actualiza los acceso de un usuario
   */
  public updateUserAccess(userAccess: IUpdateUserAccess): Observable<any> {

    const payload = {
      "userName": userAccess.username,
      "organization": this._sessionService.getOrganization(),
      "accessList": userAccess.availableAccess,
      "profilesList": userAccess.profilesList,
      "operation": EUsuarioServiceAcctions.UPDATE_USER_ACCESS
    }

    return this.http.post(this.endpoint, payload);
  }

  /**
   * Actualiza información personal del usuario
   */
  public updateUserBasicInformation(username: string, email: string, firstName: string, givenName: string): Observable<any> {

    const payload = {
      "userPoolID": this.userPoolID,
      "userName": username,
      "email": email,
      "name": firstName,
      "givenName": givenName,
      "organization": this._sessionService.getOrganization(), // por verificar jfk 
      "operation": EUsuarioServiceAcctions.UPDATE_USER_BASIC_INFORMATION
    }

    return this.http.post(this.endpoint, payload);
  }

  /**
   * Obtiene todos los usuarios de una organización definida
   */
  public getUsersForGroup(): Observable<IGetUsersForGroupResponse[]> {

    const payload = {
      "userPoolID": this.userPoolID,
      "organization": this._sessionService.getOrganization(),
      "operation": EUsuarioServiceAcctions.GET_USER_GROUP
    };

    return this.http.post<IGetUsersForGroupResponse[]>(this.endpoint, payload);
  }

  /**
   * Verifica si el usuario existe en la organizacion actual o en otra
   */
  public getsearchUserExists(username: string): Observable<IGetUserExistsResponse> {

    const payload = {
      "userPoolID": this.userPoolID,
      "userName": username,
      "organization": this._sessionService.getOrganization(),
      "operation": EUsuarioServiceAcctions.GET_USER_EXISTS
    }; 

    return this.http.post<IGetUserExistsResponse>(this.endpoint, payload);
  }

  /**
   * Agrega un usuario a una organización cuando dicho usuario ya existe en el sistema
   * (en otra organización)
   */
  public setAssignUser(user: ICreateUser): Observable<any> {

    const payload = {
      "userPoolID": this.userPoolID,
      "userName": user.username,
      "organization": this._sessionService.getOrganization(),
      "accessList": user.systemUserAccessList,
      "profilesList" : ["WEB"],
      "operation": EUsuarioServiceAcctions.SET_ASSIGN_USER
    }; 
    return this.http.post<any>(this.endpoint, payload);
  }

  /**
   * Restablece la constraseña del usuario
   */
  public resetUserPassword(username: string): Observable<any> {

    const payload = {
      "userPoolID": this.userPoolID,
      "userName": username,
      "organization": this._sessionService.getOrganization(),
      "operation": EUsuarioServiceAcctions.RESET_USER_PASS
    }; 
    return this.http.post<any>(this.endpoint, payload);
  }

  /**
   * Se establece una nueva contraseña al usuario indicado
   */
  public changeUserPassword(username: string, pass: string): Observable<any> {

    const payload = {
      "userPoolID": this.userPoolID,
      "userName": username,
      "password": pass,
      "organization": this._sessionService.getOrganization(),
      "operation": EUsuarioServiceAcctions.CHANGE_USER_PASS
    }; 
    return this.http.post<any>(this.endpoint, payload);
  }

}
