<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="hasUserAccess">

    <div *ngIf="showForm && !showList && !showReport">
        <div class="container__form">
            <div class="col-md-6">
                <div class="block">
                    <form [formGroup]="searchLicitacionForm" (ngSubmit)="submitFecha()">
                        <div class="form-horizontal form-bordered">
                            <div class="form-group">
                                <label class="col-md-3 control-label" for="example-text-input">Fecha</label>
                                <div class="col-md-6">
                                    <input formControlName="fecha" type="date" class="form-control" [min]="min" [max]="max" required>
                                </div>
                            </div>
                            <div class="form-group form-actions container__actions">
                                <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-angle-right"></i>
                                    Buscar Reporte</button>
                                <button type="button" (click)="clearForm()" class="btn btn-sm btn-warning"><i class="fa fa-repeat"></i>
                                    Limpiar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!showForm && showList && !showReport">
        <div class="row">
            <div class="col-md-10 mx-auto my-4">
                <div class="col-12">

                    <div class="row container__back-button">
                        <button (click)="volverForm()" class="btn btn-primary ml-4 mr-auto p-2">Volver</button>
                        <button *ngIf="licitacionesFiltrado.length > 0" id="descargarExcel" class="btn btn-primary p-2 mr-4" 
                            (click)="descargarExcel()">Descargar Excel</button>
                    </div>

                    <h2>
                        <strong>Licitaciones para la fecha: </strong>
                        <span class="text-capitalize">{{fechaSeleccionada | date: 'dd-MM-yyyy'}}</span>
                    </h2>

                    <div class="card text-center my-4">

                        <div class="card-header">
                            <div class="my-0 row d-flex px-4">

                                <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                    <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                                </div>

                                <div class="col-xs-3 col-sm-auto p-2">

                                    <div class="row">

                                        <div class="col-10 py-0">
                                            <div class="input-group mb-3">
                                                <input id="table-complete-search" type="text" 
                                                    class="form-control" name="searchTerm" [(ngModel)]="inputText" 
                                                    (keyup)="filtrarObjeto()" placeholder="Filtrar tabla"
                                                >
                                            </div>
                                        </div>

                                        <div class="col-2">
                                            <button 
                                                type="button" 
                                                class="btn btn-info float-right refresh-button" 
                                                (click)="actualizarTabla()"
                                                title="Actualizar tabla"
                                            >
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                            </button>
                                        </div>

                                    </div>
                                    
                                </div>

                            </div>
                        </div>

                        <div class="col-xs-3 col-sm-auto">
                            <div class="table-responsive">
                                <table id="example-datatable" class="table table-bordered table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-center font-size cursor-pointer"
                                            (click)="cambioDeCampo('Codigo')" width="10%">
                                                Código
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer"
                                            (click)="cambioDeCampo('Descripcion')" width="28%">
                                                Descripción
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer"
                                            (click)="cambioDeCampo('Estado')" width="10%">
                                                Estado
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>

                                            <th scope="col" class="text-center font-size cursor-pointer"
                                            (click)="cambioDeCampo('NombreOrganismo')" width="14%">
                                                Nombre Organismo
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer"
                                            (click)="cambioDeCampo('RutOrganismo')" width="12%">
                                                Rut Organismo
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer"
                                            (click)="cambioDeCampo('NombreProveedor')" width="14%">
                                                Nombre Proveedor
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                            <th scope="col" class="text-center font-size cursor-pointer"
                                            (click)="cambioDeCampo('RutProveedor')" width="12%">
                                                Rut Proveedor
                                                <div class="float-right">
                                                    <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                                    <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let licitacion of licitacionesFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                            <td class="text-center cursor-pointer" (click)="buscarLicitacion(licitacion?.Codigo)">
                                                {{licitacion?.Codigo}}
                                            </td>
                                            <td class="text-center cursor-pointer" (click)="buscarLicitacion(licitacion?.Codigo)">
                                                {{licitacion?.Descripcion}}
                                            </td>
                                            <td class="text-center cursor-pointer" (click)="buscarLicitacion(licitacion?.Codigo)">
                                                {{licitacion?.Estado}}
                                            </td>
                                            <td class="text-center cursor-pointer" (click)="buscarLicitacion(licitacion?.Codigo)">
                                                {{licitacion?.NombreOrganismo}}
                                            </td>
                                            <td class="text-center cursor-pointer" (click)="buscarLicitacion(licitacion?.Codigo)">
                                                {{licitacion?.RutOrganismo ? (licitacion?.RutOrganismo | rutFormat) : ''}}
                                            </td>
                                            <td class="text-center cursor-pointer" (click)="buscarLicitacion(licitacion?.Codigo)">
                                                {{licitacion?.NombreProveedor}}
                                            </td>
                                            <td class="text-center cursor-pointer" (click)="buscarLicitacion(licitacion?.Codigo)">
                                                {{licitacion?.RutProveedor ? (licitacion?.RutProveedor | rutFormat) : ''}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="card-footer text-muted">
                            <div class="p-2">
                                <div id="pagination">
                                    <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                        <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!showForm && !showList && showReport">
        <div class="container-gtp">
            <div class="panel panel-default">
                <div class="panel-body">
                    <div class="btn-option">
                        <button (click)="closeReport()" class="btn btn-primary">Volver</button>
                        <button [printTitle]='"Licitacion_"+licitacionCodigo'
                        [useExistingCss]="true" printSectionId="print-section" ngxPrint class="btn btn-primary">Descargar PDF</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="print-section">
            <app-reporte-licitacion-view [dataLicitacion]="licitacion" [codigoLicitacion]="licitacionCodigo"></app-reporte-licitacion-view>
        </div>
    </ng-container>

</ng-container>