import { Component, Input, OnInit } from '@angular/core';
import { AlertService } from 'src/app/components/_alert';
import { BalanceManualService } from 'src/app/shared/services/balance-manual.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-reporte-balance-manual-view',
  templateUrl: './reporte-balance-manual-view.component.html',
  styleUrls: ['./reporte-balance-manual-view.component.scss']
})
export class ReporteBalanceManualViewComponent implements OnInit {
  public objectKeys = Object.keys;
  @Input() rut: string = '';
  @Input() data: any[] = [];
  @Input() listaArchivos: any = [];
  public contratoResultados: any = {};
  public contratoResultadosInput: any = {};
  public secciones: any[] = [];
  public seccionesInput: any[] = [];
  public valores: any[] = [];
  public arrayContrato: any[] = [];
  public arrayContratoInput: any[] = [];
  public calculosWeb: any[] = [];
  public visualizacionExtendida = false;
  public groupNameConsulting: string[] = ['contratoCalculos'];
  public subscriptions: Subscription[] = [];
  public errores: any[] = [];

  constructor(
    public alertService: AlertService,
    private balanceManualService: BalanceManualService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    if(this.data.length > 0){
      this.callServices();
    }
  }

  async callServices(): Promise<void> {
    const apiServices: any = [];

    apiServices.push(this.getServices('contratoCalculos'))

    if(this.data.length > 0){
      for await (const [i, reporte] of this.data.entries()){
        const idTransaccion = reporte?.DatosBasicosSolicitud?.IDTransaccion || '';
        if(idTransaccion && this.listaArchivos.includes(reporte?.DatosBasicosSolicitud?.IDTransaccion)){
          apiServices.push(this.getServices('excelCarga',
            reporte?.Reporte?.periodo,
            reporte?.Reporte?.tipo,
            idTransaccion,
            i
          ));
        }
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          if(element.id === 'excelCarga') {
            console.error(element.msg);
          } else {
            this.alertService.error(element.msg);
          }
        });
      }
      this.obtencionDeValores();
      this.spinner.hide();
    },
      (error) => {
        this.alertService.error(error?.message);
        this.obtencionDeValores();
        this.spinner.hide();
      }
    ));
  }

  getServices(service: string, 
    periodo?: string | number, 
    tipo?: string,
    idTransaccion?: string,
    index?: number
  ): any {
    const objeto: any = {
      'contratoCalculos': () => {
        return this.balanceManualService.obtenerContratoCalculos()
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Ha ocurrido un error obtener los calculos a realizar.'), of(null))));
      },
      'excelCarga': () => {
        return this.balanceManualService.obtenerExcelCarga(this.rut, periodo ? periodo : '', tipo ? tipo : '', idTransaccion ? idTransaccion : '')
        .pipe(
          map(resp => {
            this.setResponse(service, 
              resp,
              index
            );
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Ha ocurrido un error al consultar por excel de carga'), of(null))));
      }
    };
    return objeto[service]();
  }

  setResponse(service: string, response: any, index?: number
  ): void {
    if (service === 'contratoCalculos'){
      if(response.length > 0){
        this.calculosWeb = response || [];
      }
    }
    else if (service === 'excelCarga'){
      if(index != undefined && response?.xlsx){
        if(index >= 0 && this.data?.[index]?.Reporte){
          this.data[index].Reporte.xlsx = response?.xlsx;
        }
      }
    }
  }

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  changeVisualizacion(valor: boolean): void {
    this.visualizacionExtendida = valor;
    this.obtencionDeValores();
  }

  obtenerContratoCalculos(): void {
    this.spinner.show();
    this.balanceManualService.obtenerContratoCalculos().subscribe(
      (data: any) => {
        if(data.length > 0){
          this.calculosWeb = data || [];
        }
        this.spinner.hide();
        this.obtencionDeValores();
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error obtener los calculos a realizar.');
        this.spinner.hide();
        this.obtencionDeValores();
    });
  }

  obtencionDeValores(): void {
    this.spinner.show();
    this.valores = [];
    this.secciones = [];

    this.obtenerContrato();
    this.obtenerValores();
    this.obtenerSecciones();
    this.arrayContrato = this.generarContratoFromContrato();
    this.obtenerDatosInput();

    this.spinner.hide();
  }

  obtenerDatosInput(): void {
    //this.valoresInput = [];
    this.seccionesInput = [];

    this.obtenerContratoInput();
    //this.obtenerValoresInput();
    this.obtenerSeccionesInput();
    this.arrayContratoInput = this.generarContratoFromContratoInput();
  }

  obtenerContrato(): void {
    const element = this.data.find(e => e?.Reporte?.tipo === 'balance');
    if(element){
      this.contratoResultados = element?.Reporte?.definicionBalance?.output || {};
    } else {
      this.contratoResultados = this.data[0]?.Reporte?.definicionBalance?.output || {};
    }
  }

  obtenerContratoInput(): void {
    const element = this.data.find(e => e?.Reporte?.tipo === 'balance');
    if(element){
      this.contratoResultadosInput = element?.Reporte?.definicionBalance?.input || {};
    } else {
      this.contratoResultadosInput = this.data[0]?.Reporte?.definicionBalance?.input || {};
    }
  }

  obtenerValores(): void {
    this.data.forEach(element => {
      const objeto: any = element?.Reporte || {};
      if(Object.keys(objeto).length > 0 && objeto?.periodo){
        objeto.IDTransaccion = element?.DatosBasicosSolicitud?.IDTransaccion || '';
        this.valores.push(objeto);
      }
    });

    if(this.valores.length > 0){
      this.valores.sort(function(a, b) {
        // Convertir el campo "periodo" a un número para comparar correctamente
        const periodoA = Number(a.periodo);
        const periodoB = Number(b.periodo);
        
        // Comparar los valores de periodo
        if (periodoA > periodoB) {
          return -1;
        } else if (periodoA < periodoB) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  obtenerPeriodoCabecera(item: any): string {
    if(item?.periodo){
      return (item?.periodo + "/" + (Number(item?.periodo) - 1));
    }
    return '';
  }

  obtenerSecciones(): void {
    const sectionMap = new Map();
  
    for (const key in this.contratoResultados) {
      const nivel1 = this.contratoResultados[key].nivel1;
      const nivel1Name = this.contratoResultados[key].nivel1Name;
      const nivel2 = this.contratoResultados[key].nivel2;
      const nivel2Name = this.contratoResultados[key].nivel2Name;
      const nivel3 = this.contratoResultados[key].nivel3;
      const nivel3Name = this.contratoResultados[key].nivel3Name;
  
      if (!sectionMap.has(nivel1)) {
        sectionMap.set(nivel1, { nivel1, nivel1Name, nivel2: [] });
      }
  
      const sectionObj = sectionMap.get(nivel1);
      const nivel2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
  
      if (!nivel2Obj) {
        sectionObj.nivel2.push({ nivel2, nivel2Name, nivel3: [] });
      } else {
        const section2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
        const nivel3Obj = section2Obj.nivel3.find((subSec: any) => subSec.nivel3 === nivel3);
    
        if (!nivel3Obj) {
          section2Obj.nivel3.push({ nivel3, nivel3Name });
        }
      }
  
    }
  
    this.secciones = Array.from(sectionMap.values());

    if(this.visualizacionExtendida === true)
    this.secciones = this.secciones.filter(e => e.nivel1 === 'liquideseindicadores');

  }

  obtenerSeccionesInput(): void {
    const sectionMap = new Map();
  
    for (const key in this.contratoResultadosInput) {
      const nivel1 = this.contratoResultadosInput[key].nivel1;
      const nivel1Name = this.contratoResultadosInput[key].nivel1Name;
      const nivel2 = this.contratoResultadosInput[key].nivel2;
      const nivel2Name = this.contratoResultadosInput[key].nivel2Name;
      const nivel3 = this.contratoResultadosInput[key].nivel3;
      const nivel3Name = this.contratoResultadosInput[key].nivel3Name;
  
      if (!sectionMap.has(nivel1)) {
        sectionMap.set(nivel1, { nivel1, nivel1Name, nivel2: [] });
      }
  
      const sectionObj = sectionMap.get(nivel1);
      const nivel2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
  
      if (!nivel2Obj) {
        sectionObj.nivel2.push({ nivel2, nivel2Name, nivel3: [] });
      } else {
        const section2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
        const nivel3Obj = section2Obj.nivel3.find((subSec: any) => subSec.nivel3 === nivel3);
    
        if (!nivel3Obj) {
          section2Obj.nivel3.push({ nivel3, nivel3Name });
        }
      }
  
    }
  
    this.seccionesInput = Array.from(sectionMap.values());

  }

  filtrarObjetoNiveles(objeto: any, valor1: any, valor2: any = undefined, valor3: any = undefined): any[] {
    const resultado: any[] = [];
    for (const key in objeto) {
      const lista: any[] = [];
      if (objeto.hasOwnProperty(key) && objeto[key]?.nivel1 === valor1 && objeto[key]?.nivel2 === valor2 && objeto[key]?.nivel3 === valor3) {
        const objetoElemento: any = {
          key: key,
          valor: objeto[key]?.name,
          type: objeto[key]?.type || "String"
        };
        lista.push(objetoElemento);

        this.valores.forEach((element: any) => {
          const objetoElemento: any = {
            valor: (element?.balance[key] || element?.balance[key] == 0) ? element?.balance[key] : (element?.campos[key] || element?.campos[key] == 0) ? element?.campos[key] : "",
            type: objeto[key]?.type
          };
          lista.push(objetoElemento);
        })
        resultado.push(lista);
      }
    }
  
    return resultado;
  }

  filtrarObjetoNivelesInput(objeto: any, valor1: any, valor2: any = undefined, valor3: any = undefined): any[] {
    const resultado: any[] = [];
    for (const key in objeto) {
      const lista: any[] = [];
      if (objeto.hasOwnProperty(key) && objeto[key]?.nivel1 === valor1 && objeto[key]?.nivel2 === valor2 && objeto[key]?.nivel3 === valor3) {
        const objetoElemento: any = {
          key: key,
          valor: objeto[key]?.name,
          type: objeto[key]?.type || "String"
        };
        lista.push(objetoElemento);

        this.valores.forEach((element: any) => {
          const objetoElemento: any = {
            valor: (element?.campos[key] || element?.campos[key] == 0) ? element?.campos[key] : (element?.balance[key] || element?.balance[key] == 0) ? element?.balance[key] : "",
            type: objeto[key]?.type
          };
          lista.push(objetoElemento);
        })
        resultado.push(lista);
      }
    }
  
    return resultado;
  }

  generarContratoFromContrato(): any[] {
    const respuesta: any[] = [];
  
    this.secciones.forEach(element1 => {
      if (!element1?.nivel1) {
        const arrayUndefined: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1) || [];
        const objeto: any = { variables: arrayUndefined };
        respuesta.push(objeto);
      } else {
        const objetoNivel1: any = {
          nivel1Name: element1?.nivel1Name || "",
          nivel1: element1?.nivel1 || "",
          variables: [],
          nivel2: []
        };
  
        if (element1?.nivel2 && element1?.nivel2.length > 0) {
          element1.nivel2.forEach((element2: any) => {
            if (!element2?.nivel2) {
              const arrayNivel2: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2) || [];
              const objeto: any = {
                nivel2Name: "",
                nivel2: "",
                variables: arrayNivel2
              };
              objetoNivel1.nivel2.push(objeto);
            } else {
              const objetoNivel2: any = {
                nivel2Name: element2?.nivel2Name || "",
                nivel2: element2?.nivel2 || "",
                variables: [],
                nivel3: []
              };
  
              if (element2?.nivel3 && element2?.nivel3.length > 0) {
                element2.nivel3.forEach((element3: any) => {
                  if (!element3?.nivel3) {
                    const arrayNivel3: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objeto: any = {
                      nivel3Name: "",
                      nivel3: "",
                      variables: arrayNivel3
                    };
                    objetoNivel2.nivel3.push(objeto);
                  } else {
                    const arrayNivel3: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objetoNivel3: any = {
                      nivel3Name: element3?.nivel3Name || "",
                      nivel3: element3?.nivel3 || "",
                      variables: arrayNivel3 || []
                    };
                    objetoNivel2.nivel3.push(objetoNivel3);
                  }
                });
              } else {
                const arrayNivel2: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1, element2?.nivel2) || [];
                objetoNivel2.variables.push(...arrayNivel2);
              }
  
              objetoNivel1.nivel2.push(objetoNivel2);
            }
          });
        } else {
          const arrayNivel1: any = this.filtrarObjetoNiveles(this.contratoResultados, element1?.nivel1) || [];
          objetoNivel1.variables.push(...arrayNivel1);
        }
  
        respuesta.push(objetoNivel1);
      }
    });
  
    return respuesta || [];
  }

  generarContratoFromContratoInput(): any[] {
    const respuesta: any[] = [];
  
    this.seccionesInput.forEach(element1 => {
      if (!element1?.nivel1) {
        const arrayUndefined: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1) || [];
        const objeto: any = { variables: arrayUndefined };
        respuesta.push(objeto);
      } else {
        const objetoNivel1: any = {
          nivel1Name: element1?.nivel1Name || "",
          nivel1: element1?.nivel1 || "",
          variables: [],
          nivel2: []
        };
  
        if (element1?.nivel2 && element1?.nivel2.length > 0) {
          element1.nivel2.forEach((element2: any) => {
            if (!element2?.nivel2) {
              const arrayNivel2: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1, element2?.nivel2) || [];
              const objeto: any = {
                nivel2Name: "",
                nivel2: "",
                variables: arrayNivel2
              };
              objetoNivel1.nivel2.push(objeto);
            } else {
              const objetoNivel2: any = {
                nivel2Name: element2?.nivel2Name || "",
                nivel2: element2?.nivel2 || "",
                variables: [],
                nivel3: []
              };
  
              if (element2?.nivel3 && element2?.nivel3.length > 0) {
                element2.nivel3.forEach((element3: any) => {
                  if (!element3?.nivel3) {
                    const arrayNivel3: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objeto: any = {
                      nivel3Name: "",
                      nivel3: "",
                      variables: arrayNivel3
                    };
                    objetoNivel2.nivel3.push(objeto);
                  } else {
                    const arrayNivel3: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objetoNivel3: any = {
                      nivel3Name: element3?.nivel3Name || "",
                      nivel3: element3?.nivel3 || "",
                      variables: arrayNivel3 || []
                    };
                    objetoNivel2.nivel3.push(objetoNivel3);
                  }
                });
              } else {
                const arrayNivel2: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1, element2?.nivel2) || [];
                objetoNivel2.variables.push(...arrayNivel2);
              }
  
              objetoNivel1.nivel2.push(objetoNivel2);
            }
          });
        } else {
          const arrayNivel1: any = this.filtrarObjetoNivelesInput(this.contratoResultadosInput, element1?.nivel1) || [];
          objetoNivel1.variables.push(...arrayNivel1);
        }
  
        respuesta.push(objetoNivel1);
      }
    });
  
    return respuesta || [];
  }

  obtenerArrayCabeceraCalculos(keyNivel: string | undefined, contrato: any): string[] {
    const respuesta: string[] = [];

    if(contrato && Object.keys(contrato).length > 0 && contrato?.nivel1 
      && contrato?.nivel1.length > 0 && contrato?.nivel1.includes(keyNivel) 
      && contrato?.exception && !contrato?.exception.includes(keyNivel)
    ) {
      const objetos: any = {
        valores: this.valores
      }
      const arrayContract: number[] = this.generateArrayByContract(contrato?.columnsLength); 
      if(arrayContract && arrayContract.length > 0){
        
        arrayContract.forEach(e => {
          let titulo: string = contrato?.titleVars?.title || '';
          contrato?.titleVars?.subtitle?.forEach((element: {objectFrom: string, index: number | string, key: string, value: string}) => {
            if(element?.objectFrom && element?.key && element?.index === ''){
              titulo += this.getValueFromObject(objetos?.[element?.objectFrom], element?.key) || '';
            } else if(element?.objectFrom && element?.key === 'index' && element?.index !== ''){
              titulo += `(${(Number(e) + Number(element?.index)) + 1})`
            } else if(element?.objectFrom && element?.key && element?.index !== ''){
              titulo += this.getValueFromObject(objetos?.[element?.objectFrom]?.[(Number(e) + Number(element?.index))], element?.key) || '';
            } else if(element?.value) {
              titulo += element?.value;
            } else {
              titulo += '';
            }
          });
          respuesta.push(titulo);
        });

      }

    }
    return respuesta;
  }

  validaNivel(elemento: any, nivel1Array: string[]): boolean{
    if(elemento?.campo?.[0]?.key && nivel1Array && nivel1Array.length > 0 && this.contratoResultados?.[elemento?.campo?.[0]?.key] && Object.keys(this.contratoResultados?.[elemento?.campo?.[0]?.key])) {
      const nivel1: string = this.contratoResultados[elemento?.campo?.[0]?.key]?.nivel1 || '';
      if(nivel1Array.includes(nivel1)){
        return true;
      }
    }
    return false;
  }

  validaException(elemento: any, exceptionArray: string[]): boolean{
    if(elemento?.campo?.[0]?.key && exceptionArray && exceptionArray.length >= 0) {
      const key: string = elemento?.campo?.[0]?.key || '';
      if(!exceptionArray.includes(key)){
        return true;
      }
    }
    return false;
  }

  validaHeaderCalculadoNivel(contratoCalculo: any, nivel1: string): boolean{
    if(contratoCalculo?.nivel1 && contratoCalculo?.nivel1.length > 0 && nivel1 && !contratoCalculo?.nivel1.includes(nivel1)) {
      return false;
    }
    return true;
  }

  validaHeaderCalculadoCampo(contratoCalculo: any, key: string): boolean{
    if(contratoCalculo?.exception && contratoCalculo?.exception.length > 0 && key && contratoCalculo?.exception.includes(key)) {
      return false;
    }
    return true;
  }

  validaMeses(index: number, contratoCalculo: any): boolean {
    if(contratoCalculo?.validarCantMesesVar === true && contratoCalculo?.validacionMesesVar && contratoCalculo?.validacionMesesVar.length > 0){
      const valoresDiff: number[] = [];

      contratoCalculo?.validacionMesesVar.forEach((element: number) => {
        const indice: number = index + Number(element) - 1;

        const mesInicio: number = Number(this.valores[indice]?.mesInicio) || 0;
        const mesFin: number = Number(this.valores[indice]?.mesFin) || 0;
        
        const diff = mesFin - mesInicio;
        valoresDiff.push(diff);
      });

      // validar que todos los valores sean iguales
      const valoresIguales: boolean = valoresDiff.every((val, i, arr) => val === arr[0]);
      if(!valoresIguales){
        return false;
      }
    }
    return true;
  }

  obtenerValorCalculadoHeader(index: number, key: string, contratoCalculo: any): any {
    let respuesta: any = contratoCalculo?.defaultValue || contratoCalculo?.defaultValue === 0 ? contratoCalculo?.defaultValue : '';

    const objetos: any = {
      valores: this.valores,
    }
    
    const variables: any[] = [];
    if(contratoCalculo?.variables && contratoCalculo?.variables.length > 0){
      contratoCalculo?.variables.forEach((element: ({objectFrom: string, key: string, column: number|string, value: string|number})) => {
        let valor: number = 0;
        let valorResponse: number | string = '';
        const indice: number = index + Number(element?.column) - 1;
        if(this.valores[indice] && Object.keys(this.valores[indice]).length > 0) {
          valorResponse = (this.valores[indice]?.balance[key] || this.valores[indice]?.balance[key] == 0) ? (this.valores[indice]?.balance[key]) : 
          (this.valores[indice]?.campos[key] || this.valores[indice]?.campos[key] == 0 ? this.valores[indice]?.campos[key] : '');
        }
        if(element.objectFrom && element?.key){
          valor = this.getValueFromObject(objetos?.[element?.objectFrom]?.[index], element?.key) || 0;
        } else if((element?.column || element?.column === 0) && (index || index === 0)) {
          const elementValor = Number(valorResponse) || 0;
          valor = Number(elementValor);
        } else if (element?.value || element?.value === 0) {
          valor = Number(element?.value);
        } else {
          valor = 0;
        }

        variables.push(valor);
      });
    }
    const valoresCalculados: any = {};
    contratoCalculo?.formula.forEach((element: ({key: string, operation: string, values: number[], valueError: number})) => {
      let valor: number | null = null;
      element?.values.forEach(e => {
        const valorElemento: number = this.validarTipoNumber(variables[e]) ? (Number(variables[e])) : (valoresCalculados?.[e]);
        if(element?.operation === 'suma') {
          valor = valor == null ? (valorElemento) : (valor + valorElemento);
        } else if(element?.operation === 'resta') {
          valor = valor == null ? (valorElemento) : (valor - valorElemento);
        } else if(element?.operation === 'multiplicacion') {
          valor = valor == null ? (valorElemento) : (valor * valorElemento);
        } else if(element?.operation === 'division') {
          valor = valor == null ? (valorElemento) : (valor / valorElemento);
        } else {
          valor = valorElemento;
        }

      });

      if((element?.valueError || element?.valueError === 0) && !this.esNumeroValido(valor)) {
        valor = element?.valueError;
      }

      valoresCalculados[element.key] = valor;
    });

    if(valoresCalculados.total || valoresCalculados.total === 0){
      respuesta = Number(valoresCalculados.total);
    }

    return respuesta;
  }

  obtenerValorCalculado(index: number, valoresArray: any, contratoCalculo: any): any { //index se mantiene, valores va a this.valores debe ir con key y contrato calculo se mantiene
    let respuesta: any = contratoCalculo?.defaultValue || contratoCalculo?.defaultValue === 0 ? contratoCalculo?.defaultValue : '';

    const objetos: any = {
      valores: this.valores,
    }
    const variables: any[] = [];
    if(contratoCalculo?.variables && contratoCalculo?.variables.length > 0){
      contratoCalculo?.variables.forEach((element: ({objectFrom: string, key: string, column: number|string, value: string|number})) => {
        let valor: number = 0;

        if(element.objectFrom && element?.key){
          valor = this.getValueFromObject(objetos?.[element?.objectFrom]?.[index], element?.key) || 0;
        } else if((element?.column || element?.column === 0) && (index || index === 0) && valoresArray && valoresArray.length > 0) {
          const elementValor = valoresArray?.[index + Number(element?.column)]?.valor || 0;
          valor = Number(elementValor);
        } else if (element?.value || element?.value === 0) {
          valor = Number(element?.value);
        } else {
          valor = 0;
        }

        variables.push(valor);
      });
    }

    const valoresCalculados: any = {};
    contratoCalculo?.formula.forEach((element: ({key: string, operation: string, values: number[], valueError: number})) => {
      let valor: number | null = null;
      element?.values.forEach(e => {
        const valorElemento: number = this.validarTipoNumber(variables[e]) ? (Number(variables[e])) : (valoresCalculados?.[e]);
        if(element?.operation === 'suma') {
          valor = valor == null ? (valorElemento) : (valor + valorElemento);
        } else if(element?.operation === 'resta') {
          valor = valor == null ? (valorElemento) : (valor - valorElemento);
        } else if(element?.operation === 'multiplicacion') {
          valor = valor == null ? (valorElemento) : (valor * valorElemento);
        } else if(element?.operation === 'division') {
          valor = valor == null ? (valorElemento) : (valor / valorElemento);
        } else {
          valor = valorElemento;
        }

      });

      if((element?.valueError || element?.valueError === 0) && !this.esNumeroValido(valor)) {
        valor = element?.valueError;
      }

      valoresCalculados[element.key] = valor;
    });

    if(valoresCalculados.total || valoresCalculados.total === 0){
      respuesta = Number(valoresCalculados.total);
    }

    return respuesta;
  }

  esNumeroValido(n: any): boolean {
    return typeof n === 'number' && !isNaN(n) && isFinite(n);
  }

  esNumeroOStringNumerico(valor: any): boolean {
    if (typeof valor === "number") {
      return true;
    }

    if (typeof valor === "string" && valor.trim() !== "") {
      return /^[0-9]+$/.test(valor);
    }

    return false;
  }

  obtenerVariablesColumnas(arrayVariables: {objectFrom: string, key: string, value: string|number}[], objetos: any): any[] {
    const respuesta: any = [];
    if(arrayVariables && arrayVariables.length > 0){
      arrayVariables.forEach((element: ({objectFrom: string, key: string, value: string|number})) => {
        let valor: number = 0;
        if(element?.key === 'length'){
          valor = Number(objetos?.[element?.objectFrom]?.length || 0);
        } else if(element?.key !== '' && element?.key != 'length') {
          valor = this.getValueFromObject(objetos?.[element?.objectFrom], element?.key) || 0
        } else if (!element?.key && !element?.objectFrom && (element?.value || element?.value === 0)) {
          valor = Number(element?.value || 0);
        } else {
          valor = 0;
        }
        respuesta.push(valor);
      });
    }
    return respuesta;
  }

  generateArrayByContract(contractLength: any): number[] {
    const respuesta: number[] = [];

    if(contractLength && Object.keys(contractLength).length > 0) {
      const objetos: any = {
        valores: this.valores,
      }
      const variables: any[] = this.obtenerVariablesColumnas(contractLength?.variables, objetos);
      const valoresCalculados: any = {};
      contractLength?.formula.forEach((element: ({key: string, operation: string, values: number[]})) => {
        let valor: number | null = null;
        element?.values.forEach(e => {
          const valorElemento: number = this.validarTipoNumber(variables[e]) ? (Number(variables[e])) : (valoresCalculados?.[e] ? Number(valoresCalculados?.[e]) : 0);
          if(element?.operation === 'suma') {
            valor = valor == null ? (valorElemento) : (valor + valorElemento);
          } else if(element?.operation === 'resta') {
            valor = valor == null ? (valorElemento) : (valor - valorElemento);
          } else if(element?.operation === 'multiplicacion') {
            valor = valor == null ? (valorElemento) : (valor * valorElemento);
          } else if(element?.operation === 'division') {
            valor = valor == null ? (valorElemento) : (valor / valorElemento);
          } else {
            valor = valorElemento;
          }
        });
        valoresCalculados[element.key] = valor;
      });

      if(valoresCalculados.total && valoresCalculados.total > 0){
        for (let index = 0; index < valoresCalculados.total; index++) {
          respuesta.push(index);
        }
      }

    }
    return respuesta;
  }

  obtenerValorTotal(nivelKey: string, objeto: any): any {
    if(nivelKey && objeto && Object.keys(objeto).length > 0){
      const valor: any = (objeto?.balance?.[nivelKey] || objeto?.balance?.[nivelKey] == 0) ? (objeto?.balance?.[nivelKey]) : 
        (objeto?.campos?.[nivelKey] || objeto?.campos?.[nivelKey] == 0) ? (objeto?.campos?.[nivelKey]) : ("");
      return valor;
    }
    return '';
  }

  validarTipoNumber(valor: any) : boolean {
    // Si es un número (incluyendo formato "123" pero no valores como "123abc")
    if (!isNaN(valor) && typeof valor === 'number') {
      return true;
    }
  
    // Si es un número pero viene en formato string (por ejemplo "123")
    if (!isNaN(valor) && typeof valor === 'string') {
      return true;
    }
  
    // Si es texto
    if (typeof valor === 'string') {
      return false;
    }
  
    // Si no es ninguno de los anteriores
    return false;
  }

  getValueFromObject(obj: any, key: string): any {
    if (!key || typeof key !== 'string' || !obj || typeof obj !== 'object') {
      return undefined;
    }

    const keys = key.split('.');

    for (let i = 0; i < keys.length; i++) {
      if (obj.hasOwnProperty(keys[i])) {
        obj = obj[keys[i]];
      } else {
        return undefined;
      }
    }
    return obj;
  }

  obtenerTitulo(): string {
    const element = this.data.find(e => e?.Reporte?.tipo === 'balance');
    if(element){
      return 'BALANCE';
    } else {
      return 'PRE BALANCE';
    }
  }

  toggleContent(event: any, id: string): void{
    let estadoClosed: boolean | null = null;
    if(event?.target?.tagName && event?.target?.tagName.toUpperCase() === 'TH') {
      if(event?.target?.parentElement) {
        const parentElement: any = event?.target?.parentElement;
        if ( parentElement.classList.contains('closed') ){
          parentElement.classList.remove('closed');
          estadoClosed = false;
        } else {
          parentElement.classList.add('closed');
          estadoClosed = true;
        }
      }
    } else if(event?.target?.tagName && event?.target?.tagName.toUpperCase() === 'TR') {
      const element: any = event?.target;
      if ( element.classList.contains('closed') ){
        element.classList.remove('closed');
        estadoClosed = false;
      } else {
        element.classList.add('closed');
        estadoClosed = true;
      }

    }

    if(estadoClosed != null){
      const elementosHTML: any = document.querySelectorAll(`[id^="${id}"]`);
      for (const elementoHTML of elementosHTML) {
        if(elementoHTML){
          if(estadoClosed === true) {
            elementoHTML.classList.contains('d-none') ? null : elementoHTML.classList.add('d-none');
          } else {
            elementoHTML.classList.contains('d-none') ? elementoHTML.classList.remove('d-none') : null;
            elementoHTML.classList.contains('closed') ? elementoHTML.classList.remove('closed') : null;
          }
        }
      }
    }
  }

  obtenerNombreMes(mes: number): string {
    const meses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    // Verificar que el número esté entre 1 y 12
    if (mes && mes >= 1 && mes <= 12) {
        return meses[mes - 1];
    } else {
        return "";
    }
  }

  obtenerTipoString(tipo: string): string {
    if(tipo === 'balance'){
      return 'Balance';
    } else if(tipo === 'prebalance') {
      return 'Pre Balance';
    } else {
      return '';
    }
  }

  validaVariables(variables: any[], nivelKey: string): boolean {
    let respuesta: boolean = true;
    if(variables && variables.length === 1 && nivelKey) {
      const variable = variables[0] || [];
      if(nivelKey === variable?.[0]?.key){
        respuesta = false
      }
    } 
    return respuesta;
  }

  validaCampo(campo: any[]): boolean {
    let respuesta: boolean = true;
    if(campo && campo.length > 0) {
      for (const property in this.contratoResultados) {
        if(this.contratoResultados[property]?.nivel1){
          if(this.contratoResultados[property]?.nivel1 === campo?.[0]?.key) return false;
        }
        if(this.contratoResultados[property]?.nivel2){
          if(this.contratoResultados[property]?.nivel2 === campo?.[0]?.key) return false;
        }
        if(this.contratoResultados[property]?.nivel3){
          if(this.contratoResultados[property]?.nivel3 === campo?.[0]?.key) return false;
        }
      }
    } 
    return respuesta;
  }

  obtenerDatosColumna(index: number): string {
    if(index >= 0){
      const tipo: string = this.valores[index]?.tipo || '';
      const periodo: string = this.valores[index]?.periodo.toString() || '';
      const mesInicio: number = this.valores[index]?.mesInicio || '';
      const mesFin: number = this.valores[index]?.mesFin || '';
      return `${tipo} ${periodo} ${this.obtenerNombreMes(mesInicio)} - ${this.obtenerNombreMes(mesFin)}`;
    }
    return '';
  }

  validaXlsx(): boolean {
    return this.valores.some(e => e?.xlsx);
  }

  validaXlsxReporte(reporte: any): boolean {
    if(reporte && Object.keys(reporte).length > 0 && reporte?.xlsx)
      return true;
    
    return false;
  }

  descargarExcel(reporte: any): any {
    if(reporte?.xlsx){
      const downloadLink = document.createElement('a');
      const fileName = `${reporte.tipo}_${reporte?.subTipo}_${this.rut}_${reporte?.periodo}_${reporte?.mesInicio}_${reporte?.mesFin}.xlsx`;
      downloadLink.href = 'data:application/octet-stream;base64,' + reporte?.xlsx;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

}
