import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { SessionService } from 'src/app/shared/services/session.service';
import { MercadoPublicoService } from 'src/app/shared/services/mercado-publico.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { environment as env } from 'src/environments/environment';
import * as XLSX from 'xlsx';

interface licitacionFormValues {
  fecha: string;
}

@Component({
  selector: 'app-reporte-licitacion-fecha',
  templateUrl: './reporte-licitacion-fecha.component.html',
  styleUrls: ['./reporte-licitacion-fecha.component.scss']
})
export class ReporteLicitacionFechaComponent implements OnInit {
  public searchLicitacionForm: UntypedFormGroup;
  public fechaSeleccionada: string = '';
  public licitaciones: any[] = [];
  public licitacionesFiltrado: any[] = [];
  public showList: boolean = false;
  public showReport: boolean = false;
  public showForm: boolean = true;
  public licitacion: any = {};
  public licitacionCodigo: string = '';
  private access: string[] = [ESystemAccess.MERCADO_PUBLICO, ESystemAccess.MERCADO_PUBLICO_BUSCAR, ESystemProfileList.WEB];
  public hasUserAccess: boolean = false;
  private mapeoEstado: any = {
    "5": "Publicada",
    "6": "Cerrada",
    "7": "Desierta",
    "8": "Adjudicada",
    "18": "Revocada",
    "19": "Suspendida"
  };

  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public inputText = '';
  public order = '';
  public campoOrder = '';
  public min = '';
  public max = '';

  constructor(
    private spinner: NgxSpinnerService,
    public alertService: AlertService,
    private _sessionService: SessionService,
    private mercadoPublicoService: MercadoPublicoService,
    private formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title
  ) {
    this.searchLicitacionForm = this.formBuilder.group({
      fecha: ['', [Validators.required]],
    }) as FormGroupTyped<licitacionFormValues>;
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserAccess().includes(this.access[1]) && this._sessionService.getUserProfileList().includes(this.access[2])) {
      this.hasUserAccess = true;
      this.setMaxMinDate();
    }
  }

  setMaxMinDate(): void {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    const monthFormatted = (month + 1 < 10) ? `0${month + 1}` : `${month + 1}`;
    const dayFormatted = (day - 1 < 10) ? `0${day - 1}` : `${day - 1}`;
    this.min = `2023-11-01`;
    this.max = `${year}-${monthFormatted}-${dayFormatted}`;
  }

  submitFecha(): void {
    const { fecha } = this.searchLicitacionForm.value;
    if (this.searchLicitacionForm.valid) {
      this.fechaSeleccionada = fecha;
      const FechaFormatted: string = this.convertirFecha(fecha);
      this.buscarLicitaciones(FechaFormatted);
    } else {
      this.alertService.warn("Debe ingresar una fecha valida");
    }
  }

  buscarLicitaciones(fecha: string, intentos: number = 5): void {
    this.spinner.show();
    if(intentos > 0){

      this.mercadoPublicoService.obtenerLicitaciones(fecha).subscribe(
      (data: any) => {
        if(data === null){
          this.buscarLicitaciones(fecha, intentos - 1);
        } else {
          if(data?.Cantidad > 0 && data?.Listado.length > 0){
            this.generarArrayLicitaciones(data?.Listado);
            this.showList = true;
            this.showForm = false;
            this.showReport = false;
          } else {
            this.alertService.error('No se han encontrado licitaciones en la fecha indicada');
          }
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al consultar por las licitaciones');
        this.spinner.hide();
      });

    } else {

      if(this.licitaciones.length > 0){
        this.showList = true;
        this.showForm = false;
        this.showReport = false;
        this.spinner.hide();
      } else {
        this.alertService.error('No se han encontrado licitaciones en la fecha indicada');
        this.spinner.hide();
      }

    }
  }

  actualizarTabla(intentos: number = 3): void {
    const fecha: string = this.convertirFecha(this.fechaSeleccionada);
    this.spinner.show();
    if(intentos > 0){
      this.mercadoPublicoService.obtenerLicitaciones(fecha).subscribe(
      (data: any) => {
        if(data != null){
          if(data?.Cantidad > 0 && data?.Listado.length > 0){
            this.generarArrayLicitaciones(data?.Listado);
          } else {
            this.alertService.error('No se han encontrado licitaciones en la fecha indicada');
          }
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al consultar por las licitaciones');
        this.spinner.hide();
      });

    } else {
      this.spinner.hide();
    }
  }

  generarArrayLicitaciones(listado: any[]): void {
    this.licitaciones = [];
    this.licitacionesFiltrado = [];
    for (const elemento of listado) {
      const objeto: any = {
        "Codigo": elemento?.CodigoExterno || '',
        "Descripcion": elemento?.Nombre || '',
        "Estado": this.mapeoEstado?.[elemento?.CodigoEstado] || elemento.CodigoEstado?.toString() || '',
        "NombreOrganismo": elemento?.NombreOrganismo || '',
        "RutOrganismo": elemento?.RutUnidad || '',
        "RutProveedor": '',
        "NombreProveedor": ''
      };

      if (elemento?.Adjudicaciones && elemento?.Adjudicaciones.length > 0) {
        const uniqueAdjudicaciones = elemento.Adjudicaciones.reduce((acc: any[], curr: any) => {
          const existingAdjudicacion = acc.find((item: any) => item.RutProveedor === curr.RutProveedor);
          if (!existingAdjudicacion) {
            acc.push(curr);
          }
          return acc;
        }, []);

        for(const adjudicacion of uniqueAdjudicaciones){
          const objetoAdjudicacion: any = JSON.parse(JSON.stringify(objeto));
          objetoAdjudicacion.RutProveedor = adjudicacion?.RutProveedor || '';
          objetoAdjudicacion.NombreProveedor = adjudicacion?.NombreProveedor || '';
          this.licitaciones.push(objetoAdjudicacion);
        }

      } else {
        this.licitaciones.push(objeto);
      }
    }
    this.filtrarObjeto();
  }

  filtroRut(rut: string, entrada: string): boolean {
    //const rutSinGuion = rut.replace(/\\-/g, '');
    //const rutSinPuntos = rut.replace(/\\./g, '');
    //const rutEntradaSinGuion = entrada.replace(/\\-/g, '');
    const rutEntradaSinPuntos = entrada.replace(/\\./g, '');
    return rut.toUpperCase().includes(rutEntradaSinPuntos.toUpperCase());
  }

  filtrarObjeto(): void {
    this.currentPage = 0;
    this.licitacionesFiltrado = this.inputText != '' ? this.licitaciones.filter((item: any) => 
    item?.Codigo?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.Descripcion?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.Estado?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.NombreOrganismo?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.NombreProveedor?.toUpperCase().includes(this.inputText.toUpperCase())
    || this.filtroRut(item?.RutOrganismo, this.inputText) // rut
    || this.filtroRut(item?.RutProveedor, this.inputText) // rut
    ) : this.licitaciones;
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      this.licitacionesFiltrado.sort((a, b) => {
        const textA = a[campo]?.toString() || '';
        const textB = b[campo]?.toString() || '';

        if (!textA && !textB) {
          return 0; // Both fields are empty, consider them equal
        } else if (!textA) {
          return 1; // Field A is empty, move it to the end
        } else if (!textB) {
          return -1; // Field B is empty, move it to the end
        } else {
          return textA.localeCompare(textB); // Compare the fields normally
        }
      });
    } else {
      this.licitacionesFiltrado.sort((a, b) => {
        const textA = a[campo].toString();
        const textB = b[campo].toString();
        //return (-1 * textA.localeCompare(textB));

        if (!textA && !textB) {
          return 0; // Both fields are empty, consider them equal
        } else if (!textA) {
          return 1; // Field A is empty, move it to the end
        } else if (!textB) {
          return -1; // Field B is empty, move it to the end
        } else {
          return (-1 * textA.localeCompare(textB)); // Compare the fields in descending order
        }
      });
    }
  }

  buscarLicitacion(codigo: string): void {
    if(codigo) {
      this.spinner.show();
      this.mercadoPublicoService.obtenerLicitacion(codigo).subscribe(
      (data: any) => {
        if(data?.Reporte?.Cantidad > 0 && data?.Reporte?.Listado?.[0] && Object.keys(data?.Reporte?.Listado?.[0]).length > 0){
          this.licitacion = data?.Reporte?.Listado?.[0] || {};
          this.showList = false;
          this.showReport = true;
          this.licitacionCodigo = codigo;
          this.showForm = false;
        } else {
          this.alertService.error('No se ha encontrado información de la licitación');
        }
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al consultar por la licitación seleccionada');
        this.spinner.hide();
      });
    }
  }

  convertirFecha(fecha: string): string {
    const partes = fecha.split('-');
    
    const dia = partes[2].padStart(2, '0');
    const mes = partes[1].padStart(2, '0');
    const ano = partes[0];

    return dia + mes + ano;
  }

  clearForm(): void {
    this.searchLicitacionForm.reset();
  }

  closeReport(): void {
    this.licitacion = {};
    this.licitacionCodigo = '';
    this.showList = true;
    this.showReport = false;
    this.showForm = false;
  }

  volverForm(): void {
    this.licitaciones = [];
    this.licitacionesFiltrado = [];
    this.licitacionCodigo = '';
    this.licitacion = {};
    this.showList = false;
    this.showReport = false;
    this.showForm = true;
  }

  formatFecha(inputFecha: string) {
    // Dividir la fecha en sus partes (año, mes, día)
    const partesFecha = inputFecha.split('-');
    
    // Verificar si la fecha tiene el formato esperado
    if (partesFecha.length !== 3) {
        return "Formato de fecha incorrecto";
    }

    const year = partesFecha[0];
    const month = partesFecha[1];
    const day = partesFecha[2];

    // Crear una nueva fecha con el formato 'dd-MM-yyyy'
    const fechaFormateada = `${day}-${month}-${year}`;

    return fechaFormateada;
}

  //Funcion para obtener letra a partir de numero
  getColName(n: number): string {
    const ordA = 'a'.charCodeAt(0);
    const ordZ = 'z'.charCodeAt(0);
    const len = ordZ - ordA + 1;
  
    let s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s;
  }

  descargarExcel(): void{
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // Inicio Hoja Horizontal

    // Cabecera
    const arrayCabecera: any[] = [
      {A: `Listado Licitaciones para la Fecha: ${this.formatFecha(this.fechaSeleccionada)}`}
    ];

    if(this.inputText != '') {
      arrayCabecera.push({A: `Filtrado por texto: "${this.inputText}"`});
    }
    arrayCabecera.push({A: ""});

    const wsh = XLSX.utils.json_to_sheet(arrayCabecera, {header: ["A"], skipHeader: true});

    const headerH: string[] = [];
    const wscolsH: any[] = [];

    // Cabecera y ancho
    for(let i = 0; i <= 7; i++){
      headerH.push(this.getColName(i).toUpperCase());
      wscolsH.push({ width: 30 });
    }

    XLSX.utils.sheet_add_json(wsh, [
      { A: "Código", B: "Descripción", C: "Estado",
        D: "Nombre Organismo", E: "Rut Organismo", F: "Rut Proveedor", G: "Nombre Proveedor" }
    ], {header: headerH, skipHeader: true, origin: -1});

    this.licitacionesFiltrado.forEach((licitacion: {Codigo: string, Descripcion: string, Estado: string, NombreOrganismo: string, RutOrganismo: string, RutProveedor: string, NombreProveedor: string}) => {

      XLSX.utils.sheet_add_json(wsh, [
        { 
          A: licitacion.Codigo, B: licitacion.Descripcion, C: licitacion.Estado,
          D: licitacion.NombreOrganismo,
          E: licitacion.RutOrganismo,
          F: licitacion.RutProveedor,
          G: licitacion.NombreProveedor
        }
        
      ], {header: headerH, skipHeader: true, origin: -1});

    });

    if(wscolsH[1] && wscolsH[1].width)
      wscolsH[1].width = 90;


    //Combinacion de celdas para cabeceras
    const mergeh = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 2 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 2 } }
    ];
    if(this.inputText != '') {
      mergeh.push({ s: { r: 2, c: 0 }, e: { r: 2, c: 2 } })
    }
    wsh["!merges"] = mergeh;

    // Ancho de columnas
    wsh["!cols"] = wscolsH;


    XLSX.utils.book_append_sheet(wb, wsh, "Listado Licitaciones");
    // Fin Hoja Horizontal

    const today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;  

    XLSX.writeFile(wb, "Licitaciones_" + this.formatFecha(this.fechaSeleccionada) + "_" + today.toString()  + ".xls");

  }

}
