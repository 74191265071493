<div class="mx-auto mb-5 div-print">
    <h2 class="text-center pt-3">{{obtenerTitulo()}} MANUAL</h2>
    <h5 class="text-center">
        <span class="badge bg-secondary text-center">
            <span *ngIf="rut">
                {{rut | rutFormat | uppercase}}
            </span>
        </span>
    </h5>

    <div class="row">

        <div class="col-sm-12">
            <div class="container-balance p-5 bg-light">

                <div class="row print-container">

                    <div class="col-sm-12 d-flex justify-content-center mb-3 no-print">
                        <div class="btn-group btn-group-toggle" data-toggle="buttons">
                            <label class="btn btn-info active" (click)="changeVisualizacion(false)">
                                <input type="radio" name="options" id="option1" checked> Visualización Resumida
                            </label>
                            <label class="btn btn-info" (click)="changeVisualizacion(true)">
                                <input type="radio" name="options" id="option2"> Visualización Extendida
                            </label>
                        </div>
                    </div>

                    <div class="col-sm-12 col-lg-10">
                        <div class="card w-100 mx-auto border-0">
                            <div class="card-body">

                                <div class="table-responsive">
                                    <table class="table table-bordered table-hover border my-0">
                                        <thead>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Año</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{valor?.periodo}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Tipo de balance (1)</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{obtenerTipoString(valor?.tipo)}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Tipo de balance (2)</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{valor?.subTipo || ''}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Auditado</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{valor?.auditado === true ? 'Si' : (valor?.auditado === false ? 'No' : '')}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Moneda</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{valor?.moneda || ''}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Desde</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{obtenerNombreMes(valor?.mesInicio)}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr>
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Hasta</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <th class="align-middle font-size">
                                                        {{obtenerNombreMes(valor?.mesFin)}}
                                                    </th>
                                                </ng-container>
                                            </tr>
                                            <tr class="no-print" *ngIf="validaXlsx()">
                                                <th width="40%" class="align-middle font-size font-weight-bold bg-nivel2">Excel Cargado</th>
                                                <ng-container *ngFor="let valor of valores">
                                                    <ng-container *ngIf="validaXlsxReporte(valor); else elseExcel">
                                                        <th class="align-middle font-size">
                                                            <button type="button" class="btn btn-success" (click)="descargarExcel(valor)">Descargar <i class="fa fa-file-excel-o" aria-hidden="true"></i></button>
                                                        </th>
                                                    </ng-container>
                                                    <ng-template #elseExcel>
                                                        <th class="align-middle font-size">
                                                            -
                                                        </th>
                                                    </ng-template>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 px-0" *ngIf="visualizacionExtendida">
                        <app-balance-manual-input-view
                            [arrayContrato]="arrayContratoInput" 
                            [valores]="valores" 
                            [contratoResultados]="contratoResultadosInput"
                        ></app-balance-manual-input-view>
                    </div>

                    
                </div>
                
                <div class="row print-container" [ngClass]="{'break-box-b': visualizacionExtendida}">
                    <div class="col-sm-12 px-0">
                        <ng-container *ngFor="let nivel1 of arrayContrato; let inivel1 = index;">
    
                            <div class="break-box">
                                <ng-container *ngIf="!nivel1?.nivel1; else nivel1Template">
        
                                    <ng-container *ngIf="nivel1?.variables && nivel1?.variables.length > 0">
                                        <div class="card w-100 mx-auto border-0">
                                            <div class="card-body">
        
                                                <div class="table-responsive">
                                                    <table class="table table-bordered table-hover border my-0 table-balance">
                                                        <tbody>
                                                            <ng-container *ngFor="let campo of nivel1?.variables">
        
                                                                <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: ''} }"></ng-container>
                
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
        
                                            </div>
                                        </div>
                                    </ng-container>
                                    
                                </ng-container>
        
                                <ng-template #nivel1Template>
        
                                    <div class="card w-100 mx-auto border-0">
                                        <div class="card-body">
        
                                            <div class="table-responsive">
                                                <table class="table table-bordered table-hover border my-0 table-balance">
                                                    <thead>
                                                        <tr class="bg-nivel1" [ngClass]="{'cursor-pointer': validaVariables(nivel1?.variables, nivel1?.nivel1)}" (click)="toggleContent($event, 'header-nivel1' + inivel1)">
                                                            <th scope="col" rowspan="2" class="align-middle font-size font-weight-bold bg-nivel1">
                                                                {{  nivel1?.nivel1Name  }}
                                                            </th>
    
                                                            <ng-container *ngFor="let valor of valores; let i = index">
                                                                <th rowspan="1" class="align-middle font-size text-right bg-nivel1">
                                                                    <div class="row">
                                                                        <div class="col-12 text-center text-white text-capitalize">
                                                                            {{ obtenerDatosColumna(i) || '' }}
                                                                        </div>
                                                                        <div class="col-12 text-center text-white">
                                                                            ({{ i + 1 }})
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </ng-container>
    
                                                            <ng-container *ngFor="let calculo of calculosWeb">
            
                                                                <ng-container *ngFor="let item of obtenerArrayCabeceraCalculos(nivel1?.nivel1, calculo)">
                                                                    <th rowspan="1" class="align-middle font-size text-right bg-nivel1">
                                                                        {{item || ''}}
                                                                    </th>
                                                                </ng-container>
                                                                
                                                            </ng-container>
                                                            
                                                        </tr>
                                                        <tr class="bg-nivel1" [ngClass]="{'cursor-pointer': validaVariables(nivel1?.variables, nivel1?.nivel1)}" (click)="toggleContent($event, 'header-nivel1' + inivel1)">
                                                            <ng-container *ngFor="let valor of valores">
                                                                <th rowspan="1" class="align-middle font-size text-right bg-nivel1">
                                                                    {{(obtenerValorTotal(nivel1?.nivel1, valor) || obtenerValorTotal(nivel1?.nivel1, valor) == 0) ? 
                                                                        (obtenerValorTotal(nivel1?.nivel1, valor) | number: '1.0-2') : 
                                                                        ('')
                                                                    }}
                                                                </th>
                                                            </ng-container>
    
                                                            <ng-container *ngFor="let calculo of calculosWeb">
        
                                                                <!--[0,1,2,3...]-->
                                                                <ng-container *ngIf="validaHeaderCalculadoNivel(calculo, nivel1?.nivel1)">
    
                                                                    <ng-container *ngFor="let item of generateArrayByContract(calculo?.columnsLength)">
                                                                        <ng-container *ngIf="validaHeaderCalculadoCampo(calculo, nivel1?.nivel1) && validaMeses(item, calculo); else elseThVacio1">
                                                                            <th class="align-middle font-size text-right bg-nivel1">
                                                                                {{(obtenerValorCalculadoHeader(item, nivel1?.nivel1, calculo) || obtenerValorCalculadoHeader(item, nivel1?.nivel1, calculo) === 0) ? 
                                                                                    ((obtenerValorCalculadoHeader(item, nivel1?.nivel1, calculo) | number: '1.0-2') + (calculo?.simbolo || '')) : 
                                                                                    ('') 
                                                                                }}
                                                                            </th>
                                                                        </ng-container>
                                                                        <ng-template #elseThVacio1>
                                                                            <th class="align-middle font-size text-right bg-nivel1"></th>
                                                                        </ng-template>
                                                                    </ng-container>
                                                                    
                                                                </ng-container>
                                                                
                                                            </ng-container>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngIf="nivel1?.nivel2 && nivel1?.nivel2.length > 0; else notNivel2Template">
        
                                                            <ng-container *ngFor="let nivel2 of nivel1?.nivel2; let inivel2 = index;">
            
                                                                <ng-container *ngIf="!nivel2?.nivel2; else nivel2Template">
            
                                                                    <ng-container *ngFor="let campo of nivel2?.variables">
                                                                        <ng-container *ngIf="validaCampo(campo)">
                
                                                                            <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' +inivel2} }"></ng-container>
                
                                                                        </ng-container>
                                                                    </ng-container>
            
                                                                </ng-container>
                                                                <ng-template #nivel2Template>
                                                                    <tr [id]="'header-nivel1' + inivel1" class="bg-nivel2" [ngClass]="{'cursor-pointer': validaVariables(nivel2?.variables, nivel2?.nivel2)}" (click)="toggleContent($event, 'header-nivel1' + inivel1 + '-nivel2' + inivel2)">
                                                                        <th scope="col" class="align-middle font-size bg-nivel2">
                                                                            {{  nivel2?.nivel2Name  }}
                                                                        </th>
                                                                        <ng-container *ngFor="let valor of valores">
                                                                            <th class="align-middle font-size text-right bg-nivel2">
                                                                                {{(obtenerValorTotal(nivel2?.nivel2, valor) || obtenerValorTotal(nivel2?.nivel2, valor) == 0) ? 
                                                                                    (obtenerValorTotal(nivel2?.nivel2, valor) | number: '1.0-2') : 
                                                                                    ('')
                                                                                }}
                                                                            </th>
                                                                        </ng-container>
    
                                                                        <ng-container *ngFor="let calculo of calculosWeb">
    
                                                                            <ng-container *ngIf="validaHeaderCalculadoNivel(calculo, nivel1?.nivel1)">
    
                                                                                <ng-container *ngFor="let item of generateArrayByContract(calculo?.columnsLength)">
                                                                                    <ng-container *ngIf="validaHeaderCalculadoCampo(calculo, nivel2?.nivel2) && validaMeses(item, calculo); else elseThVacio2">
                                                                                        <th class="align-middle font-size text-right bg-nivel2">
                                                                                            {{(obtenerValorCalculadoHeader(item, nivel2?.nivel2, calculo) || obtenerValorCalculadoHeader(item, nivel2?.nivel2, calculo) === 0) ? 
                                                                                                ((obtenerValorCalculadoHeader(item, nivel2?.nivel2, calculo) | number: '1.0-2') + (calculo?.simbolo || '')) : 
                                                                                                ('') 
                                                                                            }}
                                                                                        </th>
                                                                                    </ng-container>
                                                                                    <ng-template #elseThVacio2>
                                                                                        <th class="align-middle font-size text-right bg-nivel2"></th>
                                                                                    </ng-template>
                                                                                </ng-container>
                                                                                
                                                                            </ng-container>
                                                                            
                                                                        </ng-container>
                                                                    </tr>
                                                                    <ng-container *ngIf="nivel2?.nivel3 && nivel2?.nivel3.length > 0; else notNivel3Template">
            
                                                                        <ng-container *ngFor="let nivel3 of nivel2?.nivel3; let inivel3 = index;">
                                                                            
                                                                            <ng-container *ngIf="!nivel3?.nivel3; else nivel3Template">
    
                                                                                <ng-container *ngFor="let campo of nivel3?.variables">
                                                                                    <ng-container *ngIf="validaCampo(campo)">
            
                                                                                        <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' + inivel2 + '-' + 'nivel3' + inivel3 + 'element'} }"></ng-container>
                
                                                                                    </ng-container>
                                                                                </ng-container>
        
        
                                                                            </ng-container>
                                                                            <ng-template #nivel3Template>
        
                                                                                <tr [id]="'header-nivel1' + inivel1 + '-nivel2' + inivel2" class="bg-nivel3" [ngClass]="{'cursor-pointer': validaVariables(nivel3?.variables, nivel3?.nivel3)}" (click)="toggleContent($event, 'header-nivel1' + inivel1 + '-nivel2' + inivel2 + '-nivel3' + inivel3 + 'element')">
                                                                                    <th scope="col" class="align-middle font-size bg-nivel3">
                                                                                        {{  nivel3?.nivel3Name  }}
                                                                                    </th>
                                                                                    <ng-container *ngFor="let valor of valores">
                                                                                        <th class="align-middle font-size text-right bg-nivel3">
                                                                                            {{(obtenerValorTotal(nivel3?.nivel3, valor) || obtenerValorTotal(nivel3?.nivel3, valor)) ? 
                                                                                            (obtenerValorTotal(nivel3?.nivel3, valor) | number: '1.0-2') :
                                                                                            ('')
                                                                                        }}
                                                                                        </th>
                                                                                    </ng-container>
    
                                                                                    <ng-container *ngFor="let calculo of calculosWeb">
    
                                                                                        <ng-container *ngIf="validaHeaderCalculadoNivel(calculo, nivel1?.nivel1)">
    
                                                                                            <ng-container *ngFor="let item of generateArrayByContract(calculo?.columnsLength)">
                                                                                                <ng-container *ngIf="validaHeaderCalculadoCampo(calculo, nivel3?.nivel3) && validaMeses(item, calculo); else elseThVacio3">
                                                                                                    <th class="align-middle font-size text-right bg-nivel3">
                                                                                                        {{(obtenerValorCalculadoHeader(item, nivel3?.nivel3, calculo) || obtenerValorCalculadoHeader(item, nivel3?.nivel3, calculo) === 0) ? 
                                                                                                            ((obtenerValorCalculadoHeader(item, nivel3?.nivel3, calculo) | number: '1.0-2') + (calculo?.simbolo || '')) : 
                                                                                                            ('') 
                                                                                                        }}
                                                                                                    </th>
                                                                                                </ng-container>
                                                                                                <ng-template #elseThVacio3>
                                                                                                    <th class="align-middle font-size text-right bg-nivel3"></th>
                                                                                                </ng-template>
                                                                                            </ng-container>
                                                                                            
                                                                                        </ng-container>
                                                                                        
                                                                                    </ng-container>
                                                                                </tr>
        
                                                                                <ng-container *ngFor="let campo of nivel3?.variables">
                                                                                    
                                                                                    <ng-container *ngIf="validaCampo(campo)">
                                                                                        <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' + inivel2 + '-' + 'nivel3' + inivel3 + 'element'} }"></ng-container>
                                                                                    </ng-container>
            
                                                                                </ng-container> 
        
                                                                            </ng-template>
        
                                                                        </ng-container>
        
                                                                    </ng-container>
        
                                                                    <ng-template #notNivel3Template>
    
                                                                        <ng-container *ngFor="let campo of nivel2?.variables">
    
                                                                            <ng-container *ngIf="validaCampo(campo)">
                                                                                <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1 + '-' + 'nivel2' + inivel2} }"></ng-container>
                                                                            </ng-container>
    
                                                                        </ng-container>
        
                                                                    </ng-template>
            
                                                                </ng-template>
            
                                                            </ng-container>
            
                                                        </ng-container>
                                                        <ng-template #notNivel2Template>
    
                                                            <ng-container *ngFor="let campo of nivel1?.variables">
                                                                
                                                                <ng-container *ngIf="validaCampo(campo)">
                                                                    <ng-container *ngTemplateOutlet="inputsTemplate; context: { $implicit: {campo: campo, index: 'nivel1' + inivel1} }"></ng-container>
                                                                </ng-container>
    
                                                            </ng-container>
                                                            
                                                        </ng-template>
                                                    </tbody>
                                                </table>
                                            </div>
        
                                        </div>
                                    </div>
        
                                </ng-template>
                            </div>
        
                        </ng-container>
                    </div>

                </div>

            </div>
        </div>

    </div>
</div>



<!-- Template td -->
<ng-template #inputsTemplate let-elemento>

    <tr [id]="'header-' + elemento?.index">

        <ng-container *ngFor="let item of elemento?.campo; let i = index">
            <td class="font-size align-middle text-right">
                {{ (item?.valor || item?.valor == 0) ? (esNumeroOStringNumerico(item?.valor) ? (item?.valor | number: '1.0-2'): (item?.valor)) : ('') }}
            </td>
        </ng-container>

        <ng-container *ngFor="let calculo of calculosWeb">
            
            <ng-container *ngIf="calculo?.columnsLength && objectKeys(calculo?.columnsLength).length > 0 && validaNivel(elemento, calculo?.nivel1)">

                <ng-container *ngFor="let item of generateArrayByContract(calculo?.columnsLength)">
                    <td class="font-size align-middle text-right">
                        <ng-container *ngIf="validaException(elemento, calculo?.exception) && validaMeses(item, calculo)">
                            {{(obtenerValorCalculado(item, elemento?.campo, calculo) || obtenerValorCalculado(item, elemento?.campo, calculo) === 0) ? 
                                ((obtenerValorCalculado(item, elemento?.campo, calculo) | number: '1.0-2') + (calculo?.simbolo || '')) : 
                                ('') 
                            }}
                        </ng-container>
                    </td>
                </ng-container>

            </ng-container>
            
        </ng-container>
        
    </tr>
    
</ng-template>