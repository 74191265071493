import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { CredencialesService } from 'src/app/shared/services/credenciales.service';
import { AlertService } from 'src/app/components/_alert';
import { environment as env } from 'src/environments/environment';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { RUT } from 'src/app/shared/utils/rut';

interface RutFormValue {
  username: string;
  password: string;
  orgAccess: string;
}

@Component({
  selector: 'app-credenciales-organizacion',
  templateUrl: './credenciales-organizacion.component.html',
  styleUrls: ['./credenciales-organizacion.component.scss']
})
export class CredencialesOrganizacionComponent implements OnInit {
  @ViewChildren("validacredenciales") validaCredencialesButton: QueryList<ElementRef> | undefined;
  public showGuardar: boolean = false;

  @Input() credsNameEntrada: string = '';
  @Output() actualizaMensaje = new EventEmitter<any>();
  
  public listaOrganizacion: any = {};
  public tablaServicios: any[] = [];
  public tablaServiciosFiltrado: any[] = [];
  public seeTable: boolean = true;
  public currentPage = 0;
  public currentItem = 10;
  public inputText: string = '';
  public order = '';
  public campoOrder = '';
  public servicioConfigurar: any = {};
  public configurarForm!: UntypedFormGroup;
  public subscriptionForm!: Subscription;
  public optionsPaginator: number[] = [10, 25, 50, 100];

  public activateRutHelper: any = {};

  public ultimosDatos: any[] = [];
  public classButtonValidation: string = 'btn-light';

  private access: string[] = [ESystemAccess.CLIENTES_USER_ADMIN, ESystemProfileList.WEB];
  public hasUserAccess = false;

  constructor(
    private spinner: NgxSpinnerService,
    private credencialesService: CredencialesService,
    public alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private utilsService: UtilsService,
    private _sessionService: SessionService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);

    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;

      if(this.credsNameEntrada !== ''){
        this.obtenerFromModal();
      } else {
        this.obtenerLista();
      }

    }

  }

  // Funcion para usar vista desde modal
  obtenerFromModal(): void {
    this.ultimosDatos = [];
    this.classButtonValidation = 'btn-light';
    this.spinner.show();
    this.credencialesService.listarSecretosOrgyUsuarios().subscribe(
      (data: any) => {
        this.listaOrganizacion = data?.OrgConfiguration || {};
        this.tablaServicios = this.listaOrganizacion?.services?.services || [];
        if((this.tablaServicios.length > 0)){
          const servicioEncontrado = this.tablaServicios.find(e => e.name === this.credsNameEntrada)
          if(servicioEncontrado){
            this.servicioConfigurar = servicioEncontrado;
            this.initForm();
            this.generarUltimosDatos();
            this.seeTable = false;
            this.spinner.hide();
          } else {
            this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: 'No se ha encontrado el servicio indicado.'});
            this.spinner.hide();
          }
        } else {
          this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: 'No se han encontrado servicios para ser configurados.'});
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: (error.message || 'Ha ocurrido un error al obtener el listado.')});
        this.seeTable = true;
        this.spinner.hide();
    });
  }

  obtenerLista(): void {
    this.ultimosDatos = [];
    this.classButtonValidation = 'btn-light';
    this.spinner.show();
    this.credencialesService.listarSecretosOrgyUsuarios().subscribe(
      (data: any) => {
        this.listaOrganizacion = data?.OrgConfiguration || {};
        this.tablaServicios = this.listaOrganizacion?.services?.services || [];
        if((this.tablaServicios.length > 0)){
          this.validaDisplay();
          this.filtrarObjeto();
          this.seeTable = true;
          this.spinner.hide();
        } else {
          this.obtenerContratoServicios();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al obtener el listado.');
        this.seeTable = true;
        this.spinner.hide();
    });
  }

  obtenerContratoServicios(): void {
    this.spinner.show();
    this.credencialesService.obtenerContrato().subscribe(
      (data: any) => {
        this.tablaServicios = data?.services || [];
        if((this.tablaServicios.length > 0)){
          this.validaDisplay();
          this.filtrarObjeto();
          this.seeTable = true;
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al obtener el listado del contrato.');
        this.spinner.hide();
    });
  }

  filtrarObjeto(){
    this.currentPage = 0;
    this.tablaServiciosFiltrado = this.inputText != '' ? this.tablaServicios.filter((item: any) => 
    item?.displayName?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.name?.toUpperCase().includes(this.inputText.toUpperCase())) : this.tablaServicios;
  }

  obtenerOrgAccess(atributos: any[]): boolean | '' {
    const orgAccess = atributos?.find(e => e.name === 'orgAccess');
    if(orgAccess)
      return orgAccess.value || '';
    else 
      return '';
  }

  normalizaNombre(nombre: string): string {
    let nombreNormalizado = nombre?.trim() || '';
    nombreNormalizado = nombreNormalizado.replace(/_/g, " ")
    nombreNormalizado = nombreNormalizado.toLocaleLowerCase();
    nombreNormalizado = nombreNormalizado.charAt(0).toUpperCase() + nombreNormalizado.slice(1);
    return nombreNormalizado;
  }

  validaDisplay(): void {
    this.tablaServicios = this.tablaServicios.filter((element: any) => element?.display?.indexOf("organizacion") > -1);
  }

  cambioDeCampo(campo: string): void{
    if(campo != this.campoOrder){
      this.order = '';
    }
    if((this.order === '' || this.order === 'desc') && campo === this.campoOrder){
      this.order = 'asc';
    } else if((this.order === 'asc') && campo === this.campoOrder){
      this.order = 'desc';
    }
    this.campoOrder = campo;
    this.filtrarColumna(this.campoOrder);
  }

  filtrarColumna(campo: string){
    if(this.order === '' || this.order === 'asc'){
      this.tablaServiciosFiltrado.sort((a, b) => {
        if(campo === 'orgAccess'){
          const orgAccessA = this.obtenerOrgAccess(a?.attributes).toString();
          const orgAccessB = this.obtenerOrgAccess(b?.attributes).toString();
          return orgAccessA.localeCompare(orgAccessB)
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return textA.localeCompare(textB);
        }
      });
    } else {
      this.tablaServiciosFiltrado.sort((a, b) => {
        if(campo === 'orgAccess'){
          const orgAccessA = this.obtenerOrgAccess(a?.attributes).toString();
          const orgAccessB = this.obtenerOrgAccess(b?.attributes).toString();
          return (-1 * orgAccessA.localeCompare(orgAccessB));
        } else {
          const textA = a[campo].toString();
          const textB = b[campo].toString();
          return (-1 * textA.localeCompare(textB));
        }
      });
    }
  }

  configurarServicio(servicio: any): void {
    this.servicioConfigurar = servicio;
    this.showGuardar = false;
    this.initForm();
    this.generarUltimosDatos();

    this.seeTable = false;
  }

  initForm(): void {
    this.configurarForm = this.formBuilder.group({});
    const atributos: any[] = this.servicioConfigurar?.attributes || [];

    for(const [index, atributo] of atributos.entries()){
      if(atributo?.display === true) {
        const validatorsArray: any[] = [Validators.required];
        if(atributo.typeFormat === 'RUT'){
          validatorsArray.push(gtpRutValidator());
          this.activateRutHelper[index] = false;
        }
        this.configurarForm.addControl(atributo?.name, new UntypedFormControl(atributo?.value || '', validatorsArray));
      }
    }
  
    this.eventForm();
  }

  eventForm() {
    const atributos: any[] = this.servicioConfigurar?.attributes || [];
    for(const [index, atributo] of atributos.entries()){
      if(atributo?.display === true && atributo.typeFormat === 'RUT') {
        this.configurarForm.get(atributo?.name)?.valueChanges.subscribe(val => {
          this.activateRutHelper[index] = rutValidate(val) ? false : true;
        });
      }
    }
  }

  typeTraduccion(type: string): string {
    switch(type){
      case 'STRING':
        return 'text';
      case 'BOOLEAN':
        return 'boolean';
      case 'PASSWORD':
        return 'password';
      case 'RUT':
        return 'text';
      case 'DATE':
        return 'date';
      case 'NUMBER':
        return 'number';
      default:
        return 'text';
    }
  }

  onSubmit(): void{
    if (!this.configurarForm.valid) {
      this.utilsService.validateAllFormFields(this.configurarForm);
      if(this.credsNameEntrada === '') {
        this.alertService.error('Complete los campos para crear/actualizar el servicio.');
      }
      return;
    }

    const formTemp = this.configurarForm.getRawValue();

    this.servicioConfigurar?.attributes?.forEach((element: any) => {
      if(element?.display === true){
        element.value = formTemp?.[element.name] || element.value;
      }
    });

    const request = JSON.parse(JSON.stringify(this.servicioConfigurar))
    if(request){
      delete request.configurationStatus;
      delete request.displayName;
    }


    for(const element of request?.attributes) {
      if(element?.display === true){
        if(element.typeFormat === 'RUT' && element.value) {
          element.value = RUT.applyBackendFormat(element.value);
        }
        else if(element.typeFormat === 'BOOLEAN') {
          element.value = element.value == 'true' ? true : (element.value == 'false' ? false : element.value);
        }
      }
    }

    this.spinner.show();
    this.credencialesService.crearActualizarCredenciales("", [request]).subscribe(
      (data: any) => {
        if(this.credsNameEntrada === '') {
          this.alertService.success(data.message || 'Se ha creado/actualizado correctamente el servicio.')
          this.obtenerLista();
        } else {
          // Funcion para usar vista desde modal
          this.actualizaMensaje.emit({alertS: true, alertE: false, mensaje: (data.message || 'Se ha creado/actualizado correctamente el servicio.')});
        }

        this.spinner.hide();
      },
      ({ error }) => {
        if(this.credsNameEntrada === '') {
          this.alertService.error(error.message || 'Ha ocurrido un error al consultar crear/actualizar servicio.');
        } else {
          // Funcion para usar vista desde modal
          this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: (error.message || 'Ha ocurrido un error al consultar crear/actualizar servicio.')});
        }

        this.spinner.hide();
    });

  }

  validaCredenciales(): void {
    const button = this.validaCredencialesButton?.first?.nativeElement;

    if(button){
      if(this.ultimosDatos.length === 0){
        this.alertService.error('No se logra conseguir los campos para validar.');
        return;
      }

      if(!this.servicioConfigurar?.requestValidationCreds && !(Object.keys(this.servicioConfigurar?.requestValidationCreds).length > 0)){
        this.alertService.error('No se logra conseguir los campos para validar.');
        return;
      }

      if (!this.configurarForm.valid) {
        this.utilsService.validateAllFormFields(this.configurarForm);
        if(this.credsNameEntrada === '') {
          this.alertService.error('Complete los campos para crear/actualizar el servicio.');
        }
        return;
      }

      const request: any = {};
      const formTemp: any = this.configurarForm.getRawValue();
      for(const [key, value] of Object.entries(this.servicioConfigurar?.requestValidationCreds)){
        request[key] = "";
        if(value && formTemp?.[value as string]) {
          request[key] = formTemp?.[value as string];
        }
      }

      const service = this.servicioConfigurar?.name;
      let validacionRequest = true;
      for(const [key, value] of Object.entries(request)){
        if(value === '' || value === null || value === undefined){
          validacionRequest = false;
        }
      }

      if(validacionRequest){
        this.actualizaUtimosDatos(request);

        for(const [key, value] of Object.entries(request)){
          const keyValue = this.servicioConfigurar?.requestValidationCreds[key];
          if(keyValue) {
            const element = this.servicioConfigurar?.attributes?.find((e: any) => e.name === keyValue);
            if(element?.typeFormat === 'RUT' && value){
              request[key] = RUT.applyBackendFormat(value as string);
            } else if (element.typeFormat === 'BOOLEAN') {
              request[key] = value == 'true' ? true : (value == 'false' ? false : value);
            }
          } 
        }

        this.spinner.show();
        this.credencialesService.validarCredencialesDinamico(request, service).subscribe(
          (data: any) => {
            if ( button?.classList?.contains('btn-light') === true ){
              button.classList.remove('btn-light');
              button.classList.add('btn-success');
              button.disabled = true;
              button.title = (data.message || 'Se ha creado/actualizado correctamente el servicio.');
              this.classButtonValidation = 'btn-success';
              this.showGuardar = true;
            }
            
            this.spinner.hide();
          },
          ({ error }) => {
            const mensaje: string = error.message ? error.message : error && typeof(error) == 'string' ? error : 'Ha ocurrido un error al consultar la validacion de credenciales.'; 
            if(button) {
              if ( button?.classList?.contains('btn-light') === true ){
                button.classList.remove('btn-light');
                button.classList.add('btn-danger');
                this.classButtonValidation = 'btn-danger';
                button.disabled = true;
                button.title = (mensaje);
              }
            } else {
              if(this.credsNameEntrada === '') {
                this.alertService.error(mensaje);
              } else {
                this.actualizaMensaje.emit({alertS: false, alertE: true, mensaje: (mensaje)});
              }
            }
  
            this.spinner.hide();
        });        
      }


    }
  }

  validaDisabled(): boolean {
    const button = this.validaCredencialesButton?.first?.nativeElement;
    const formTemp = this.configurarForm.getRawValue();
    let response = true;

    for(const element of this.ultimosDatos) {
      const elementForm = this.servicioConfigurar?.requestValidationCreds[element.name];
      if(elementForm){
        if(element.value !== formTemp?.[elementForm]){
          response = false;
        }
      }
    }

    if(response === false){

      this.showGuardar = false;

      if ( button?.classList?.contains('btn-danger') === true ){
        button.classList.remove('btn-danger');
        button.classList.add('btn-light');
      }
      if ( button?.classList?.contains('btn-success') === true ){
        button.classList.remove('btn-success');
        button.classList.add('btn-light');
      }
      return response;
    } else if (response === true) {
      if ( button?.classList?.contains('btn-danger') === true )
        button.classList.remove('btn-danger');
      if ( button?.classList?.contains('btn-success') === true )
        button.classList.remove('btn-success');
      if ( button?.classList?.contains('btn-light') === true )
        button.classList.remove('btn-light');

      if(button?.classList)
        button.classList.add(this.classButtonValidation);

      if(this.classButtonValidation === 'btn-success'){
        this.showGuardar = true;
      }

      return response;
    }

    return response;
  }

  validaInputRut(servicioName: string): boolean {
    if(this.credencialesService.inputRut.includes(servicioName)){
      return true;
    }
    return false;
  }

  validaUserAdmin(): boolean {
    if (this._sessionService.getUserAccess().includes(this.access[0])){
      return true
    }
    return false
  }

  borrarServicio(servicio: any): void {
    if(servicio?.name){
      this.spinner.show();
      this.credencialesService.borrarCredenciales('', servicio?.name).subscribe(
        (data: any) => {
          this.alertService.success(data.message || 'Secreto eliminado exitosamente.');
          this.spinner.hide();
          this.obtenerLista();
        },
        ({ error }) => {
          this.alertService.error(error.message || 'Ha ocurrido un error al borrar las credenciales.');
          this.spinner.hide();
      });
    }
  }

  toggleTypePassword(id: string): void {
    const element = document.getElementById(id);
    if(element){
      if (element.getAttribute('type') === 'password') {
        element.setAttribute('type', 'text');
      } else {
        element.setAttribute('type', 'password');
      }
    }
  }

  generarUltimosDatos(): void {
    if(this.servicioConfigurar?.onlineValidationCreds === true && this.servicioConfigurar?.requestValidationCreds && Object.keys(this.servicioConfigurar?.requestValidationCreds).length > 0){
      
      if(!this.servicioConfigurar?.attributes && !(this.servicioConfigurar?.attributes.length >= 0) ){
        return
      }
      const requestValidationCreds = this.servicioConfigurar?.requestValidationCreds;
      const atributos: any[] = this.servicioConfigurar?.attributes || [];

      for(const [key, value] of Object.entries(requestValidationCreds)){
        const atributo = atributos.find(e => e.name === value);
        if(atributo){
          this.ultimosDatos.push({name: key, value: atributo?.value});
        }
      }

    }
  }

  actualizaUtimosDatos(request: any): void {
    if(request && Object.keys(request).length > 0){
      for(const [key, value] of Object.entries(request)){
        const ultimoDato = this.ultimosDatos.find(e => e.name === key);
        if(ultimoDato){
          ultimoDato.value = value;
        }
      }
    }
  }

  validaShowGuardar(): boolean {
    if(this.showGuardar === true || this.servicioConfigurar?.onlineValidationCreds === false)
      return true;
    
    return false;
  }

}
