<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<ng-container *ngIf="hasUserAccess">

    <div class="card w-100 mx-auto border-0 rounded">
        <div class="card-body p-5">
            <div class="row">
    
                <div class="col-sm-12 my-2">
                    <h4>
                        Credenciales Organización
                    </h4>
                </div>
    
                <div class="col-sm-12" *ngIf="seeTable">
                    <div class="card text-center my-4">
    
                        <div class="card-header">
                            <div class="my-0 row d-flex px-4">
    
                                <div class="col-xs-3 col-sm-auto mr-auto p-2">
                                    <app-paginator-by-items title="Cantidad de filas a mostrar en la tabla" [itemsPerPageInput]="currentItem" [itemsOptions]="optionsPaginator" (itemsPerPage)="currentItem = $event; currentPage = 0"></app-paginator-by-items>
                                </div>
    
                                <div class="col-xs-3 col-sm-auto p-2">
                                    <div class="input-group mb-3">
                                        <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                            title="Ingrese texto para filtrar tabla"
                                            placeholder="Filtrar tabla"
                                            [(ngModel)]="inputText" 
                                            (keyup)="filtrarObjeto()"
                                        >
                                    </div>
                                </div>
                                
                            </div>
                        </div>
    
                        <div class="table-responsive">
                            <table class="table table-hover border my-0">
                                <thead>
                                  <tr>
                                    <th scope="col" class="text-center font-size cursor-pointer"
                                        (click)="cambioDeCampo('name')"
                                    >
                                        Nombre Servicio
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <th scope="col" class="text-center font-size cursor-pointer min-width-th"
                                        (click)="cambioDeCampo('configurationStatus')"
                                    >
                                        Configurada
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <th scope="col" class="text-center font-size cursor-pointer min-width-th-lg"
                                        (click)="cambioDeCampo('orgAccess')"
                                    >
                                        Acceso a nivel de la organización
                                        <div class="float-right">
                                            <i aria-hidden="true" class="fa fa-caret-up d-block"></i>
                                            <i aria-hidden="true" class="fa fa-caret-down d-block"></i>
                                        </div>
                                    </th>
                                    <ng-container *ngIf="validaUserAdmin()">
                                        <th scope="col" class="text-center font-size cursor-pointer"></th>
                                    </ng-container>
                                  </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let servicio of tablaServiciosFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                        <tr>
                                            <td class="font-size cursor-pointer" (click)="configurarServicio(servicio)">
                                                {{ servicio.displayName || '' }}
                                            </td>
                                            <td class="text-center font-size cursor-pointer" (click)="configurarServicio(servicio)">
                                                <ng-container *ngIf="servicio.configurationStatus === true; else elseConfigurationTable">
                                                    <i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                                                </ng-container>
                                                <ng-template #elseConfigurationTable>
                                                    <i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>
                                                </ng-template>                                                </td>
                                            <td class="text-center font-size cursor-pointer" (click)="configurarServicio(servicio)">
                                                <ng-container *ngIf="obtenerOrgAccess(servicio.attributes) === true; else elseOrgAccess">
                                                    <i class="fa fa-check-circle fa-lg text-success" aria-hidden="true"></i>
                                                </ng-container>
                                                <ng-template #elseOrgAccess>
                                                    <i class="fa fa-times-circle fa-lg text-danger" aria-hidden="true"></i>
                                                </ng-template>
                                            </td>
                                            <ng-container *ngIf="validaUserAdmin()">
                                                <td class="text-center font-size">
                                                    <i class="fa fa-trash text-danger cursor-pointer" aria-hidden="true"
                                                        (click)="borrarServicio(servicio)"
                                                    ></i>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
    
                        <div class="card-footer text-muted">
                            <div class="p-2">
                                <div id="pagination">
                                    <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                                        <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
    
                <div class="col-sm-12 my-2" *ngIf="!seeTable">
                    <div class="container-fluid p-3">
                        <div class="row">

                            <ng-container *ngIf="credsNameEntrada === ''">
                                <div class="col-sm-12 mb-3">
                                    <button type="button" class="btn btn-primary" (click)="obtenerLista()">Volver</button>
                                </div>
                            </ng-container>
                            

                            <div class="col-sm-12 my-1 px-5">
                                <form [formGroup]="configurarForm" (ngSubmit)="onSubmit()">
                                    <div class="col-sm-12 my-1 px-5">
                                
                                        <ng-container *ngIf="servicioConfigurar.displayName">
                                            <h4 class="text-center font-weight-bold mb-3">{{ servicioConfigurar.displayName || '' }}</h4>
                                        </ng-container>
                                
                                        <div class="form-row">

                                            <ng-container *ngFor="let servicio of servicioConfigurar?.attributes; let i = index">

                                                <ng-container *ngIf="servicio?.display === true">
                                                    <div class="form-group col-md-8 mx-auto" [ngClass]="{'has-error': activateRutHelper[i] && servicio?.typeFormat === 'RUT'}">
                                                        <label 
                                                            [attr.for]="servicio?.name"
                                                            [attr.title]="servicio?.displayName"
                                                            class="cursor-pointer"
                                                        >
                                                            {{servicio?.displayName || ''}}
                                                        </label>
                                    
                                                        <ng-container [ngSwitch]="typeTraduccion(servicio?.typeFormat)">
                                                            <ng-container *ngSwitchCase="'boolean'">
                                                                <select 
                                                                    class="form-control"
                                                                    [attr.id]="servicio?.name"
                                                                    [formControlName]="servicio?.name"
                                                                    [attr.title]="servicio?.name === 'orgAccess' ? 'Desea que todos los usuarios de la organización puedan utilizar esas credenciales o solo el usuario que las crea' : servicio?.displayName || ''"
                                                                >
                                                                    <option value="">Seleccione una opción</option>
                                                                    <option value="true">Si</option>
                                                                    <option value="false">No</option>
                                                                </select>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'password'">
                                                                <div class="input-group">
                                                                    <input 
                                                                        [attr.type]="'password'"
                                                                        class="form-control border-pass"
                                                                        [attr.id]="servicio.name"
                                                                        [attr.placeholder]="servicio?.displayName || servicio?.name || ''"
                                                                        [formControlName]="servicio.name"
                                                                        [attr.id]="servicio.name"
                                                                        [attr.aria-describedby]="'button-' + servicio.name"
                                                                        [attr.title]="servicio?.name === 'orgAccess' ? 'Desea que todos los usuarios de la organización puedan utilizar esas credenciales o solo el usuario que las crea' : servicio?.displayName || ''"
                                                                    >
                                                                    <button 
                                                                        class="btn btn-outline-primary border" 
                                                                        type="button" 
                                                                        [attr.title]="'Mostrar/Ocultar ' + (servicio?.displayName || servicio?.name || '')" 
                                                                        [attr.id]="'button-' + servicio.name"
                                                                        (click)="toggleTypePassword(servicio.name)"
                                                                    >
                                                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                                                    </button>                                  
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchDefault>
                                                                <ng-container *ngIf="servicio?.typeFormat === 'RUT'; else elseRutInput">
                                                                    <input 
                                                                        class="form-control"
                                                                        [attr.type]="typeTraduccion(servicio?.typeFormat)"
                                                                        [attr.id]="servicio?.name"
                                                                        [attr.placeholder]="servicio?.displayName || servicio?.name || ''"
                                                                        [formControlName]="servicio?.name"
                                                                        [attr.title]="servicio?.name === 'orgAccess' ? 'Desea que todos los usuarios de la organización puedan utilizar esas credenciales o solo el usuario que las crea' : servicio?.displayName || ''"
                                                                        formatRut required
                                                                    >
                                                                </ng-container>
                                                                <ng-template #elseRutInput>
                                                                    <input 
                                                                        class="form-control"
                                                                        [attr.type]="typeTraduccion(servicio?.typeFormat)"
                                                                        [attr.id]="servicio?.name"
                                                                        [attr.placeholder]="servicio?.displayName || servicio?.name || ''"
                                                                        [formControlName]="servicio?.name"
                                                                        [attr.title]="servicio?.name === 'orgAccess' ? 'Desea que todos los usuarios de la organización puedan utilizar esas credenciales o solo el usuario que las crea' : servicio?.displayName || ''"
                                                                    >
                                                                </ng-template>
                                                                
                                                            </ng-container>
                                                        </ng-container>

                                                        <ng-container *ngIf="servicio?.typeFormat === 'RUT'; else elseRut">
                                                            <span *ngIf="activateRutHelper[i]" class="help-block">Rut no válido</span>
                                                        </ng-container>
                                                        <ng-template #elseRut>
                                                            <div class="w-100 mb-2">
                                                                <app-error [control]="configurarForm.get(servicio?.name)"
                                                                ></app-error>
                                                            </div>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>

                                            </ng-container>

                                        </div>
                                    </div>
                                    <div class="col-sm-12 my-1 text-center">
                                
                                        <button #validacredenciales type='button' class="btn btn-light mx-2" *ngIf="servicioConfigurar?.onlineValidationCreds === true" 
                                            (click)="validaCredenciales()"
                                            [disabled]="validaDisabled()">
                                            Validar Credenciales
                                        </button>
                                        
                                        <button *ngIf="validaShowGuardar()" type="submit" class="btn btn-primary mx-2">Guardar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
    
        </div>
    </div>

</ng-container>