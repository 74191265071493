import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MercadoPublicoService {
  private endpoint: string = env.backend.mercadoPublico.url;
  private servicio: string = env.backend.mercadoPublico.servicio;

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
  ) { }

  obtenerLicitaciones(fecha: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("fecha", fecha);
    params = params.append("requestOrigin", "WEB");
    params = params.append("servicio", this.servicio);
    params = params.append("uri", "licitaciones");
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    return this.http.get<any>(`${this.endpoint}obtener`, { params })
  }

  obtenerLicitacion(licitacion: string): Observable<any> {
    const payload: any = {
      idUsuario: this._sessionService.getUserInfo().username,
      idOrganizacion: this._sessionService.getOrganization(),
      requestOrigin: "WEB",
      servicio: this.servicio,
      uri: "transaccion/licitacion",
      licitacion: licitacion
    }
    return this.http.post<any>(`${this.endpoint}crear`, payload);
  }

}
