import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { SessionService } from 'src/app/shared/services/session.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

interface OrganizationFormValues {
  rut: string;
}

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  private homeURI: string = environment.cognitoConfig.baseRedirectURI + "/";

  public organizationForm: FormGroup;
  public organizations: string[] = [];

  constructor(
    private _sessionService: SessionService,
    private formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
  ) {
    this.organizationForm = this.formBuilder.group({
      organization: ['', Validators.required],
    }) as FormGroupTyped<OrganizationFormValues>;
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    const userInfo = this._sessionService.getUserInfo();
    this.organizations = userInfo.organizations;
  }

  get organization() {
    return this.organizationForm.get('organization');
  }

  public changeOrganization(e: any) {
    this.organization!.setValue(e.target.value, {
      onlySelf: true
    });
  }

  public selectOrganization() {
    if (!this.organizationForm.valid) {
      return;
    }

    const organization = this.organizationForm.value["organization"];
    this._sessionService.selectAnOrganization(organization);
    window.location.href = this.homeURI;
  }

}
