import { Component, OnInit } from '@angular/core';
import { ESsystemUserProfile, ESystemOrganizacion } from 'src/app/enum/EAccess';
import { CognitoService, IUserInfoCognito } from 'src/app/shared/services/cognito.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  public username = '';
  public organization = '';
  public userInfo: IUserInfoCognito | null = null;
  public hasOrganizationSelected = false;
  public hasManyOrganization = false;
  public isUser: boolean = false;
  public organizacionValid: boolean = false;
  private access: string = ESsystemUserProfile.USERUNIQUE;
  private accessOrg: string[] = ESystemOrganizacion.ORGANIZACION_ACCESS;
  private organizationURI: string = environment.cognitoConfig.baseRedirectURI + "/organizacion";
  private organizacionValidaFlujoCredito: string[] = ['DESARROLLO', 'salfa300008', 'SOPORTE'];

  constructor(
    private _cognitoService: CognitoService,
    private _sessionService: SessionService) {
  }

  ngOnInit(): void {
    this.userInfo = this._sessionService.getUserInfo();
    this.organization = this._sessionService.getOrganization();
    this.hasManyOrganization = this._sessionService.hasManyOrganizations();
    if (this.organization && this.organization !== '') {
      this.hasOrganizationSelected = true;
    }

    if (this.userInfo && this.userInfo.email.includes(this.access) && this.accessOrg.includes(this._sessionService.getOrganization())) {
      this.isUser = true;
    }

    if (this.accessOrg[0] === this._sessionService.getOrganization()) {      
      this.organizacionValid = true;
    }
  }

  public swithOrganization() {
    this._sessionService.removeSelectedOrganization();
    window.location.href = this.organizationURI;
  }

  public logout() {
    this._cognitoService.logout();
  }

  public validaOrganizacionFlujoCredito(): boolean {
    return this.organizacionValidaFlujoCredito.includes(this.organization); 
  }

}
