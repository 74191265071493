import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompraVentaService } from 'src/app/shared/services/compra-venta.service';
import { AlertService } from 'src/app/components/_alert';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-nc-section',
  templateUrl: './nc-section.component.html',
  styleUrls: ['./nc-section.component.scss']
})
export class NcSectionComponent implements OnInit {
  @Input() errores: any[] = [];
  @Input() rut = '';
  @Input() periodoInicial: any;
  @Input() hasBackLogic: boolean = false;
  @Output() backReport = new EventEmitter<void>();
  
  public filtroFormC: FormGroup;
  public filtroFormV: FormGroup;

  public showSpinnerRutC: boolean = false;
  public showSpinnerRutV: boolean = false;

  public listaRutsC: any [] = [];
  public listaRutsV: any [] = [];
  
  public currentItemC = 10;
  public currentItemV = 10;

  public paginationOptionsC: number[] = [10, 25, 50, 100];
  public paginationOptionsV: number[] = [10, 25, 50, 100];
  
  public paginaActualC: any[] = [];
  public paginaActualV: any[] = [];

  public currentPageC = 0;
  public currentPageV = 0;

  public cantidadRegistrosC: number = 0;
  public cantidadRegistrosV: number = 0;

  private subscriptions: any[] = [];

  public respuestaCalculos: any = {};
  public calulosErrores: {tipo: string, error: string}[] = [];

  public cantidadPaginasV: number = 0;
  public cantidadPaginasC: number = 0;

  public paginasActualesErroresV: {pagina: number, error: string}[] = []
  public paginasActualesErroresC: {pagina: number, error: string}[] = []
  public paginasActualesV: {pagina: number, response: any}[] = [];
  public paginasActualesC: {pagina: number, response: any}[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private compraVentaService: CompraVentaService,
    public alertService: AlertService,
  ) {
    this.filtroFormV = this.formBuilder.group({
      entidadRutV: [''],
      selectPeriodosV: ['']
    }) as FormGroupTyped<any>;

    this.filtroFormC = this.formBuilder.group({
      entidadRutC: [''],
      selectPeriodosC: ['']
    }) as FormGroupTyped<any>;
  }

  ngOnInit(): void {
    if(this.periodoInicial) {
      this.filtroFormC.get('selectPeriodosC')?.setValue('36');
      this.filtroFormV.get('selectPeriodosV')?.setValue('36');
      this.llamadaCalculos(true, true);
      this.onChanges();
    } else {
      this.alertService.error('No se ha logrado recuperar el periodo');
      this.spinner.hide();
      this.logicaRetorno();
    }
  }

  onChanges(): void {
    // Ventas
    this.subscriptions.push(this.filtroFormV.get('entidadRutV')?.valueChanges.subscribe(value => {
      this.llamadaCalculos(false, false, 'VENTA');
    }));

    this.subscriptions.push(this.filtroFormV.get('selectPeriodosV')?.valueChanges.subscribe(value => {
      const periodo = this.filtroFormV.get('selectPeriodosV')?.value || '';
      const rut = this.filtroFormV.get('entidadRutV')?.value || '';

      if(periodo){
        this.llamadaCalculos(rut ? false : true, false, 'VENTA');
      }
    }));

    // Compras
    this.subscriptions.push(this.filtroFormC.get('entidadRutC')?.valueChanges.subscribe(value => {
      this.llamadaCalculos(false, false, 'COMPRA');
    }));

    this.subscriptions.push(this.filtroFormC.get('selectPeriodosC')?.valueChanges.subscribe(value => {
      const periodo = this.filtroFormC.get('selectPeriodosC')?.value || '';
      const rut = this.filtroFormC.get('entidadRutC')?.value || '';

      if(periodo){
        this.llamadaCalculos(rut ? false : true, false, 'COMPRA');
      }
    }));
  }

  logicaRetorno(): void {
    if(this.hasBackLogic) {
      this.backReport.emit();
    }
  }

  setResponseCalculo(response: any, tipo: string): void {
    this.respuestaCalculos[tipo] = response;
  }

  setErrorCalculo(tipo: string, error: string): void {
    this.calulosErrores.push({
      tipo: tipo,
      error: error
    })
  }

  getServiceCalculo(tipo: string): any {
    const meses: string = tipo === 'COMPRA' ? this.filtroFormC.get('selectPeriodosC')?.value || '36' : this.filtroFormV.get('selectPeriodosV')?.value || '36';
    const rutFiltro = tipo === 'COMPRA' ? this.filtroFormC.get('entidadRutC')?.value : this.filtroFormV.get('entidadRutV')?.value;
    const esTipoDocNC: boolean = true;

    return this.compraVentaService.obtenerCalculos(this.rut, tipo, meses, rutFiltro, this.periodoInicial, esTipoDocNC)
      .pipe(
        map(resp => {
          this.setResponseCalculo(resp, tipo);
        })
      )
      .pipe(
        catchError((error) => (this.setErrorCalculo(tipo, error?.error?.message || 'Error Inesperado en servicio'), of(null))));
  }

  obtenerRuts(tipo: string): void {
    const meses: string = tipo === 'COMPRA' ? this.filtroFormC.get('selectPeriodosC')?.value || '36' : this.filtroFormV.get('selectPeriodosV')?.value || '36';
    const esTipoDocNC: boolean = true;

    tipo === 'COMPRA' ? this.showSpinnerRutC = true : this.showSpinnerRutV = true;
    this.compraVentaService.obtenerListaRuts(this.rut, tipo, meses, this.periodoInicial, esTipoDocNC).subscribe( // por validar*
      (resp: any) => {
        if (resp && Array.isArray(resp) && resp.length > 0) {
          for(const element of resp) {
            if(tipo === 'VENTA'){
              this.listaRutsV.push({rut: `${element?.rutDoc}-${element?.dvDoc}`, nombre: `${element?.razonSocial}`});
            } else {
              this.listaRutsC.push({rut: `${element?.rutDoc}-${element?.dvDoc}`, nombre: `${element?.razonSocial}`});
            }
          }
          tipo === 'COMPRA' ? this.showSpinnerRutC = false : this.showSpinnerRutV = false;
        } else {
          console.warn('Se obtuvo filtro de ruts vacio');
          tipo === 'COMPRA' ? this.showSpinnerRutC = false : this.showSpinnerRutV = false;
        }
      },
      ({ error }) => {
        console.error(error.message || 'Ocurrió un error al obtener el filtro de ruts');
        tipo === 'COMPRA' ? this.showSpinnerRutC = false : this.showSpinnerRutV = false;
    });
  }


  llamadaCalculos(obtenerRuts: boolean = true, validaCantidad: boolean = false, tipo: string = ''): void {
    const apiServices: any = [];

    if(tipo === 'VENTA' || tipo === '') {
      apiServices.push(this.getServiceCalculo('VENTA'));
    }
    if(tipo === 'COMPRA' || tipo === '') {
      apiServices.push(this.getServiceCalculo('COMPRA'));
    }

    if(apiServices.length > 0){
      this.spinner.show();
      forkJoin(apiServices).subscribe(
        resp => {
          if(this.calulosErrores.length > 0) 
          {
            this.alertService.error('Ha ocurrido un error al obtener los calculos de compra y venta');
            this.spinner.hide();
            this.logicaRetorno();
          } else {
            if(tipo === 'VENTA' || tipo === '') {
              this.cantidadRegistrosV = 0;
              this.cantidadRegistrosV = this.respuestaCalculos?.['VENTA']?.datosGenerales?.totalRegistros ? this.respuestaCalculos?.['VENTA']?.datosGenerales?.totalRegistros : 0;
            }
            if(tipo === 'COMPRA' || tipo === '') {
              this.cantidadRegistrosC = 0;
              this.cantidadRegistrosC = this.respuestaCalculos?.['COMPRA']?.datosGenerales?.totalRegistros ? this.respuestaCalculos?.['COMPRA']?.datosGenerales?.totalRegistros : 0;
            }

            if(validaCantidad) {
              if(this.cantidadRegistrosC > 0 || this.cantidadRegistrosV > 0) {
                if(obtenerRuts){
                  if(tipo === 'VENTA' || tipo === '') {
                    this.listaRutsV = [];
                    this.obtenerRuts('VENTA');
                  }
                  if(tipo === 'COMPRA' || tipo === '') {
                    this.listaRutsC = [];
                    this.obtenerRuts('COMPRA');
                  }
                }
                this.obtenerCantidadPaginas(true, tipo);
              } else {
                this.alertService.error('No se encontraron registros para los filtros seleccionados');
                this.spinner.hide();
                this.logicaRetorno();
              }
            } else {
              if(obtenerRuts){
                if(tipo === 'VENTA' || tipo === '') {
                  this.listaRutsV = [];
                  this.obtenerRuts('VENTA');
                }
                if(tipo === 'COMPRA' || tipo === '') {
                  this.listaRutsC = [];
                  this.obtenerRuts('COMPRA');
                }
              }
              this.obtenerCantidadPaginas(true, tipo);
            }
            
          }
        },
        error => {
          this.alertService.error(error.message || 'Ha ocurrido un error al obtener los calculos');
          this.spinner.hide();
          this.logicaRetorno();
        }
      );
    }
  }

  obtenerCantidadPaginas(forzar: boolean = false, tipo: string = ''): void {    
    if(this.cantidadRegistrosV > 0 && (tipo === 'VENTA' || tipo === '')) {
      this.cantidadPaginasV = Math.ceil(this.cantidadRegistrosV / this.currentItemV);
    }
    
    if(this.cantidadRegistrosC > 0 && (tipo === 'COMPRA' || tipo === '')) {
      this.cantidadPaginasC = Math.ceil(this.cantidadRegistrosC / this.currentItemC);
    }

    if((this.cantidadPaginasV <= 0 || this.cantidadRegistrosV <= 0) && (tipo === 'VENTA' || tipo === '')) {
      this.alertService.error('No se han encontrado registros de ventas para los filtros seleccionados');
      this.paginaActualV = [];
      this.paginasActualesV = [];
    }

    if((this.cantidadPaginasC <= 0 || this.cantidadRegistrosC <= 0) && (tipo === 'COMPRA' || tipo === '')) {
      this.alertService.error('No se han encontrado registros de compras para los filtros seleccionados');
      this.paginaActualC = [];
      this.paginasActualesC = [];
    }

    if((this.cantidadPaginasV > 0 && this.cantidadRegistrosV > 0 && (tipo === 'VENTA' || tipo === '')) || (this.cantidadPaginasC > 0 && this.cantidadRegistrosC > 0 && (tipo === 'COMPRA' || tipo === ''))) {
      this.getPage(1, tipo, forzar);
    } else {
      this.spinner.hide();
    }
  }



  cambioPaginacion(tipo: string): void {
    this.obtenerCantidadPaginas(true, tipo);
  }

  getPage(event: number, tipo: string = '', forzar: boolean = false): void { // si no viene el tipo, se asume que son ambos
    if(tipo === 'VENTA') {
      this.paginasActualesErroresV = [];
    } else if(tipo === 'COMPRA') {
      this.paginasActualesErroresC = [];
    } else if(tipo === '') {
      this.paginasActualesErroresV = [];
      this.paginasActualesErroresC = [];
    }
    let paginasConsultarC: number[] = [];
    let paginasConsultarV: number[] = [];
    if((tipo === 'COMPRA' || tipo === '') && this.cantidadRegistrosC > 0) {
      const elemento: any = this.paginasActualesC.find(e => e.pagina == (event));
      if(elemento == undefined || forzar){
        if(forzar){
          this.paginasActualesC = [];
        }

        paginasConsultarC = this.getArrayPaginas(event, 'COMPRA');
      } else {
        this.paginaActualC = this.mapeoTipoDocumento(elemento?.response, 'compra', 'TipoDoc', 'detTipoDocRef') || [undefined];
      }
    }
    if((tipo === 'VENTA' || tipo === '') && this.cantidadRegistrosV > 0) {
      const elemento: any = this.paginasActualesV.find(e => e.pagina == (event));
      if(elemento == undefined || forzar){
        if(forzar){
          this.paginasActualesV = [];
        }

        paginasConsultarV = this.getArrayPaginas(event, 'VENTA');

      } else {
        this.paginaActualV = this.mapeoTipoDocumento(elemento?.response, 'venta', 'TipoDoc', 'TipoDoctoReferencia') || [undefined];
      }
    }

    const paginaActualC: number | undefined = tipo === 'COMPRA' || tipo === '' ? event : undefined;
    const paginaActualV: number | undefined = tipo === 'VENTA' || tipo === '' ? event : undefined;
    this.obtenerPaginas({paginas: paginasConsultarC, paginaActual: paginaActualC}, {paginas: paginasConsultarV, paginaActual: paginaActualV});


    if((tipo === 'VENTA' || tipo === '')){
      this.currentPageV = event;
    }
    if((tipo === 'COMPRA' || tipo === '')){
      this.currentPageC = event;
    }

  }

  // aqui se debe validar si son vacios los array y validar si estan guardados previamente las paginas a consultar
  obtenerPaginas(datosCompras: {paginas: number[], paginaActual: number | undefined}, datosVentas: {paginas: number[], paginaActual: number | undefined}): void {
    const esTipoDocNC: boolean = true;
    const apiServices: any = [];

    // Compras
    if(datosCompras?.paginas && datosCompras?.paginas.length > 0 && datosCompras?.paginaActual) {
      const paginas = datosCompras?.paginas || [];
      const tipo = 'COMPRA';
      if(this.cantidadPaginasC > 0 && paginas.length > 0 && tipo) { // por cada pagina se valida si existe previamente
        paginas.forEach(pagina => { //paginas es la entrada antigua
          if(pagina > 0 && pagina <= this.cantidadPaginasC && !this.paginasActualesC.some(e => e?.pagina == pagina)) { // paginas guardadas
  
            apiServices.push( // si no existe se agrega a la lista de servicios a consultar
              {
                service: this.getServicePagina(pagina, this.currentItemC, tipo, esTipoDocNC),
                pagina: pagina
              }
            );
  
          }
        });
  
        // se quitan elementos que no se desean consultar
        this.paginasActualesC = this.paginasActualesC.filter(item =>
          paginas.includes(item.pagina) && 
          item.pagina > 0 && 
          item.pagina <= this.cantidadPaginasC
        );
  
      }
    }
    // Ventas
    if(datosVentas?.paginas && datosVentas?.paginas.length > 0 && datosVentas?.paginaActual) {
      const paginas = datosVentas?.paginas || [];
      const tipo = 'VENTA';
      if(this.cantidadPaginasV > 0 && paginas.length > 0 && tipo) { // por cada pagina se valida si existe previamente
        paginas.forEach(pagina => { //paginas es la entrada antigua
          if(pagina > 0 && pagina <= this.cantidadPaginasV && !this.paginasActualesV.some(e => e?.pagina == pagina)) { // paginas guardadas
  
            apiServices.push( // si no existe se agrega a la lista de servicios a consultar
              {
                service: this.getServicePagina(pagina, this.currentItemV, tipo, esTipoDocNC),
                pagina: pagina
              }
            );
  
          }
        });
  
        // se quitan elementos que no se desean consultar
        this.paginasActualesV = this.paginasActualesV.filter(item =>
          paginas.includes(item.pagina) && 
          item.pagina > 0 && 
          item.pagina <= this.cantidadPaginasV
        );
  
      }
    }

    if(apiServices && apiServices.length > 0 && (datosCompras?.paginaActual || datosVentas?.paginaActual)){
      this.llamadaObtenerPaginas(apiServices, datosCompras?.paginaActual, datosVentas?.paginaActual); // se debe validar que el valor de la pagina sea valido
    }
  }

  getServicePagina(pagina: number, cantidadElementos: number, tipo: string, esTipoDocNC: boolean | null): any {
    const meses: string = tipo === 'COMPRA' ? this.filtroFormC.get('selectPeriodosC')?.value || '36' : this.filtroFormV.get('selectPeriodosV')?.value || '36';
    const rutFiltro = tipo === 'COMPRA' ? this.filtroFormC.get('entidadRutC')?.value : this.filtroFormV.get('entidadRutV')?.value;

    return this.compraVentaService.obtenerPagina(this.rut, pagina, cantidadElementos, tipo, meses, rutFiltro, this.periodoInicial, esTipoDocNC)
      .pipe(
        map(resp => {
          this.setResponse(resp, pagina, tipo); // se debe enviar el tipo para identificar si es compra o venta
        })
      )
      .pipe(
        catchError((error) => (this.setError(pagina, tipo, error?.error?.message || 'Error Inesperado en servicio'), of(null)))); // se debe enviar el tipo para identificar si es compra o venta
  }

  setError(pagina: number, tipo: string, error: string): void {
    const obj: any = {
      pagina: pagina,
      error: error
    };
    if(tipo === 'VENTA'){
      this.paginasActualesErroresV.push(obj)
    } else {
      this.paginasActualesErroresC.push(obj)
    }
  }

  setResponse(response: any[], pagina: number, tipo: string): void {
    const obj: any = {
      pagina: pagina,
      response: response || null
    };
    if(tipo === 'VENTA'){
      this.paginasActualesV.push(obj)
    } else {
      this.paginasActualesC.push(obj)
    }
  }

  // se debe validar que el valor de la pagina sea valido
  llamadaObtenerPaginas(apiServices: any[], paginaActualC: number | undefined, paginaActualV: number | undefined): void { //apiServices, datosCompras?.paginaActual, datosVentas?.paginaActual
    if(apiServices.length == 0) {
      this.alertService.error('No se han encontrado servicios para consultar');
      this.spinner.hide();
      return;
    }
    const services = apiServices.map(e => e?.service);

    if(services.length == 0) {
      this.alertService.error('No se han encontrado servicios para consultar');
      this.spinner.hide();
      return;
    }

    this.spinner.show();
    forkJoin(services).subscribe(
      resp => {
        if(this.paginasActualesErroresC.length > 0) { // valida si hay errores en compras y si estan repetidos para mostrarlos
          const mensajesMostrados: Set<string> = new Set();
      
          this.paginasActualesErroresC.forEach(e => {
            const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas consultadas';
            if (!mensajesMostrados.has(mensaje)) {
              this.alertService.error(mensaje);
              mensajesMostrados.add(mensaje);
            }
          });
        }

        if(this.paginasActualesErroresV.length > 0) { // valida si hay errores en ventas y si estan repetidos para mostrarlos
          const mensajesMostrados: Set<string> = new Set();
      
          this.paginasActualesErroresV.forEach(e => {
            const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas consultadas';
            if (!mensajesMostrados.has(mensaje)) {
              this.alertService.error(mensaje);
              mensajesMostrados.add(mensaje);
            }
          });
        }

        if(paginaActualC) {
          const elemento: any = this.paginasActualesC.find(e => e.pagina == (paginaActualC))
          this.paginaActualC = this.mapeoTipoDocumento(elemento?.response, 'compra', 'TipoDoc', 'detTipoDocRef') || [undefined];
        }

        if(paginaActualV) {
          const elemento: any = this.paginasActualesV.find(e => e.pagina == (paginaActualV))
          this.paginaActualV = this.mapeoTipoDocumento(elemento?.response, 'venta', 'TipoDoc', 'TipoDoctoReferencia') || [undefined];
        }

        this.spinner.hide();
      },
      error => {
        this.alertService.error(error.message || 'Ocurrió un error al consultar por las paginas');
        this.spinner.hide();
      }
    );
  }

  mapeoTipoDocumento(paginaActual: any[] | any, tipo: string, keyTipo: string, keyTipoRef: string): any {
    if(paginaActual && paginaActual.length > 0) {
      for(const e of paginaActual) {
        e.tipoDocumentoMap = tipo === 'venta' ? this.compraVentaService.getTipoDocumento(e?.[keyTipo]) : 
        (tipo === 'compra' ? this.compraVentaService.getTipoDocumento(e?.[keyTipo]) : 'Sin Información');
        e.tipoDocumentoRefMap = tipo === 'venta' ? this.compraVentaService.getTipoDocumento(e?.[keyTipoRef]) : 
        (tipo === 'compra' ? this.compraVentaService.getTipoDocumento(e?.[keyTipoRef]) : 'Sin Información');
      }
    }
    return paginaActual;
  }

  getArrayPaginas(event: number, tipo: string): number[] {
    let response: any[] = [];
    const cantidadPaginas: number = tipo === 'VENTA' ? this.cantidadPaginasV : this.cantidadPaginasC;
    if(event == 1){
      for(let i = 0; i < 3; i++){
        const pagina = event + i;
        if(pagina > 0 && pagina <= cantidadPaginas){
          response.push(pagina);
        }
      }
    } else if(event > 1 && event <= cantidadPaginas) {
      const pagina: number = event;
      response = [pagina - 1, pagina];
      if(event + 1 <= cantidadPaginas)
        response.push(pagina + 1);
    }
    return response;
  }

  esFormatoFecha(cadena: string): boolean {
    if (typeof cadena !== 'string' || !cadena) {
      return false;
    }

    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    return regex.test(cadena);
  }

  cambiarFormatoFecha(cadena: string): any {
    if (!this.esFormatoFecha(cadena)) {
      return cadena;
    }
    
    return cadena.replace(/\//g, "-");
  }

  esFormatoFechaHora(cadena: string): boolean {
    if (typeof cadena !== 'string' || !cadena) {
      return false;
    }

    const regex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/;
    return regex.test(cadena);
  }

  cambiarFormatoFechaHora(cadena: string): any {
    if (!this.esFormatoFechaHora(cadena)) {
      return cadena;
    }
    
    return cadena.replace(/\//g, "-");
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    
  }

}
