import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { CognitoService } from '../shared/services/cognito.service';
import { environment } from '../../environments/environment';
import { BackendAuthService } from '../shared/services/backend-auth.service';
import { SessionService } from '../shared/services/session.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html'
})
export class CallbackComponent implements OnInit {

  private homeURI: string = environment.cognitoConfig.baseRedirectURI + "/";
  private organizationURI: string = environment.cognitoConfig.baseRedirectURI + "/organizacion";

  constructor(
    private route: ActivatedRoute,
    private _spinner: NgxSpinnerService,
    private _cognitoService: CognitoService,
    private _sessionService: SessionService,
    private _backendAuthService: BackendAuthService) { }

  ngOnInit(): void {

    //Relaod on new version
    // if(localStorage.getItem('shouldLogout')) {
    //   localStorage.removeItem('shouldLogout');
    //   this._cognitoService.logout();
    // }

    this._spinner.show();
    const code = this.route.snapshot.queryParamMap.get('code') || '';
    const state = this.route.snapshot.queryParamMap.get('state') || '';
    const codeVerifier = this.route.snapshot.queryParamMap.get('code_verifier') || '';

    this._cognitoService.register(code, state, codeVerifier)
      .then(async (isValid) => {
        // Si no fue valida la autenticacion, borrar datos locales y devolver al home
        if (!isValid) {
          this._sessionService.clearSession();
          window.location.href = this.homeURI;
        }

        this._spinner.hide();
        if (this._sessionService.hasManyOrganizations()) {
          window.location.href = this.organizationURI;
        } else {
          // If all correct about the data access to platform and token for request to backend, redirect to homeURI
          try {
            const userInfo = this._sessionService.getUserInfo();
            this._sessionService.selectAnOrganization(userInfo.organizations[0])
          } catch (e) {
            this._sessionService.clearSession();
            window.location.href = this.homeURI;
          }
          window.location.href = this.homeURI;
        }
      })
      .catch((err) => {
        console.error(err);
        this._spinner.hide();
        this._sessionService.clearSession();
        window.location.href = this.homeURI;
      });
  }

}
