<!-- Article Header -->
<!-- For an image header add the class 'content-header-media' and an image as in the following example -->
<div class="content-header content-header-media">
    <div class="header-section">
        <h1>SOLICITE ACCESO<br><small>www.gestionatupyme.cl</small></h1>
    </div>
</div>
<!-- Article Content -->
<div class="row">
    <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
        <!-- Article Block -->
        <div class="block block-alt-noborder">
            <!-- Article Content -->
            <article>
                <h3 class="sub-header text-center"><strong>SOLICITE ACCESO</strong></h3>
                <p>Ud no cuenta con los accesos necesarios , pongase en contacto con el administrador para solicitar acceso</p>
                <blockquote>
                    <p>
                        contacto@gestionatupyme.cl
                    </p>
                    <footer></footer>
                </blockquote>
            </article>
            <!-- END Article Content -->
        </div>
        <!-- END Article Block -->
    </div>
</div>
<!-- END Article Content -->