import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
//import { Ng9RutModule } from 'ng9-rut';
//import { SimpleModalModule } from 'ngx-simple-modal';
import { MatDialogModule } from "@angular/material/dialog";
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { NgxMasonryModule } from 'ngx-masonry';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CallbackComponent } from './callback/callback.component';
import { LogoutComponent } from './logout/logout.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { RequestAccessViewComponent } from './components/request-access-view/request-access-view.component';
import { ComingSoonViewComponent } from './components/coming-soon-view/coming-soon-view.component';
import { BoletinConcursalConsultarComponent } from './pages/boletin-concursal/boletin-consursal-consultar/boletin-consursal-consultar.component';
import { SolicitudesConsultarComponent } from './pages/solicitudes/solicitudes-consultar/solicitudes-consultar.component';
import { CrearUsuarioComponent } from './pages/usuarios/crear-usuario/crear-usuario.component';
import { ListarUsuarioComponent } from './pages/usuarios/listar-usuario/listar-usuario.component';
import { EditarUsuarioComponent } from './pages/usuarios/editar-usuario/editar-usuario.component';
import { EmpresaEnUnDiaConsultarComponent } from './pages/empresa-en-un-dia/empresa-en-un-dia-consultar/empresa-en-un-dia-consultar.component';
import { ReporteJudicialConsultarComponent } from './pages/reporte-judicial/reporte-judicial-consultar/reporte-judicial-consultar.component';
import { ReporteBasicoComponent } from './pages/reporte/reporte-basico/reporte-basico.component';
import { ReporteBuscarComponent } from './pages/reporte/reporte-buscar/reporte-buscar.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AccessDialogComponent } from './components/access-dialog/access-dialog.component';
import { AlertModule } from './components/_alert';
import { ReporteViewerComponent } from './components/reporte/reporte-viewer/reporte-viewer.component';
import { OrganizationComponent } from './pages/organization/organization.component';
import { EmpresaEnUnDiaBuscarComponent } from './pages/empresa-en-un-dia/empresa-en-un-dia-buscar/empresa-en-un-dia-buscar.component';
import { AnotacionesViewerComponent } from './components/empresa-en-un-dia/anotaciones-viewer/anotaciones-viewer.component';
import { VigenciaViewerComponent } from './components/empresa-en-un-dia/vigencia-viewer/vigencia-viewer.component';
import { EstatutosViewerComponent } from './components/empresa-en-un-dia/estatutos-viewer/estatutos-viewer.component';
import { BoletinConcursalBuscarComponent } from './pages/boletin-concursal/boletin-concursal-buscar/boletin-concursal-buscar.component';
import { BoletinConcursalReportViewerComponent } from './components/boletin-concursal/boletin-concursal-report-viewer/boletin-concursal-report-viewer.component';
import { UserIdleModule } from 'angular-user-idle';
import { environment as env } from '../environments/environment';
import { AuthInterceptorService } from './shared/services/interceptor';
import { UserActiveDirective } from './shared/directives/user-active.directive';
import { LinearGaugeColorLevelComponent } from './components/linear-gauge-color-level/linear-gauge-color-level.component';
import { PoderJudicialReportViewerComponent } from './components/poder-judicial/poder-judicial-report-viewer/poder-judicial-report-viewer.component';
import { ReporteJudicialBuscarComponent } from './pages/reporte-judicial/reporte-judicial-buscar/reporte-judicial-buscar.component';
import { PaginatorByItems } from './components/paginator-by-items/paginator-by-items.component';
import { OrderByPipe } from './shared/pipes/orderBy.pipe';
import { RutFormatPipe } from './shared/pipes/rutFormat.pipe';
import { PhoneDirective } from './shared/directives/phone.directive';
import { RutDirective } from './shared/directives/RutDirective';
import { RutValueAccessor } from './shared/directives/rut-value-accessor';
import { SolicitudCreditoConsultarWorkflowComponent } from './pages/solicitud-credito-workflow/solicitud-credito-consultar-workflow/solicitud-credito-consultar-workflow.component';
import { SolicitudCreditoViewerWorkflowComponent } from './components/solicitud-credito-workflow/solicitud-credito-viewer-workflow/solicitud-credito-viewer-workflow.component';
import { CustomBarsComponent } from './components/charts/custom-bars/custom-bars.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge'
import * as HighchartsMore from 'highcharts/highcharts-more.src';
import { DynamicColunmTableComponent } from './components/tables/dynamic-colunm-table/dynamic-colunm-table.component'
import { SumTdPipe } from './shared/pipes/sumTd.pipe';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { LineBarsComponent } from './components/charts/line-bars/line-bars.component';
import { ConsumoServicioWorkflowComponent } from './components/solicitud-credito-workflow/consumo-servicio-workflow/consumo-servicio-workflow.component';
import { ConsumoServiciosDerivadosWorkflowComponent } from './components/solicitud-credito-workflow/consumo-servicios-derivados-workflow/consumo-servicios-derivados-workflow.component';
import { ErrorComponent } from './components/error/error.component';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { CamposManualesWorkflowComponent } from './components/solicitud-credito-workflow/campos-manuales-workflow/campos-manuales-workflow.component';
import { DeudaSbifLeasingConsultarComponent } from './pages/deuda-sbif-leasing/deuda-sbif-leasing-consultar/deuda-sbif-leasing-consultar.component';
import { DeudaSbifLeasingBuscarComponent } from './pages/deuda-sbif-leasing/deuda-sbif-leasing-buscar/deuda-sbif-leasing-buscar.component';
import { DeudaLeasingSbifViewerComponent } from './components/deuda-leasing-sbif-view/deuda-leasing-sbif-viewer.component';
import { ReporteFullConsultarComponent } from './pages/reporte-full/reporte-full-consultar/reporte-full-consultar.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ReporteFullViewerComponent } from './components/reporte-full-view/reporte-full-viewer.component';
import { ReporteFullBuscarComponent } from './pages/reporte-full/reporte-full-buscar/reporte-full-buscar.component';
import { ListErrorsDialogComponent } from './components/list-errors-dialog/list-errors-dialog.component';
import { NgxPrintModule } from 'ngx-print';
import { AfterPrintDirective } from './shared/directives/after-print.directive';
import { VehiculosConsultarComponent } from './pages/vehiculos/vehiculos-consultar/vehiculos-consultar.component';
import { VehiculosBuscarComponent } from './pages/vehiculos/vehiculos-buscar/vehiculos-buscar.component';
import { VehiculosViewerComponent } from './components/vehiculos/vehiculos-viewer/vehiculos-viewer.component';
import { BienesRaicesConsultarComponent } from './pages/bienes-raices/bienes-raices-consultar/bienes-raices-consultar.component';
import { BienesRaicesViewerComponent } from './components/bienes-raices/bienes-raices-viewer/bienes-raices-viewer.component';
import { BienesRaicesBuscarComponent } from './pages/bienes-raices/bienes-raices-buscar/bienes-raices-buscar.component';
import { ListaOrganizacionesComponent } from './pages/organization/listar-organizaciones/listar-organizaciones.component';
import { EditarOrganizacionComponent } from './pages/organization/editar-organizacion/editar-organizacion.component';
import { CrearOrganizacionComponent } from './pages/organization/crear-organizacion/crear-organizacion.component';
import { OnlyTextDirective } from './shared/directives/only-text.directive';
import { ReporteConsolidadoComponent } from './pages/reporte-consolidado/reporte-consolidado.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ReporteConsolidadoViewComponent } from './components/reporte-consolidado-view/reporte-consolidado-view.component';
import { FormularioF29BuscarComponent } from './pages/formulario-f29/formulario-f29-buscar/formulario-f29-buscar.component';
import { FormularioF29ConsultarComponent } from './pages/formulario-f29/formulario-f29-consultar/formulario-f29-consultar.component';
import { InformeF29ViewComponent } from './components/informe-f29-view/informe-f29-view.component';
import { FormularioF22BuscarComponent } from './pages/formulario-f22/formulario-f22-buscar/formulario-f22-buscar.component';
import { FormularioF22ConsultarComponent } from './pages/formulario-f22/formulario-f22-consultar/formulario-f22-consultar.component';
import { InformeF22ViewComponent } from './components/informe-f22-view/informe-f22-view.component';
import { AntecedentesConsolidadoComponent } from './components/reporte-consolidado-view/antecedentes-consolidado/antecedentes-consolidado.component';
import { ReporteFinancieroComponent } from './pages/reporte-financiero/reporte-financiero.component';
import { ReporteRentaConsultarComponent } from './pages/reporte-renta/reporte-renta-consultar/reporte-renta-consultar.component';
import { ReporteRentaBuscarComponent } from './pages/reporte-renta/reporte-renta-buscar/reporte-renta-buscar.component';
import { ReporteRentaViewComponent } from './components/reporte-renta-view/reporte-renta-view.component';
import { ReporteFinancieroConsolidadoComponent } from './pages/reporte-financiero-consolidado/reporte-financiero-consolidado.component';
import { ReporteFinancieroConsolidadoViewComponent } from './components/reporte-financiero-consolidado-view/reporte-financiero-consolidado-view.component';
import { ModalConsultarCarpetaTributariaComponent } from './components/modal-consultar-carpeta-tributaria/modal-consultar-carpeta-tributaria.component';
import { CompraVentaBuscarComponent } from './pages/compra-venta/compra-venta-buscar/compra-venta-buscar.component';
import { CompraVentaConsultarComponent } from './pages/compra-venta/compra-venta-consultar/compra-venta-consultar.component';
import { ReporteCompraVentaViewComponent } from './components/reporte-compra-venta-view/reporte-compra-venta-view.component';

import { ModalEntidadesWorkflowComponent } from './components/solicitud-credito-workflow/modal-entidades-workflow/modal-entidades-workflow.component';
import { BalanceAutomaticoCapitalExpressWorkflowComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-capital-nc/balance-automatico/balance-automatico.component';
import { ListErrorsDialogWorkflowComponent } from './components/list-errors-dialog-workflow/list-errors-dialog-workflow.component';
// Solicitud de credito antigua
import { SolicitudCreditoConsultarComponent } from './pages/solicitud-credito/solicitud-credito-consultar/solicitud-credito-consultar.component';
import { SolicitudCreditoViewerComponent } from './components/solicitud-credito/solicitud-credito-viewer/solicitud-credito-viewer.component';
import { EstadosFinancierosComponent } from './components/solicitud-credito/estados-financieros/estados-financieros.component';
import { FichaAnalisisComponent } from './components/solicitud-credito/ficha-analisis/ficha-analisis.component';
import { MallaSocietariaComponent } from './components/solicitud-credito/malla-societaria/malla-societaria.component';
import { AnexoComponent } from './components/solicitud-credito/anexo/anexo.component';
import { InformacionClienteComponent } from './components/solicitud-credito/informacion-cliente/informacion-cliente.component';
import { CondicionesVentasComponent } from './components/solicitud-credito/condiciones-ventas/condiciones-ventas.component';
import { InformeCteComponent } from './components/solicitud-credito/informe-cte/informe-cte.component';
import { GenerarReporteComponent } from './components/solicitud-credito/generar-reporte/generar-reporte.component';
import { ResumenSolicitudCreditoComponent } from './components/solicitud-credito/resumen-solicitud-credito/resumen-solicitud-credito.component';
import { LineaCreditoComponent } from './components/solicitud-credito/linea-credito/linea-credito.component';
import { InformeLeasingSbifComponent } from './components/solicitud-credito/informe-leasing-sbif/informe-leasing-sbif.component';
import { ModalRutComponent } from './components/solicitud-credito/resumen-solicitud-credito/modal-rut/modal-rut.component';
import { UploadInformeComponent } from './components/solicitud-credito/upload-informe/upload-informe.component';
import { InformeEmpresaComponent } from './components/solicitud-credito/informe-empresa/informe-empresa.component';
import { ResumenCondicionesVentaComponent } from './components/solicitud-credito/resumen-condiciones-venta/resumen-condiciones-venta.component';
import { ModalConsolidadoWorkflowComponent } from './components/solicitud-credito-workflow/modal-consolidado-workflow/modal-consolidado-workflow.component';
import { AprobacionWorkflowComponent } from './components/solicitud-credito-workflow/aprobacion-workflow/aprobacion-workflow.component';
import { PlantillaDemoComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-demo/plantilla-demo.component';
import { BalanceAutomaticoDemoComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-demo/balance-automatico/balance-automatico.component';
import { PlantillaCesceComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-cesce/plantilla-cesce.component';
import { PlantillaCapitalNcComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-capital-nc/plantilla-capital-nc.component';
import { AntConsolidadoComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-cesce/ant-consolidado/ant-consolidado.component';
import { ReporteMallaSocietariaComponent } from './components/solicitud-credito/reporte-malla-societaria/reporte-malla-societaria.component';
import { CredencialesOrganizacionComponent } from './pages/credenciales/credenciales-organizacion/credenciales-organizacion.component';
import { CredencialesUsuarioComponent } from './pages/credenciales/credenciales-usuario/credenciales-usuario.component';
import { ModalCredencialesComponent } from './components/solicitud-credito-workflow/modal-credenciales/modal-credenciales.component';
import { CargaMasivaCredComponent } from './components/carga-masiva-cred/carga-masiva-cred.component';
import { CamposPersonalizadosComponent } from './pages/campos-personalizados/campos-personalizados.component';
import { ModalChangePassComponent } from './components/modal-change-pass/modal-change-pass.component';
import { ReporteTesoreriaBuscarComponent } from './pages/reporte-tesoreria/reporte-tesoreria-buscar/reporte-tesoreria-buscar.component';
import { ReporteTesoreriaConsultarComponent } from './pages/reporte-tesoreria/reporte-tesoreria-consultar/reporte-tesoreria-consultar.component';
import { ReporteTesoreriaViewComponent } from './components/reporte-tesoreria-view/reporte-tesoreria-view.component';
import { AsincronoService } from './shared/services/asincrono.service';
import { ReporteMallaSocietariaConsultarComponent } from './pages/reporte-malla-societaria/reporte-malla-societaria-consultar/reporte-malla-societaria-consultar.component';

//import { OrgchartModule } from '@dabeng/ng-orgchart';
import { ListDatesComponent } from './components/list-dates/list-dates.component';
import { PlantillaInterfactorComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-interfactor/plantilla-interfactor.component';
import { ModuloArchivosComponent } from './components/solicitud-credito-workflow/modulo-archivos/modulo-archivos.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { ReporteMallaSocietariaViewComponent } from './components/reporte-malla-societaria-view/reporte-malla-societaria-view.component';
import { ReporteMallaSocietariaBuscarComponent } from './pages/reporte-malla-societaria/reporte-malla-societaria-buscar/reporte-malla-societaria-buscar.component';
import { ModalPdfViewerComponent } from './components/modal-pdf-viewer/modal-pdf-viewer.component';
import { SafePipe } from './shared/pipes/safe.pipe';
import { PlantillaSodimacComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-sodimac/plantilla-sodimac.component';
import { PlantillaPanelRiesgoComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-panel-riesgo/plantilla-panel-riesgo.component';
import { PlantillaIngeComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-inge/plantilla-inge.component';
import { PlantillaCesceOldComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-cesce-old/plantilla-cesce-old.component';
import { IngresoBalanceManualComponent } from './pages/ingreso-balance-manual/ingreso-balance-manual.component';
import { ReporteBalanceManualViewComponent } from './components/reporte-balance-manual-view/reporte-balance-manual-view.component';
import { F22F29InformativoViewComponent } from './components/f22-f29-informativo-view/f22-f29-informativo-view.component';
import { ReportePanelRiesgoComponent } from './pages/reporte-panel-riesgo/reporte-panel-riesgo.component';
import { BalanceManualInputViewComponent } from './components/balance-manual-input-view/balance-manual-input-view.component';
import { ListErrorsSimpleDialogComponent } from './components/list-errors-simple-dialog/list-errors-simple-dialog.component';
import { PrintNestedTableComponent } from './components/reporte-malla-societaria-view/print-nested-table/print-nested-table.component';
import { ReporteLicitacionFechaComponent } from './pages/reporte-licitacion/reporte-licitacion-fecha/reporte-licitacion-fecha.component';
import { ReporteLicitacionViewComponent } from './components/reporte-licitacion-view/reporte-licitacion-view.component';
import { ListadoSolicitudesComponent } from './pages/sinc-credenciales/listado-solicitudes/listado-solicitudes.component';
import { PlantillaPuntualCaratulaComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-puntual-caratula/plantilla-puntual-caratula.component';
import { PlantillaPerfilCaratulaComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-perfil-caratula/plantilla-perfil-caratula.component';
import { GruposComponent } from './pages/usuarios/grupos/grupos.component';
import { GruposAsignacionPageComponent } from './components/grupos-asignacion-page/grupos-asignacion-page.component';
// import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { ChangeKeyupDirective } from './shared/directives/change-keyup.directive';
import { ModalBalanceManualLogComponent } from './components/modal-balance-manual-log/modal-balance-manual-log.component';
import { FichaComercialComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-flujo-comercial/plantilla-flujo-comercial.component';
import { PanelRiesgoCredytComponent } from './components/solicitud-credito-workflow/fichas-clientes/panel-riesgo-credyt/panel-riesgo-credyt.component';
import { CobranzaOnlineListadoComponent } from './pages/cobranza-online/cobranza-online-listado/cobranza-online-listado.component';
import { CobranzaOnlinePublicarComponent } from './pages/cobranza-online/cobranza-online-publicar/cobranza-online-publicar.component';
import { CobranzaOnlineOnboardingComponent } from './pages/cobranza-online/cobranza-online-onboarding/cobranza-online-onboarding.component';
import { NcSectionComponent } from './components/reporte-compra-venta-view/nc-section/nc-section.component';
import { PlantillaCapexModelo1 } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-capex-modelo1/plantilla-capex-modelo1.component';
import { PlantillaPuenteFinancieroCtComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-puente-financiero-ct/plantilla-puente-financiero-ct.component';
import { DetalleReporteModalComponent } from './components/solicitud-credito-workflow/fichas-clientes/plantilla-puente-financiero-ct/detalle-reporte-modal/detalle-reporte-modal.component';
registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CallbackComponent,
    LogoutComponent,
    SideMenuComponent,
    RequestAccessViewComponent,
    ComingSoonViewComponent,
    BoletinConcursalConsultarComponent,
    SolicitudesConsultarComponent,
    CrearUsuarioComponent,
    ListarUsuarioComponent,
    EditarUsuarioComponent,
    EmpresaEnUnDiaConsultarComponent,
    ReporteJudicialConsultarComponent,
    ReporteBasicoComponent,
    ReporteBuscarComponent,
    ConfirmDialogComponent,
    AccessDialogComponent,
    ListErrorsDialogComponent,
    ReporteViewerComponent,
    OrganizationComponent,
    EmpresaEnUnDiaBuscarComponent,
    AnotacionesViewerComponent,
    VigenciaViewerComponent,
    EstatutosViewerComponent,
    BoletinConcursalBuscarComponent,
    BoletinConcursalReportViewerComponent,
    UserActiveDirective,
    LinearGaugeColorLevelComponent,
    PoderJudicialReportViewerComponent,
    ReporteJudicialBuscarComponent,
    PaginatorByItems,
    OrderByPipe,
    RutFormatPipe,
    SumTdPipe,
    PhoneDirective,
    AfterPrintDirective,
    OnlyTextDirective,
    SolicitudCreditoConsultarWorkflowComponent,
    SolicitudCreditoViewerWorkflowComponent,
    CustomBarsComponent,
    PieChartComponent,
    DynamicColunmTableComponent,
    LineBarsComponent,
    ConsumoServicioWorkflowComponent,
    ConsumoServiciosDerivadosWorkflowComponent,
    ErrorComponent,
    CamposManualesWorkflowComponent,
    DeudaSbifLeasingConsultarComponent,
    DeudaSbifLeasingBuscarComponent,
    DeudaLeasingSbifViewerComponent,
    ReporteFullConsultarComponent,
    ReporteFullViewerComponent,
    ReporteFullBuscarComponent,
    VehiculosConsultarComponent,
    VehiculosBuscarComponent,
    VehiculosViewerComponent,
    BienesRaicesConsultarComponent,
    BienesRaicesViewerComponent,
    BienesRaicesBuscarComponent,
    ListaOrganizacionesComponent,
    EditarOrganizacionComponent,
    CrearOrganizacionComponent,
    ReporteConsolidadoComponent,
    ReporteConsolidadoViewComponent,
    FormularioF29BuscarComponent,
    FormularioF29ConsultarComponent,
    InformeF29ViewComponent,
    FormularioF22BuscarComponent,
    FormularioF22ConsultarComponent,
    InformeF22ViewComponent,
    AntecedentesConsolidadoComponent,
    ReporteFinancieroComponent,
    ReporteRentaConsultarComponent,
    ReporteRentaBuscarComponent,
    ReporteRentaViewComponent,
    ReporteFinancieroConsolidadoComponent,
    ReporteFinancieroConsolidadoViewComponent,
    ModalConsultarCarpetaTributariaComponent,
    CompraVentaBuscarComponent,
    CompraVentaConsultarComponent,
    ReporteCompraVentaViewComponent,
    ModalConsultarCarpetaTributariaComponent,
    ModalEntidadesWorkflowComponent,
    BalanceAutomaticoCapitalExpressWorkflowComponent,
    ListErrorsDialogWorkflowComponent,
    SolicitudCreditoConsultarComponent,
    SolicitudCreditoViewerComponent,
    EstadosFinancierosComponent,
    FichaAnalisisComponent,
    MallaSocietariaComponent,
    AnexoComponent,
    InformacionClienteComponent,
    CondicionesVentasComponent,
    InformeCteComponent,
    GenerarReporteComponent,
    ResumenSolicitudCreditoComponent,
    LineaCreditoComponent,
    InformeLeasingSbifComponent,
    ModalRutComponent,
    UploadInformeComponent,
    InformeEmpresaComponent,
    ResumenCondicionesVentaComponent,
    ModalConsolidadoWorkflowComponent,
    AprobacionWorkflowComponent,
    PlantillaDemoComponent,
    BalanceAutomaticoDemoComponent,
    PlantillaCesceComponent,
    PlantillaCapitalNcComponent,
    AntConsolidadoComponent,
    ReporteMallaSocietariaComponent,
    CredencialesOrganizacionComponent,
    CredencialesUsuarioComponent,
    ModalCredencialesComponent,
    CargaMasivaCredComponent,
    CamposPersonalizadosComponent,
    ModalChangePassComponent,
    ReporteTesoreriaBuscarComponent,
    ReporteTesoreriaConsultarComponent,
    ReporteTesoreriaViewComponent,
    ReporteMallaSocietariaConsultarComponent,
    ListDatesComponent,
    PlantillaInterfactorComponent,
    ModuloArchivosComponent,
    AlertMessageComponent,
    ReporteMallaSocietariaViewComponent,
    ReporteMallaSocietariaBuscarComponent,
    ModalPdfViewerComponent,
    SafePipe,
    PlantillaSodimacComponent,
    PlantillaPanelRiesgoComponent,
    PlantillaIngeComponent,
    PlantillaCesceOldComponent,
    IngresoBalanceManualComponent,
    ReporteBalanceManualViewComponent,
    F22F29InformativoViewComponent,
    ReportePanelRiesgoComponent,
    BalanceManualInputViewComponent,
    ListErrorsSimpleDialogComponent,
    PrintNestedTableComponent,
    ReporteLicitacionFechaComponent,
    ReporteLicitacionViewComponent,
    ListadoSolicitudesComponent,
    PlantillaPuntualCaratulaComponent,
    PlantillaPerfilCaratulaComponent,
    GruposComponent,
    GruposAsignacionPageComponent,
    RutDirective,
    RutValueAccessor,
    ChangeKeyupDirective,
    ModalBalanceManualLogComponent,
    FichaComercialComponent,
    PanelRiesgoCredytComponent,
    CobranzaOnlineListadoComponent,
    CobranzaOnlinePublicarComponent,
    CobranzaOnlineOnboardingComponent,
    NcSectionComponent,
    PlantillaCapexModelo1,
    PlantillaPuenteFinancieroCtComponent,
    DetalleReporteModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    //Ng9RutModule,
    //SimpleModalModule,
    MatDialogModule,
    AlertModule,
    GoogleChartsModule,
    NgxPaginationModule,
    ChartModule,
    NgxPrintModule,
    //OrgchartModule,
    TabsModule.forRoot(),
    //NgxMaskModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    UserIdleModule.forRoot({ idle: env.inactivity.initInactivity,
      timeout: env.inactivity.timeoutInactivity,
      ping: env.inactivity.respawnInactivity }),
    NgxMasonryModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CL' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [HighchartsMore, HighchartsSolidGauge]
    },
    {
      provide: AsincronoService
    },
    provideEnvironmentNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
