<div class="break-box">
<div class="break-box">
<div class="card">
  <div class="card-body">

    <div class="row">
      <div class="col-sm-6">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr *ngIf="objReporteBasico">
                <th colspan="1" width="40%">Razón Social:</th>
                <td colspan="4" width="60%">
                  <div class="">
                    {{objReporteBasico?.InformacionBasica?.NombreORazonSocial || 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr>
                <th colspan="1">
                  <div>
                    Rut:
                  </div>
                </th>
                <td colspan="4">
                  <div class="">
                    {{rut | rutFormat}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="objReporteBasico && ((isPersonaNatural && reporteConInformeComercial === true) || !isPersonaNatural)">
                <th colspan="1">Dirección:</th>
                <td colspan="4">
                  <div class="">
                    {{direccion || 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="objReporteBasico">
                <th colspan="1">Actividad Economica:</th>
                <td colspan="4">
                  <div class="">
                    {{objReporteBasico?.Riesgo?.ActividadEconomica || 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="objReporteBasico">
                <th colspan="1">Sector Industrial:</th>
                <td colspan="4">
                  <div class="">
                    {{objReporteBasico?.Riesgo?.Industria || 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="objReporteBasico">
                <th colspan="1">Subsector Industrial:</th>
                <td colspan="4">
                  <div class="">
                    {{objReporteBasico?.Riesgo?.Subsector || 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="objReporteBasico">
                <th colspan="1">Iniciación de Actividades:</th>
                <td colspan="4">
                  <div class="">
                    {{objReporteBasico?.InformacionBasica?.AnoInicioActividades !== 'Sin Inicio de Actividades'?
                    (objReporteBasico?.InformacionBasica?.AnoInicioActividades | date:'dd-MM-yyyy'): objReporteBasico?.InformacionBasica?.AnoInicioActividades || 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="objReporteBasico && objReporteBasico?.InformacionBasica?.TerminoGiro === true">
                <th colspan="1">Fecha Termino de Giro:</th>
                <td colspan="4">
                  <div class="">
                    {{ objReporteBasico?.InformacionBasica?.FechaTerminoGiro | date:'dd-MM-yyyy' }}
                  </div>
                </td>
              </tr>

              <ng-container *ngIf="objReporteBasico">
                <ng-container *ngIf="isPersonaNatural; else isNotPersonaNatural">
                  <ng-container *ngIf="reporteConInformeComercial === true">
                    <tr>
                      <th colspan="1">Edad:</th>
                      <td colspan="4">{{objReporteBasico?.InformacionPersonal?.Edad || 'Sin información'}}</td>
                    </tr>
                    <tr>
                      <th colspan="1">Fecha Nacimiento:</th>
                      <td colspan="4">{{objReporteBasico?.InformacionPersonal?.FechaNacimiento ? (objReporteBasico?.InformacionPersonal?.FechaNacimiento | date: 'dd-MM-yyyy') : 'Sin información'}}</td>
                    </tr>
                    <tr>
                      <th colspan="1">Nacionalidad:</th>
                      <td colspan="4">{{objReporteBasico?.InformacionPersonal?.Nacionalidad || 'Sin información'}}</td>
                    </tr>
                    <ng-container *ngIf="telefonos && telefonos.length > 0">
                      <ng-container *ngIf="telefonos.length > 1; else elseTelefonos">
                        <tr>
                          <th colspan="1">Teléfonos:</th>
                          <td colspan="4"></td>
                        </tr>
                        <ng-container *ngFor="let item of telefonos; let i = index;">
                          <tr>
                            <th colspan="1"></th>
                            <td colspan="4"><span class="font-weight-bold">{{ i + 1 }}:</span> {{item?.Telefono || 'Sin información'}}</td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <ng-template #elseTelefonos>
                        <tr>
                          <th colspan="1">Teléfono:</th>
                          <td colspan="4">{{telefonos[0]?.Telefono || 'Sin información'}}</td>
                        </tr>
                      </ng-template>
                    </ng-container>                
                    <tr>
                      <th colspan="1">Email:</th>
                      <td class="text-break">{{objReporteBasico?.InformacionPersonal?.Contactabilidad?.Email?.Email || 'Sin información'}}</td>
                    </tr>
                    <tr>
                      <th colspan="1">Fecha Email:</th>
                      <td colspan="4">{{formatFechaEmailPN(objReporteBasico?.InformacionPersonal?.Contactabilidad?.Email?.FechaEmail) || 'Sin información'}}</td>
                    </tr>
                    <tr>
                      <th colspan="1">Descripcion Estado Marital:</th>
                      <td colspan="4">{{objReporteBasico?.InformacionPersonal?.Familia?.DescripcionEstadoMarital || 'Sin información'}}</td>
                    </tr>
                    <tr>
                      <th colspan="1">Rut Conyugue:</th>
                      <td colspan="4">{{objReporteBasico?.InformacionPersonal?.Familia?.RutConyugue ? ((objReporteBasico?.InformacionPersonal?.Familia?.RutConyugue || '') | rutFormat) : 'Sin información'}}</td>
                    </tr>
                    <tr>
                      <th colspan="1">Fecha de Matrimonio:</th>
                      <td colspan="4">{{objReporteBasico?.InformacionPersonal?.Familia?.FechaDeMatrimonio ? (objReporteBasico?.InformacionPersonal?.Familia?.FechaDeMatrimonio | date: 'dd-MM-yyyy') : 'Sin información'}}</td>
                    </tr>
                  </ng-container>
                </ng-container>
                <ng-template #isNotPersonaNatural>
  
                  <ng-container *ngIf="telefonos && telefonos.length > 0">
                    <ng-container *ngIf="telefonos.length > 1; else elseTelefonos">
                      <ng-container *ngFor="let item of telefonos; let i = index;">
                        <tr>
                          <th colspan="1">Teléfono {{ i + 1 }}:</th>
                          <td colspan="4"></td>
                        </tr>
                        <tr>
                          <th colspan="1"></th>
                          <td colspan="4"><span class="font-weight-bold">Número:</span> {{item?.Telefono || 'Sin información'}}</td>
                        </tr>
                        <tr>
                          <th colspan="1"></th>
                          <td colspan="4"><span class="font-weight-bold">Fecha Teléfono:</span> {{item?.FechaTelefono ? (item?.FechaTelefono | date: 'dd-MM-yyyy') : 'Sin información'}}</td>
                        </tr>
                      </ng-container>
                    </ng-container>
                    <ng-template #elseTelefonos>
                      <tr>
                        <th colspan="1">Teléfono:</th>
                        <td colspan="4">{{telefonos[0]?.Telefono || 'Sin información'}}</td>
                      </tr>
                      <tr>
                        <th colspan="1">Fecha Teléfono:</th>
                        <td colspan="4">{{telefonos[0]?.FechaTelefono ? (telefonos[0]?.FechaTelefono | date: 'dd-MM-yyyy') : 'Sin información'}}</td>
                      </tr>
                    </ng-template>
                  </ng-container>
                  <tr *ngIf="reporteConInformeComercial === true">
                    <th colspan="1">Email:</th>
                    <td colspan="4">{{objReporteBasico?.ContactabilidadComercial?.Email || 'Sin información'}}</td>
                  </tr>
                  <tr *ngIf="reporteConInformeComercial === true">
                    <th colspan="1">Sitio Web:</th>
                    <td colspan="4">{{objReporteBasico?.ContactabilidadComercial?.SitioWeb || 'Sin información'}}</td>
                  </tr>
                </ng-template>
              </ng-container>
              

              <tr *ngIf="objReporteBasico && !isPersonaNatural">
                <th colspan="1">Cant. Empleados:</th>
                <td colspan="4">
                  <div class="">
                    {{objReporteBasico?.AntecedentesFinancieros?.NumeroEmpleados? (objReporteBasico?.AntecedentesFinancieros?.NumeroEmpleados | number: '1.0-0'): 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="objReporteBasico && !isPersonaNatural">
                <th colspan="1">Tipo de Sociedad:</th>
                <td colspan="4">
                  <div class="">
                    {{objReporteBasico?.InformacionBasica?.TipoSociedad || 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="mallaSocietaria">
                <th colspan="1">Malla Societaria:</th>
                <td colspan="4">
                  {{mallaSocietaria || 'Sin información'}}
                </td>
              </tr>
              <ng-container
                *ngIf="objReporteFinanciero && objReporteFinanciero.CarpetaTributariaElectronicaReporte.Reporte.representantes.length > 0">
                <ng-container
                  *ngFor="let representante of objReporteFinanciero.CarpetaTributariaElectronicaReporte.Reporte.representantes; let i = index">
                  <tr>
                    <th colspan="1">Representante Legal {{i + 1}}:</th>
                    <td colspan="4">
                      <div class="">
                        {{representante.nombreRazonSocial || 'Sin información'}}
                      </div>
                    </td>
                  </tr>
                </ng-container>

              </ng-container>
              <tr *ngIf="objReporteBasico && !isPersonaNatural">
                <th colspan="1">Tamaño de la empresa:</th>
                <td colspan="4">
                  <div class="">
                    {{objReporteBasico?.AntecedentesFinancieros?.TamanoEmpresaDescripcion || 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="objReporteBasico && !isPersonaNatural">
                <th colspan="1">Subsegmento:</th>
                <td colspan="4">
                  <div class="">
                    {{objReporteBasico?.AntecedentesFinancieros?.TamanoSubsegmento || 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="objReporteBasico && !isPersonaNatural">
                <th colspan="1">Rango Facturación:</th>
                <td colspan="4">
                  <div class="">
                    {{objReporteBasico?.AntecedentesFinancieros?.TamanoEmpresaRango || 'Sin información'}}
                  </div>
                </td>
              </tr>
              <tr *ngIf="objReporteBasico && !isPersonaNatural">
                <th colspan="1">Patrimonio:</th>
                <td colspan="4">
                  <div class="">
                    {{objReporteBasico?.AntecedentesFinancieros?.Patrimonio || 'Sin información'}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr *ngIf="objReporteDeudaSbif">
                <th colspan="1" width="40%">Deuda al :</th>
                <td colspan="4" width="60%">
                    <div class="form-control text-capitalize  border-0">
                      <ng-container *ngIf="objReporteDeudaSbif">
                        {{periodoDeuda  | date:'LLL yyyy' | titlecase}}
                      </ng-container>
                    </div>
                </td>
            </tr>
            <tr *ngIf="objReporteDeudaSbif">
                <th colspan="1">Deuda vigente Bancarios y leasing M$:</th>
                <td colspan="4">
                    <div class="form-control border-0">
                      {{objReporteDeudaSbif? (sumarTotalesDeudas(['dirvigente', 'leasingvig']) | number: '1.0-0'): 'Sin información'}}
                      </div>
                </td>
            </tr>
            <tr *ngIf="objReporteDeudaSbif">
                <th colspan="1">Deuda morosa Bancarios y leasing M$:</th>
                <td colspan="4">
                    <div class="form-control border-0">
                        {{objReporteDeudaSbif? (sumarTotalesDeudas(['dirmorosa', 'dirvencida', 'castigadadirecta', 'dirven180mayor3year', 'leasingmor']) | number: '1.0-0'): 'Sin información'}}
                    </div>
                </td>
            </tr>
            <tr *ngIf="objReporteBasico">
                <th colspan="1">Total Impago Empresa (Dicom) M$:</th>
                <td colspan="4">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6 pl-0">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text border-0 bg-white" disabled>#</span>
                                    </div>
                                    <div class="form-control border-0" disabled>
                                        {{objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad? (objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad | number: '1.0-0'): 'Sin información'}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 pr-0">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text border-0 bg-white" disabled>$</span>
                                    </div>
                                    <div class="form-control border-0" disabled>
                                        {{objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto? (objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto | number: '1.0-0'): 'Sin información'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr *ngIf="objReporteDeudaSbif">
                <th colspan="1">LC bancarias disponibles M$:</th>
                <td colspan="4">
                    <div class="form-control  border-0">
                      {{objReporteDeudaSbif? (sumarTotalesDeudas(['lineacredito']) | number: '1.0-0'): 'Sin información'}}
                    </div>
                </td>
            </tr>
            <tr *ngIf="objReporteDeudaSbif">
                <th colspan="1">Crédito contingentes M$:</th>
                <td colspan="4">
                    <div class="form-control  border-0">
                      {{objReporteDeudaSbif? (sumarTotalesDeudas(['credcontingente']) | number: '1.0-0'): 'Sin información'}}
                    </div>
                </td>
            </tr>

              <tr *ngIf="totalJudicial">
                <th>Causas judiciales (Cantidad de causas laborales, civiles o cobranza): </th>
                <td class="px-3" colspan="3">
                  <ng-template #judicialSinInfo>
                    Sin información
                  </ng-template>

                  <ng-container *ngIf="objReporteJudicial; else judicialSinInfo">
                    {{totalJudicial}}
                  </ng-container>
                </td>
              </tr>
              <tr *ngIf="objReporteBoletinConcursal">
                <th>Boletin Concursal</th>
                <td class="px-3" colspan="3">{{
                  objReporteBoletinConcursal && objectKeys(objReporteBoletinConcursal.boletinConcursalReporte.principal).length > 0? 'SI': 'NO'
                  }}</td>
              </tr>
              <tr *ngIf="objReporteBasico">
                <th>Número de Vehiculos:</th>
                <td class="px-3">
                  {{objReporteBasico?.AntecedentesFinancieros?.NumeroVehiculos? (objReporteBasico?.AntecedentesFinancieros?.NumeroVehiculos | number: '1.0-0'): 'Sin información'}}
                </td>
                <th>Valor de Vehiculos:</th>
                <td class="px-3">
                  {{objReporteBasico?.AntecedentesFinancieros?.ValorVehiculos? (objReporteBasico?.AntecedentesFinancieros?.ValorVehiculos | number: '1.0-0'): 'Sin información'}}
                </td>
              </tr>
              <tr *ngIf="objReporteBasico">
                <th>Número de Bienes Raices:</th>
                <td class="px-3">
                  {{objReporteBasico?.AntecedentesFinancieros?.NumeroBienesRaices? (objReporteBasico?.AntecedentesFinancieros?.NumeroBienesRaices | number: '1.0-0'): 'Sin información'}}
                </td>
                <th>Valor de Bienes Raices:</th>
                <td class="px-3">
                  {{objReporteBasico?.AntecedentesFinancieros?.ValorBienesRaices? (objReporteBasico?.AntecedentesFinancieros?.ValorBienesRaices | number: '1.0-0'): 'Sin información'}}
                </td>
              </tr>
              <tr *ngIf="objReporteEmpresaDia">
                <th>Empresa en dia</th>
                <td class="px-3" colspan="3">{{
                  objReporteEmpresaDia && objectKeys(objReporteEmpresaDia).length > 0? 'SI': 'NO'
                  }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-block w-100">
    <div class="card">
      <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-exclamation-triangle"
          style="font-size:28px;">&nbsp;</i>RIESGO <p class="d-inline fecha_size text-white">({{objReporteBasico?.InformacionBasica?.FechaReporte | date: 'dd-MM-yyyy'}})</p></h5>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <table class="table table-hover">
              <tbody>
                <tr>
                  <th width="30%">Riesgo Industria:</th>
                  <td>{{objReporteBasico?.Riesgo?.RiesgoIndustria || 'Sin información'}}</td>
                </tr>
                <tr *ngIf="riesgoIndustriaCalculado && objReporteBasico?.Riesgo">
                  <th colspan="2">
                    <div class="form-group">
                      <div class="d-flex btn-group" role="group" aria-label="Basic radio toggle button group">
    
                        <input type="radio" class="btn-check" name="inputLogType" id="alto" value="Alto">
                        <label class="btn btn-outline-primary alto" for="alto">Alto
                          <div class="selected" *ngIf="objReporteBasico?.Riesgo?.RiesgoIndustria === 'Alto'"></div>
                        </label>
    
                        <input type="radio" class="btn-check" name="inputLogType" id="medioAlto" value="Medio Alto">
                        <label class="btn btn-outline-primary medioAlto" for="medioAlto">Medio Alto
                          <div class="selected" *ngIf="objReporteBasico?.Riesgo?.RiesgoIndustria === 'Medio Alto'"></div>
                        </label>
    
                        <input type="radio" class="btn-check" name="inputLogType" id="medio" value="Medio">
                        <label class="btn btn-outline-primary medio" for="medio">Medio
                          <div class="selected" *ngIf="objReporteBasico?.Riesgo?.RiesgoIndustria === 'Medio'"></div>
                        </label>
    
                        <input type="radio" class="btn-check" name="inputLogType" id="medioBajo" value="Medio Bajo">
                        <label class="btn btn-outline-primary medioBajo" for="medioBajo">Medio Bajo
                          <div class="selected" *ngIf="objReporteBasico?.Riesgo?.RiesgoIndustria === 'Medio Bajo'"></div>
                        </label>
    
                        <input type="radio" class="btn-check" name="inputLogType" id="Bajo" value="Bajo">
                        <label class="btn btn-outline-primary bajo" for="Bajo">Bajo
                          <div class="selected" *ngIf="objReporteBasico?.Riesgo?.RiesgoIndustria === 'Bajo'"></div>
                        </label>
    
                      </div>
                    </div>
                  </th>
                </tr>
                <tr *ngIf="objReporteBasico?.Riesgo?.Clasificacion">
                  <th>Clasificación Comercial:</th>
                  <td>
                    <!-- <table width="100%" border="0">
                      <tbody> -->
                        <div class="w-100">
                          <div style="padding: 0.75rem 3.5%;">
                            <div style="width:94%;">
                              <div style="width:22px;text-align: center;"
                                [ngStyle]="{'margin-left': setClasificacionPosition(objReporteBasico?.Riesgo?.Clasificacion || '') + '%'}">
                                <div style="width:26px;height:12px;text-align: center; font-size: 1.5rem;
                              font-weight: bold;">{{objReporteBasico?.Riesgo?.Clasificacion}}
                                </div>
                                <div style="width:26px;height:14px;text-align: center;"><i class="arrow down"></i></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-100">
                          <div><img src="assets/img/barra.jpg" width="100%" alt="" /></div>
                        </div>
                      <!-- </tbody>
                    </table> -->
                  </td>
                </tr>
                <tr *ngIf="objReporteFinanciero">
                  <th>Rating Financiero:</th>
                  <td>
                    <ng-template #ratinSinInfo>
                      Sin información
                    </ng-template>
                    <ng-container *ngIf="objReporteFinanciero &&  informacionBalance && informacionBalance.length > 0 && informacionBalance[0]?.calculado; else ratinSinInfo">
                      <!-- <table width="100%" border="0">
                        <tbody> -->
                          <div class="w-100">
                            <div style="padding: 0.75rem 16px;">
                              <div style="width:94%;">
                                <div style="width:22px;text-align: center;"
                                  [ngStyle]="{'margin-left': setClasificacionPosition(informacionBalance[0]?.calculado? informacionBalance[0].calculado.rating: '') + '%'}">
                                  <div style="width:26px;height:12px;text-align: center; font-size: 1.5rem;
                                    font-weight: bold;">{{informacionBalance[0]?.calculado? informacionBalance[0].calculado.rating: ''}}
                                  </div>
                                  <div style="width:26px;height:14px;text-align: center;"><i class="arrow down"></i></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="w-100">
                            <div colspan="2"><img src="assets/img/barra.jpg" width="100%" alt="" /></div>
                          </div>
                        <!-- </tbody>
                      </table> -->
                    </ng-container>
                  </td>
                </tr>
                <tr>
                  <th>Glosa Rating Financiero </th>
                  <td>
                    <ng-template #glosaSinInfo>
                      Sin información
                    </ng-template>
    
                    <ng-container *ngIf="objReporteFinanciero && informacionBalance && informacionBalance.length > 0 && informacionBalance[0]?.calculado; else glosaSinInfo">
                      {{informacionBalance[0]?.calculado? informacionBalance[0].calculado.glosa: ''}}
                    </ng-container>
    
                  </td>
                </tr>
                <!-- <tr *ngIf="graph && objReporteBasico?.Riesgo?.ClasificacionHistorica">
                  <td colspan="2">
                    <div [chart]="graph" class="graphContent"></div>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div class="col-sm-6" *ngIf="graph && objReporteBasico?.Riesgo?.ClasificacionHistorica">
            <div [chart]="graph" class="graphContent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  <div class="d-block w-100 break-box" *ngIf="objReporteFinanciero">
    <div class="card textos-campo">
      <h5 class="card-header list-group-item cuadro-color">
        <i class="fa fa-file-text-o" style="font-size:28px;">&nbsp;</i>
        ANTECEDENTES FINANCIEROS (RESUMEN)
      </h5>
      <div class="card-body">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table">
              <tbody>
                <ng-container *ngFor="let item of headerResumenFinanciero">
                  <tr>
                    <th width="30%">{{balanceReporteFullResumenFinanciero[item]}}</th>
                    <td *ngFor="let data of informacionBalance" [attr.width]="70/informacionBalance.length + '%'">
                      <span *ngIf="data.calculado">
                        <ng-template #percent>
                          {{data.calculado[item] | number: '1.0-2'}}
                        </ng-template>

                        <ng-container *ngIf="!getPercents(item); else percent">
                          {{data.calculado[item] | number: '1.0-0'}}
                        </ng-container>
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

</div>
</div>

<div class="row" *ngIf="validarIfInformation(objReporteBasico) && objReporteBasico?.InformacionBasica?.TipoReporte !== sinInformeText">
  <div class="col-sm-6" style="max-width: 50%;">
    <div class="card">
      <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-sitemap"
          style="font-size:28px;">&nbsp;</i>ANTECEDENTES SOCIETARIOS <p class="d-inline fecha_size text-white">({{validaObjetoSociosFecha() | date: 'dd-MM-yyyy'}})</p></h5>
      <div class="card-body">
        <table class="table table-hover">
          <tbody>
            <ng-template #antecedentesComercial>
              <tr>
                <th width="29%" scope="row">Rut:</th>
                <td width="71%">
                  <ng-container *ngIf="objReporteBasico && objReporteBasico?.InformacionBasica">
                    {{(objReporteBasico?.InformacionBasica?.Rut || '') | rutFormat}}
                  </ng-container>
                </td>
              </tr>
              <tr>
                <th scope="row">Nombre:</th>
                <td>
                  <ng-container *ngIf="objReporteBasico && objReporteBasico?.InformacionBasica">
                  {{objReporteBasico?.InformacionBasica?.NombreORazonSocial}}
                </ng-container>
                </td>
              </tr>
            </ng-template>
            <ng-container *ngIf="validaObjetoAntecedentes(); else antecedentesComercial">
              <tr>
                <th width="29%" scope="row">Rut:</th>
                <td width="71%">
                  {{(objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.Rut || '') |
                  number:
                  '1.0-0'}}-{{(objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.DV ||
                  '') |
                  titlecase}}
                </td>
              </tr>
              <tr>
                <th scope="row">Nombre:</th>
                <td>{{objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.nombre}}</td>
              </tr>
            </ng-container>

          </tbody>
        </table>
        <div class="tree ">
          <ul>
            <li>
              <ng-template #sociosComercial>
                <ng-container *ngIf="validarIfInformation(objReporteBasico) && objReporteBasico?.InformacionBasica">
                <span>
                  <a style="color:#000; text-decoration:none;" data-toggle="collapse" href="#Web" aria-expanded="true"
                    aria-controls="Web"><i class="collapsed"><i class="fas fa-folder"></i></i> <i class="expanded"><i
                        class="fa fa-folder-open"></i></i>
                    {{objReporteBasico?.InformacionBasica?.NombreORazonSocial}} /
                    {{(objReporteBasico?.InformacionBasica?.Rut || '') |
                    rutFormat}}</a></span>
                <div id="Web" class="collapse show">
                  <ul>
                    <li *ngFor="let socio of objReporteBasico?.AntecedentesSocietarios">
                      <span><i class="fa fa-file"></i> {{socio.nombre}} / {{socio.rut | rutFormat}}</span>
                    </li>
                  </ul>
                </div>
              </ng-container>
              </ng-template>
              <ng-container *ngIf="validaObjetoSocios(); else sociosComercial">
                <span>
                  <a style="color:#000; text-decoration:none;" data-toggle="collapse" href="#Web" aria-expanded="true"
                    aria-controls="Web"><i class="collapsed"><i class="fas fa-folder"></i></i> <i class="expanded"><i
                        class="fa fa-folder-open"></i></i>
                    {{(objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.Rut || '') |
                    number:
                    '1.0-0'}}-{{(objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.DV ||
                    '') |
                    titlecase}} / {{objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.nombre}}
                  </a></span>
                <div id="Web" class="collapse show"
                  *ngIf="validaObjetoSocios()">
                  <ul>
                    <li *ngFor="let socio of objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.socios">
                      <span><i class="fa fa-file"></i> {{socio.nombreRazonSocial}} / {{socio.rut | number:
                        '1.0-0'}}-{{socio.dv | titlecase}}</span>
                    </li>
                  </ul>
                </div>
              </ng-container>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>



  <div class="col-sm-6" style="max-width: 50%;"
    *ngIf="validarIfInformation(objReporteBasico) && objReporteBasico?.InformacionBasica?.TipoReporte !== sinInformeText">
    <div class="card">
      <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-file-text-o"
          style="font-size:28px;">&nbsp;</i>ANTECEDENTES COMERCIALES <p class="d-inline fecha_size text-white">({{objReporteBasico?.InformacionBasica?.FechaReporte | date: 'dd-MM-yyyy'}})</p></h5>
      <div class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <td align="center" scope="col">&nbsp;</td>
              <td align="right"><strong>Monto miles</strong></td>
              <td align="right"><strong>Cantidad</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Protestos</th>
              <td align="right" scope="row">{{objReporteBasico?.AntecedentesComerciales?.Resumen?.ProtestosMonto |
                number:'1.0-0'}}</td>
              <!-- | round | money | remove: '.00' -->
              <td align="right">{{objReporteBasico?.AntecedentesComerciales?.Resumen?.ProtestoCantidad |
                number:'1.0-0'}}</td>
            </tr>
            <tr>
              <th scope="row">Morosidades</th>
              <td align="right">{{objReporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesMonto |
                number:'1.0-0'}}</td>
              <!-- | round | money | remove: '.00' -->
              <td align="right">{{objReporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesCantidad |
                number:'1.0-0'}}
              </td>
            </tr>
            <tr>
              <th scope="row">Infracciones Laborales</th>
              <td align="right">{{objReporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto |
                number:'1.0-0'}}</td>
              <!-- | round | money | remove: '.00' -->
              <td align="right">{{objReporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesCantidad |
                number:'1.0-0'}}</td>
            </tr>
            <tr class="table-secondary">
              <th scope="row">Total</th>
              <td align="right">{{objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto |
                number:'1.0-0'}}</td>
              <!-- | money | remove: '.00' -->
              <td align="right">{{objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad |
                number:'1.0-0'}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="card textos-campo" *ngIf="objReporteBasico && reporteConInformeComercial === true">
  <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-users"
      style="font-size:28px;">&nbsp;</i>ANTECEDENTES SOCIEDADES <p class="d-inline fecha_size text-white">({{objReporteBasico?.InformacionBasica?.FechaReporte | date: 'dd-MM-yyyy'}})</p></h5>
  <div class="card-body">
    <ng-container *ngIf="isPersonaNatural; else notPNAntSociedades">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Rut</th>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="objReporteBasico?.AntecedentesSociedades && objReporteBasico.AntecedentesSociedades.length > 0; else aSNoData">
            <tr *ngFor="let itemSociedad of objReporteBasico?.AntecedentesSociedades">
              <td>{{(itemSociedad?.Rut || '') | rutFormat}}</td>
              <td>{{itemSociedad?.Nombre || ''}}</td>
            </tr>
          </ng-container>
          <ng-template #aSNoData>
            <tr>
              <td colspan="100%">Sin Información</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </ng-container>
    <ng-template #notPNAntSociedades>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Rut</th>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="objReporteBasico?.AntecedentesSociedades && objReporteBasico.AntecedentesSociedades.length > 0; else notPNASNoData">
            <tr *ngFor="let itemSociedad of objReporteBasico?.AntecedentesSociedades">
              <td>{{(itemSociedad?.Rut || '') | rutFormat}}</td>
              <td>{{itemSociedad?.Nombre || ''}}</td>
            </tr>
          </ng-container>
          <ng-template #notPNASNoData>
            <tr>
              <td colspan="100%">Sin Información</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </ng-template>
  </div>
</div>

<div class="d-block">
  <div class="card textos-campo">
    <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-building"
        style="font-size:28px;">&nbsp;</i>INFORMACION SECTORIAL Y DE MERCADO <p class="d-inline fecha_size text-white">({{objReporteBasico?.InformacionBasica?.FechaReporte | date: 'dd-MM-yyyy'}})</p></h5>
    <div class="card-body" *ngIf="validarIfInformation(objReporteBasico)">
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col">
          <div class="card text-dark bg-light mb-3">
            <div class="card-header">
              <h6 class="card-title">Mercado</h6>
            </div>
            <div class="card-body">
              <table class="table table-hover table-borderless">
                <tbody>
                  <tr>
                    <th width="50%" scope="row">Número empresas:</th>
                    <td width="50%">{{objReporteBasico?.InformacionSectorialyDeMercado?.TamanoMercadoNumeroEmpresas |
                      currency:'CLP':'':'1.0-0' }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Total ventas en UF:</th>
                    <td>{{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasIndustriaEnUF |
                      currency:'CLP':'':'1.0-0'
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Participacion relativa:</th>
                    <td>{{objReporteBasico?.InformacionSectorialyDeMercado?.ParticipacionRelativa}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tendencia mercado:</th>
                    <td>{{objReporteBasico?.InformacionSectorialyDeMercado?.TendenciaMercado}}</td>
                  </tr>
                  <tr>
                    <th scope="row">Variacion crecimiento anual:</th>
                    <td>{{objReporteBasico?.InformacionSectorialyDeMercado?.VariacionVentasCrecimientoAnual}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card text-dark bg-light mb-3">
            <div class="card-header">
              <h6 class="card-title">Comuna</h6>
            </div>
            <div class="card-body">
              <table class="table table-hover">
                <tbody>
                  <tr>
                    <th width="50%" scope="row">Número empresas:</th>
                    <td width="50%">{{objReporteBasico?.InformacionSectorialyDeMercado?.NumeroEmpresasEnComuna |
                      currency:'CLP':'':'1.0-0' }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Total ventas en UF:</th>
                    <td>{{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasComunaEnUF |
                      currency:'CLP':'':'1.0-0' }}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Participacion relativa:</th>
                    <td>{{objReporteBasico?.InformacionSectorialyDeMercado?.ParticipacionRelativaComuna}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row row-cols-1 row-cols-md-2 g-4"> -->
      <div class="col-lg-6" *ngIf="showInformacionSectorialyDeMercado">
        <div class="card text-dark bg-light mb-3">
          <div class="card-header">
            <h6 class="card-title">Ventas</h6>
          </div>
          <div class="card-body">
            <table class="table table-hover table-borderless">
              <thead>
                <tr>
                  <td width="50%" align="left"><strong>Año</strong></td>
                  <td width="50%" align="right"><strong>Venta en UF</strong></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano1}}
                  </th>
                  <td align="right">
                    {{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_1 |
                    currency:'CLP':'':'1.0-0' }}</td>
                </tr>
                <tr>
                  <th scope="row">{{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano2}}
                  </th>
                  <td align="right">
                    {{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_2 |
                    currency:'CLP':'':'1.0-0' }}</td>
                </tr>
                <tr>
                  <th scope="row">{{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano3}}
                  </th>
                  <td align="right">
                    {{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_3 |
                    currency:'CLP':'':'1.0-0' }}</td>
                </tr>
                <tr>
                  <th scope="row">{{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano4}}
                  </th>
                  <td align="right">
                    {{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_4 |
                    currency:'CLP':'':'1.0-0' }}</td>
                </tr>
                <tr>
                  <th scope="row">{{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano5}}
                  </th>
                  <td align="right">
                    {{objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_5 |
                    currency:'CLP':'':'1.0-0' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-lg-6" *ngIf="graficaSinInformacion">
        <div class="card text-dark bg-light mb-3">
          <div class="card-header">
            <h6 class="card-title">Gráfico Ventas</h6>
          </div>
          <div class="card-body">
            <div class="grafico">
              <div class="row">
                <div id="chart_div" class="col-12 ml-0">
                  <google-chart [title]="chart.title" [type]="chart.type" [data]="chart.data" [columns]="chart.columns"
                    [options]="chart.options" [width]="chart.width" [height]="chart.height">
                  </google-chart>
    
                  <figure class="mt-3">
                    <figcaption class="blockquote-footer">
                      <cite title="Source Title">Ventas expresadas en millones de UF</cite>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card textos-campo" *ngIf="!isPersonaNatural && objReporteBasico">
  <h5 class="card-header list-group-item cuadro-color"><i class="fa fa-file-text-o"
      style="font-size:28px;">&nbsp;</i>OBSERVACIONES TRIBUTARIAS <p class="d-inline fecha_size text-white">({{objReporteBasico?.InformacionBasica?.FechaReporte | date: 'dd-MM-yyyy'}})</p></h5>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <div class="card text-dark bg-light mb-3">
          <div class="card-header">
            <h6 class="card-title">Documentos Timbrados</h6>
          </div>
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="font-weight-bold" width="50%">Documento</th>
                  <th class="font-weight-bold" width="50%">Año último Timbraje</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="objReporteBasico?.ObservacionesTributarias?.DocumentosTimbrados && objReporteBasico.ObservacionesTributarias.DocumentosTimbrados.length > 0; else elseDocumentosTimbrados">
                  <tr *ngFor="let documento of objReporteBasico?.ObservacionesTributarias?.DocumentosTimbrados">
                    <td width="50%" scope="row">{{documento?.documento || ''}}</td>
                    <td width="50%">{{documento?.anoUltimoTimbraje || ''}}</td>
                  </tr>
                </ng-container>
                <ng-template #elseDocumentosTimbrados>
                  <tr>
                    <td colspan="100%">Sin Información</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="card text-dark bg-light mb-3">
          <div class="card-header">
            <h6 class="card-title">Observaciones</h6>
          </div>
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="font-weight-bold" width="30%">Nombre</th>
                  <th class="font-weight-bold" width="70%">Descripción</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="objReporteBasico?.ObservacionesTributarias?.Observaciones && objReporteBasico.ObservacionesTributarias.Observaciones.length > 0; else elseDocumentosTimbrados">
                  <tr *ngFor="let observacion of objReporteBasico?.ObservacionesTributarias?.Observaciones">
                    <td width="30%" scope="row">{{observacion?.nombre || ''}}</td>
                    <td width="70%">{{observacion?.descripcion || ''}}</td>
                  </tr>
                </ng-container>
                <ng-template #elseDocumentosTimbrados>
                  <tr>
                    <td colspan="100%">Sin Información</td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card textos-campo" *ngIf="objReporteBasico?.AntecedentesComerciales?.ConsultasAlRut && 
(objReporteBasico?.AntecedentesComerciales?.ConsultasAlRut?.length || 0) > 0">
  <h5 class="card-header list-group-item cuadro-color">
    <table width="100%" border="0">
      <tbody>
        <tr>
          <td class="text-uppercase text-white"><i class="fa fa-area-chart" style="font-size:28px;">&nbsp;</i>
            Consultas al RUT <p class="d-inline fecha_size text-white">({{objReporteBasico?.InformacionBasica?.FechaReporte | date: 'dd-MM-yyyy'}})</p>
          </td>
        </tr>
      </tbody>
    </table>
  </h5>
  <div class="card-body">
    <div style="overflow-x:auto;">
      <table class="table table-hover">
        <thead>
          <tr>
            <th colspan="3" class="border-0">
              RUT registra {{objReporteBasico?.AntecedentesComerciales?.ConsultasAlRut?.length}} consultas
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <td>Fecha</td>
            <td>Empresa</td>
            <td>Informe </td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of objReporteBasico?.AntecedentesComerciales?.ConsultasAlRut">
            <td>{{item.FechaConsulta | date: 'dd-MM-YYYY'}}</td>
            <td>{{item.RazonSocial}} </td>
            <td>{{item.InformeConsultado}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="break-box">
  <div class="card textos-campo" *ngIf="objReporteBasico?.InformacionBasica?.TipoReporte !== sinInformeText">
    <h5 class="card-header list-group-item cuadro-color">
      <table width="100%" border="0">
        <tbody>
          <tr>
            <td class="text-white"><i class="fa fa-area-chart" style="font-size:28px;">&nbsp;</i>PROTESTOS Y MOROSIDADES <p class="d-inline fecha_size text-white">({{objReporteBasico?.InformacionBasica?.FechaReporte | date: 'dd-MM-yyyy'}})</p></td>
            <td align="right">Total M$: {{((objReporteBasico?.AntecedentesComerciales?.Resumen?.ProtestosMonto || 0) +
              (objReporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesMonto || 0)) | currency:'CLP':'':'1.0-0'}}
            </td>
            <!-- TODO: objReporteBasico?.AntecedentesComerciales.Resumen.ProtestosMonto | plus: AntecedentesComerciales.Resumen.MorosidadesMonto | money | remove: '.00' -->
          </tr>
        </tbody>
      </table>
    </h5>
    <div class="card-body">
      <div style="overflow-x:auto;">
        <table class="table table-hover print-table">
          <thead>
            <tr>
              <th align="center" scope="col">Vencimiento</th>
              <th align="center">Doc.</th>
              <th align="right">Monto</th>
              <th align="center">Localidad </th>
              <th align="center">Librador</th>
              <th align="center">Deuda</th>
              <th align="center">Mercado</th>
              <th align="center">Credito</th>
              <th align="center">N°Cheque</th>
              <th align="center">Publicación</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detalle of objReporteBasico?.AntecedentesComerciales?.DetalleMorosidadesYProtestos">
              <td align="center" scope="row">{{detalle.fechaVencimiento | date: 'dd-MM-YYYY' }}</td>
              <td align="center" scope="row">{{detalle.tipoDocumento}}</td>
              <td align="right">{{detalle.moneda}} {{detalle.monto | currency:'CLP':'':'1.0-0' }}</td>
              <td>{{detalle.localidad}}</td>
              <td>{{detalle.librador}} </td>
              <td>{{detalle.tipoDeuda}}</td>
              <td>{{detalle.mercado}}</td>
              <td>{{detalle.tipoCredito}}</td>
              <td align="center">{{detalle.nChequeOperacion}}</td>
              <td align="center">{{detalle.fechaPublicacion | date: 'dd-MM-YYYY' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="break-box">
  <div class="card textos-campo" *ngIf="objReporteBasico?.InformacionBasica?.TipoReporte !== sinInformeText">
    <h5 class="card-header list-group-item cuadro-color">
      <table width="100%" border="0">
        <tbody>
          <tr>
            <td class="text-white"><i class="fa fa-list-alt" style="font-size:28px;">&nbsp;</i>INFORMACION BOLETIN LABORAL <p class="d-inline fecha_size text-white">({{objReporteBasico?.InformacionBasica?.FechaReporte | date: 'dd-MM-yyyy'}})</p></td>
            <td align="right">Total M$: {{
              objReporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto |
              currency:'CLP':'':'1.0-0' }}</td>
          </tr>
        </tbody>
      </table>
    </h5>
    <div class="card-body">
      <div style="overflow-x:auto;">
        <table class="table table-hover">
          <thead>
            <tr>
              <td width="20%" align="center">Motivo</td>
              <td width="6%" align="center">Meses</td>
              <td width="25%" align="center">Institución</td>
              <td width="6%" align="center">Boletín</td>
              <td width="7%" align="right">Monto</td>
              <td width="5%" align="center">Página</td>
              <td width="8%" align="center">Insp</td>
              <td width="10%" align="center" nowrap="nowrap">Fecha</td>
              <td width="22%" align="right">Tipo infracción</td>
              <td width="5%" align="center">Resolución</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detalle of objReporteBasico?.AntecedentesComerciales?.InformacionBoletinComercialLaboral">
              <td align="center">{{detalle.motivo}}</td>
              <td align="center">{{detalle.meses}}</td>
              <td align="center">{{detalle.institucion}}</td>
              <td align="center" scope="row">{{detalle.boletin}}</td>
              <td align="right">{{detalle.monto | number:'1.0-0'}}</td>
              <td align="center">{{detalle.pagina}}</td>
              <td align="center">{{detalle.insp}}</td>
              <td align="center" nowrap="nowrap">{{detalle.fecha | date: 'dd-MM-YYYY' }}</td>
              <td align="right">{{detalle.tipoInfraccion}}</td>
              <td align="center" scope="row">{{detalle.resolutionNumber}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>