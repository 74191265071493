import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { SessionService } from '../shared/services/session.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  private homeURI: string = environment.cognitoConfig.baseRedirectURI + "/";

  constructor(private _sessionService: SessionService) {}

  ngOnInit(): void {
    console.log("LogoutComp oninit")

    if(sessionStorage.getItem('shouldRefresh')) {
      sessionStorage.removeItem('shouldRefresh');
      location.reload()
    }

    this._sessionService.clearSession();
    window.location.href = this.homeURI;
  }

}
