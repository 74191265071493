import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CognitoService } from '../shared/services/cognito.service';
import { InactivityService } from '../shared/services/inactivity.service';
import { SessionService } from '../shared/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private _cognitoService: CognitoService,
    private _sessionService: SessionService,
    private inactivityService: InactivityService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      //Relaod on new version
      if(localStorage.getItem('shouldLogout')) {
        localStorage.removeItem('shouldLogout');
        this._cognitoService.logout();
      }

      if (!this._cognitoService.tokenValidator()) {
        if (this._sessionService.getUserInfo()) {
          return true;
        }
  
        this._sessionService.clearSession();
        this._cognitoService.authorize();
        return false;
      } else {
        this.inactivityService.onStopWatching();
        this._cognitoService.logout();
        return false;
      }
    }
  }