import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
//import { SimpleModalService } from 'ngx-simple-modal';
import { Subscription } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { CognitoService } from './cognito.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})

export class InactivityService {

  private modalOpen = false;
  private subscription: Subscription[] = [];
  private respModal = false;

  constructor(
    private userIdleService: UserIdleService,
    //private simpleModalService: SimpleModalService,
    private _cognitoService: CognitoService,
    public dialog: MatDialog
  ) { }

  /**
  * @description
  * Cierra el proceso de observacion sobre inactividad del usuario 
  * luego inicia con una nueva observacion de inactividad
  */
  async initInactive(): Promise<void> {
    await this.onStopWatching();
    this.startInactive();
  }

  /**
  * @description
  * Inicia el proceso de observacion sobre inactividad del usuario
  * Muestra modal de inactividad cada cierto tiempo configurado
  * en la variable del enviroment @param respawnInactivity
  */
  async startInactive(): Promise<void> {
    const timer = env.inactivity.countdown * 1000;
    this.userIdleService.startWatching();

    this.subscription.push(this.userIdleService.ping$.subscribe(async () => {
      this.modalOpen = true;
      this.respModal = false;

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
            title: 'Confirmación',
            message: `Desea seguir operando o trabajando en el sitio?`,
            textBoton: ['Si', 'No'],
            textTimer: env.inactivity.countdown + 1
        }
      });

      dialogRef.afterClosed().subscribe(resp => {
        if (!resp) {
              this.onStopWatching();
              this._cognitoService.logout();
            } else {
              this.respModal = true;
              this.initInactive();
            }
      });

      /*this.simpleModalService.addModal(ConfirmDialogComponent, {
        title: 'Confirmación',
        message: `Desea seguir operando o trabajando en el sitio?`,
        textBoton: ['Si', 'No'],
        textTimer: env.inactivity.countdown + 1
      }).subscribe((resp) => {

        if (!resp) {
          this.onStopWatching();
          this._cognitoService.logout();
        } else {
          this.respModal = true;
          this.initInactive();
        }
      });*/

      setTimeout(() => {
        if (!this.respModal) {
          this.onStopWatching();
          this._cognitoService.logout();
        }
      }, timer);
    }));
  }

  /**
  * @description
  * Detiene el observador de inactividad, cierra modal de inactividad en caso de estar abierta
  * termina todas las subscripciones
  */
  async onStopWatching(): Promise<void> {
    this.modalOpen = false;
    await this.userIdleService.stopTimer();
    await this.userIdleService.stopWatching();
    await this.closeSubscription();
  }

  /**
  * @description
  * Devuelve el valor de @param modalOpen que indica si la modal esta activa o no 
  * @returns boolean
  */
  modalIsOpen(): boolean {
    return this.modalOpen;
  }

  /**
  * @description
  * Cierra todas las subscripciones
  */
  async closeSubscription(): Promise<void> {
    for (const item of this.subscription) {
      await item.unsubscribe();
    }
  }

}
