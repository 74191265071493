import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { mergeMap, take, map, takeWhile, finalize } from 'rxjs/operators'
import { AlertService } from 'src/app/components/_alert';
import { TesoreriaService } from './tesoreria.service';
import { EmpresaEnUnDiaService } from './empresa-en-un-dia.service';

@Injectable({
  providedIn: 'root'
})
export class AsincronoService {
  constructor(
    private alertService: AlertService,
    private tesoreriaService: TesoreriaService,
    private empresaEnUnDiaService: EmpresaEnUnDiaService
  ) { }

  llamadaTesoreria(idTransaccion: string, rut: string): void {
    const maxRetries = 31;
    let idTXValue: string | null = null;
    let messageError: string = '';

    timer(0, 30000)
    .pipe(
      take(maxRetries),
      mergeMap(() => this.tesoreriaService.buscarTX(idTransaccion)),
      map(response => 
        { 
          const message = response?.TesoreriaReporte?.DatosBasicosSolicitud;
          let isSatisfactory = false;
          if(message && message?.EstadoTransaccion === 'COMPLETED' && message?.SubEstadoTransaccion === 'COMPLETED'){
            idTXValue = idTransaccion;
            isSatisfactory = true
          } else if (message && message?.EstadoTransaccion === 'ERROR'){
            isSatisfactory = true
            if(message?.SubEstadoTransaccion === 'PROCESS'){
              messageError = 'Ha ocurrido un error durante el procesamiento del reporte de tesorería.';
            } else if (message?.SubEstadoTransaccion === 'PASSWORD') {
              messageError = 'Ha ocurrido un error durante el procesamiento del reporte de tesorería, las credenciales no son válidas para acceder al Sii.';
            } else if (message?.SubEstadoTransaccion === 'RUT-VALIDATION') {
              messageError = 'Ha ocurrido un error durante el procesamiento del reporte de tesorería, Rut no es válido.';
            }
          } else {
            isSatisfactory = false;
          }

          return { response: response, isSatisfactory: isSatisfactory }
          
        }),
      takeWhile(({ response, isSatisfactory }) => !isSatisfactory),
      finalize(() => {
        //Termina la ejecucion ira a identificar si existe this.idTXValue
      })
    )
    .subscribe(
      response => {
        // Llega al ultimo pero debe estar esta definicion
      },
      error => {
        this.alertService.error(error?.error?.message || 'Ha ocurrido un error al obtener datos del reporte de tesorería.');
      },
      () => {
        if(!idTXValue){
          if(messageError != '') {
            this.alertService.error(messageError || 'Ha ocurrido un error al obtener datos del reporte de tesorería.');
          }
        } else {
          const variables: any = {
            rut: rut,
            idTransaccion: idTXValue
          }
          this.alertService.success(`Se ha ejecutado correctamente la creación del reporte de tesorería ${idTXValue}`, {autoClose: true, buttonRedirect: true, componente: 'reporte-tesoreria/buscar', arguments: variables});
        }
      }
    );
  }


  llamadaEmpresaEnUnDia(idTransaccion: string, rut: string): void {
    const maxRetries = 20;
    let idTXValue: string | null = null;
    let messageError: string = '';

    timer(0, 15000)
    .pipe(
      take(maxRetries),
      mergeMap(() => this.empresaEnUnDiaService.getReport(idTransaccion)),
      map(response => 
        {  
          const datosSolicitud = response?.EmpresaEnUnDiaReporte?.DatosBasicosSolicitud;
          const certificados = response?.EmpresaEnUnDiaReporte?.Certificados || [];
          let isSatisfactory = false;
          if(datosSolicitud && datosSolicitud?.EstadoTransaccion === 'COMPLETED' && datosSolicitud?.SubEstadoTransaccion === 'COMPLETED' && certificados.length > 0){
            idTXValue = idTransaccion;
            isSatisfactory = true
          } else if (datosSolicitud && datosSolicitud?.EstadoTransaccion === 'ERROR'){
            isSatisfactory = true
            messageError = 'Empresa en un día - Ha ocurrido un error en la generación del reporte.';
          } else {
            isSatisfactory = false;
          }

          return { response: response, isSatisfactory: isSatisfactory }
          
        }),
      takeWhile(({ response, isSatisfactory }) => !isSatisfactory),
      finalize(() => {
        //Termina la ejecucion ira a identificar si existe this.idTXValue
      })
    )
    .subscribe(
      response => {
        // Llega al ultimo pero debe estar esta definicion
      },
      error => {
        this.alertService.error(error?.error?.message || 'Ha ocurrido un error al obtener datos del reporte de empresa en un dia.');
      },
      () => {
        if(!idTXValue){
          this.alertService.error(messageError || 'Ha ocurrido un error al obtener datos del reporte de empresa en un dia.');
        } else {
          const variables: any = {
            rut: rut,
            idTransaccion: idTXValue
          }
          this.alertService.success(`Se ha ejecutado correctamente la creación del reporte de empresa en un dia ${idTXValue}`, {autoClose: true, buttonRedirect: true, componente: 'empresa-en-un-dia/buscar', arguments: variables});
        }
      }
    );
  }

}
