<div id="sidebar">
    <!-- Wrapper for scrolling functionality -->
    <div id="sidebar-scroll">
        <!-- Sidebar Content -->
        <div class="sidebar-content">
            <!-- Brand -->
            <a href="#" class="sidebar-brand">
                <img src="https://www.gestionatupyme.cl/wp-content/themes/gestionatupyme/assets/img/logo.png" alt="Logo"
                    class="img-responsive logogestiona">
            </a>
            <!-- END Brand -->

            <!-- User Info -->
            <div class="sidebar-section sidebar-user clearfix sidebar-nav-mini-hide">
                <div class="sidebar-user-avatar">
                    <a href="#">
                        <img src="assets/img/placeholders/avatars/avatar2.jpg" alt="avatar">
                    </a>
                </div>
                <div *ngIf="userInfo">
                    <div class="sidebar-user-name">{{userInfo.name + ' ' + userInfo.given_name}}</div>
                    <div class="sidebar-user-links" *ngIf="hasOrganizationSelected">
                        {{organization}}
                    </div>
                    <div class="sidebar-user-links">
                        <ul class="list-inline">
                        <li *ngIf="hasManyOrganization" (click)="swithOrganization()" data-toggle="tooltip" data-placement="bottom" title="Cambiar de organizacion"><i class="fa fa-exchange"></i></li>
                        <li data-toggle="tooltip" data-placement="bottom" title="{{userInfo.email}}"><i class="gi gi-envelope"></i></li>
                        <li *ngIf="userInfo.phoneNumber" data-toggle="tooltip" data-placement="bottom" [title]="userInfo.phoneNumber"><i class="gi gi-phone_alt"></i></li>
                        <li (click)="logout()" data-toggle="tooltip" data-placement="bottom" title="Cerrar sesion"><i class="gi gi-exit"></i></li>
                    </ul>
                    </div>
                </div>
            </div>
            <!-- END User Info -->
            <!-- Sidebar Navigation -->
            <ul class="sidebar-nav" *ngIf="hasOrganizationSelected">
                <li class="sidebar-header">
                    <span class="sidebar-header-title">
                        <a [routerLink]="['']">Panel Principal</a>
                    </span>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="gi gi-credit_card sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Flujo de Crédito</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['flujo-credito/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="validaOrganizacionFlujoCredito()">
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="gi gi-credit_card sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Solicitud de Crédito</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['solicitud-credito/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu consolidado"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="gi gi-credit_card sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Reporte Consolidado</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['reporte-consolidado/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="gi gi-notes_2 sidebar-nav-icon"></i><span
                            class="sidebar-nav-mini-hide">Reporte Básico</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['reporte-basico/consultar']" [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                        <li>
                            <a [routerLink]="['reporte-basico/buscar']" [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-bar-chart sidebar-nav-icon"></i><span
                            class="sidebar-nav-mini-hide">Reporte Financiero</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['reporte-financiero/consultar']" [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-balance-scale sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Informe
                            Judicial</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['reporte-judicial/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                        <li>
                            <a [routerLink]="['reporte-judicial/buscar']"
                                [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                        <i class="fa fa-industry sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Empresa en
                            un
                            día</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['empresa-en-un-dia/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                        <li>
                            <a [routerLink]="['empresa-en-un-dia/buscar']"
                                [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-newspaper-o sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Boletin
                            Concursal</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['boletin-concursal/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                        <li>
                            <a [routerLink]="['boletin-concursal/buscar']"
                                [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-credit-card sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Deuda Sbif Leasing</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['deuda-sbif-leasing/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                        <li>
                            <a [routerLink]="['deuda-sbif-leasing/buscar']"
                                [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-car sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Vehículos</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['vehiculos/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                        <li>
                            <a [routerLink]="['vehiculos/buscar']"
                                [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Bienes Raices</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['bienes-raices/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                        <li>
                            <a [routerLink]="['bienes-raices/buscar']"
                                [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-university sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Reporte Tesoreria</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['reporte-tesoreria/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                        <li>
                            <a [routerLink]="['reporte-tesoreria/buscar']"
                                [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-book sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Libro Compra Venta</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['compra-venta/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                        <li>
                            <a [routerLink]="['compra-venta/buscar']"
                                [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                            <i class="fa fa-sitemap sidebar-nav-icon" aria-hidden="true"></i>
                            <span class="sidebar-nav-mini-hide">Malla Societaria</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['reporte-malla-societaria/consultar']"
                                [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                        <li>
                            <a [routerLink]="['reporte-malla-societaria/buscar']"
                                [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                            <i class="fa fa-list-alt sidebar-nav-icon" aria-hidden="true"></i>
                            <span class="sidebar-nav-mini-hide">Mercado Público</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['reporte-mercado-publico/fecha']"
                                [routerLinkActive]="['is-active']">Por Fecha</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="gi gi-table sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">
                            Solicitudes</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['solicitudes/consultar']" [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-money sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">
                            Ofertas Financieras</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['ofertas-financieras']" [routerLinkActive]="['is-active']">Consultar</a>
                        </li>
                    </ul>
                </li>
                <li>
                <!-- <li> -->
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-file-text sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">
                            Cobranza Online</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['cobranza-online/publicar']" [routerLinkActive]="['is-active']">Publicar</a>
                        </li>
                        <li>
                            <a [routerLink]="['cobranza-online/buscar']" [routerLinkActive]="['is-active']">Buscar</a>
                        </li>
                    </ul>
                </li>
                <li *ngIf="organization === 'DESARROLLO' || organization === 'SOPORTE'">
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-window-maximize sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">
                            Reportes</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['reportes/panelriesgo']" [routerLinkActive]="['is-active']">Panel de Riesgo</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a class="sidebar-nav-menu"><i
                            class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i><i
                            class="fa fa-wrench sidebar-nav-icon"></i><span
                            class="sidebar-nav-mini-hide">Configuraciones</span></a>
                    <ul style="display: none;">
                        <li>
                            <a [routerLink]="['usuarios/listar']" [routerLinkActive]="['is-active']">Administracion de
                                Usuarios</a>
                        </li>
                        <li>
                            <a [routerLink]="['grupos']" [routerLinkActive]="['is-active']">Administracion de
                                Grupos</a>
                        </li>
                        <li *ngIf="isUser">
                            <a [routerLink]="['organizacion/listar']" [routerLinkActive]="['is-active']">Organizaciones</a>
                        </li>
                        <li>
                            <a [routerLink]="['campos-personalizados']" [routerLinkActive]="['is-active']">Camp. Personalizados</a>
                        </li>
                        <li>
                            <a class="sidebar-nav-submenu"><i
                                    class="fa fa-angle-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                    <span class="sidebar-nav-mini-hide">Administración de Credenciales</span>
                            </a>
                            <ul style="display: none;">
                                <li>
                                    <a [routerLink]="['credenciales/organizacion']" [routerLinkActive]="['is-active']">Organizacion</a>
                                </li>
                                <li>
                                    <a [routerLink]="['credenciales/usuarios']" [routerLinkActive]="['is-active']">Usuarios</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <a (click)="logout()"> <i class="gi gi-exit sidebar-nav-icon"
                            [routerLinkActive]="['is-active']"></i> Salir</a>
                </li>
            </ul>
            <!-- END Sidebar Navigation -->
        </div>
        <!-- END Sidebar Content -->
    </div>
    <!-- END Wrapper for scrolling functionality -->
</div>